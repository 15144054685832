import { StoreAction, WindowState } from '../types';

export const WINDOW_SIZE_INIT_STATE: WindowState = {
    width: 0,
    height: 0,
    hamburgerMenuOpen: false,
    isSmartphone: false,
    navigationCollapsed: false,
};

export const TYPE_WINDOW_SIZE_WIDTH = Symbol('WINDOW_SIZE_WIDTH');
export const TYPE_WINDOW_SIZE_HEIGHT = Symbol('WINDOW_SIZE_HEIGHT');
export const TYPE_WINDOW_HAMBURGER = Symbol('WINDOW_HAMBURGER');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const windowSize = (state: WindowState = WINDOW_SIZE_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_WINDOW_SIZE_WIDTH:
            return {
                ...state,
                width: action.data,
            };
        case TYPE_WINDOW_SIZE_HEIGHT:
            return {
                ...state,
                height: action.data,
            };
        case TYPE_WINDOW_HAMBURGER:
            return {
                ...state,
                hamburgerMenuOpen: !state.hamburgerMenuOpen,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
