import { HelpState, StoreAction } from '../types';

export const HELP_INIT_STATE: HelpState = {
    contentHeight: undefined,
};

export const TYPE_HELP_CHANGE_CONTENT_HEIGHT = Symbol('TYPE_HELP_CHANGE_CONTENT_HEIGHT');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const help = (state: HelpState = HELP_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_HELP_CHANGE_CONTENT_HEIGHT:
            return {
                ...state,
                contentHeight: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
