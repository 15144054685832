import { Grid, Paper } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    IMAGES_ADDRESS, IMAGES_EXTENSION, IMAGES_EXTENSION_ALLIANCE,
    IMAGES_EXTENSION_BOOKING_FORMATION,
    IMAGES_EXTENSION_BS,
    SMARTPHONE_WIDTH,
    VERSIONS
} from '../../resources/constants';
import placeholder from '../../resources/img/products/placeholder.jpg';
import { getCurrentVersion } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import { Lesson } from '../../types/booking';
import Button from '../common/button';
import Title from '../common/title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    lesson: Lesson;
}

type Props = IProps & ReduxProps;

interface State {
    toBooking: boolean;
    thumbnail: string;
}

class Card extends React.Component<Props, State> {
    image: any;

    constructor(props: Props) {
        super(props);

        const { lesson } = this.props;
        this.state = {
            toBooking: false,
            thumbnail: placeholder,
        };

        this.image = new Image();
        this.image.onerror = () => this.setState({ thumbnail: placeholder });
        this.image.onload = () => this.setState({ thumbnail: this.image.src });

        let thumbnailAddress;
        const currentVersion = getCurrentVersion();
        if (currentVersion === VERSIONS.Swisskischool) {
            if (lesson.school.name.includes('ESS Villars-sur-Ollon')) {
                thumbnailAddress = `${IMAGES_ADDRESS + lesson.school.name.replace('-sur-Ollon', '').replace(/'/g, '').replace('ESS ', 'ess').replace(/ /g, '-')
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.external_id}-${lesson.external_activity_id}${IMAGES_EXTENSION}`;
            } else {
                thumbnailAddress = `${IMAGES_ADDRESS + lesson.school.name.replace(/'/g, '').replace('ESS ', '').replace(/ /g, '-').toLowerCase()
                    .normalize('NFD')
                    .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.external_id}-${lesson.external_activity_id}${IMAGES_EXTENSION}`;
            }
        } else if (currentVersion === VERSIONS.BookingSport) {
            thumbnailAddress = `${IMAGES_ADDRESS + lesson.school.name.replace(/'/g, '').replace(/ /g, '-').toLowerCase().normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.external_id}-${lesson.external_activity_id}${IMAGES_EXTENSION_BS}`;
        } else if (currentVersion === VERSIONS.SkiSchoolAlliance) {
            thumbnailAddress = `${IMAGES_ADDRESS + lesson.school.name.replace(/'/g, '').replace(/ /g, '-').toLowerCase().normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.external_id}-${lesson.external_activity_id}${IMAGES_EXTENSION_ALLIANCE}`;
        } else {
            thumbnailAddress = `${IMAGES_ADDRESS + lesson.school.name.replace(/'/g, '').replace(/ /g, '-').toLowerCase().normalize('NFD')
                .replace(/\p{Diacritic}/gu, '')}/lessons/${lesson.external_id}-${lesson.external_activity_id}${IMAGES_EXTENSION_BOOKING_FORMATION}`;
        }
        this.image.src = thumbnailAddress;
    }

    handleAvailabilityClicked = () => {
        this.setState(() => ({
            toBooking: true,
        }));
    };

    render() {
        const {
            language, selectedProductsDate, lesson, windowWidth,
        } = this.props;
        const { toBooking, thumbnail } = this.state;
        if (toBooking) {
            return (
                <Redirect
                    push
                    to={{
                        pathname: `/${language}/booking`,
                        state: {
                            lesson,
                            selectedLessonDate: selectedProductsDate,
                        },
                    }}
                />
            );
        }

        const image = (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions,
            jsx-a11y/click-events-have-key-events */
            <div
                className={`__products-card-image${lesson.is_visible ? '' : '-unavailable'}`}
                style={{ backgroundImage: `url('${thumbnail}')` }}
                onClick={() => lesson.is_visible && this.handleAvailabilityClicked()}
            />
        );
        const schoolName = lesson.school.name.includes('Grimentz') ? 'Grimentz-Zinal' : lesson.school.name;

        return (
            <Paper className="__products-card-frame">
                <div className={`__products-card-parent __products-card-parent-${lesson.is_visible ? 'enabled' : 'disabled'}`} onClick={() => lesson.is_visible && this.handleAvailabilityClicked()}>
                    <Grid container alignItems="center" alignContent="center" justifyContent="center">
                        {
                            windowWidth <= SMARTPHONE_WIDTH ? null
                                : (
                                    <Grid className="__products-card-left-pane" item xs={3}>
                                        {image}
                                    </Grid>
                                )
                        }
                        <Grid className="__products-card-right-pane" direction="column" container item xs={windowWidth <= SMARTPHONE_WIDTH ? 12 : 9}>
                            <div>
                                <div className="__products-card-header">
                                    <Title className={`__products-card-title${lesson.is_visible ? '' : '-unavailable'}`} onClick={() => lesson.is_visible && this.handleAvailabilityClicked()}>{lesson.title}</Title>
                                </div>
                                {
                                    windowWidth <= SMARTPHONE_WIDTH ? image : null
                                }
                                <div className="__products-card-body">
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <span className="__products-card-text" dangerouslySetInnerHTML={{ __html: (lesson.description === 'nan') ? '' : lesson.description }} />
                                </div>
                            </div>

                            <div className="__products-card-bottom">
                                <div className="__products-card-bottom-left">
                                    <div className="__products-card-info">
                                        <p className="__products-card-info-title">
                                            <FormattedMessage id="products.location" />
                                            :
                                        </p>
                                        <p className="__products-card-info-text">{schoolName}</p>
                                    </div>
                                </div>
                                <div className="__products-card-bottom-right">
                                    {
                                        windowWidth < SMARTPHONE_WIDTH
                                            ? null
                                            : (
                                                <Button disabled={!lesson.is_visible} containerClasses="__products-card-button-container" buttonClasses="__products-card-button" onClick={this.handleAvailabilityClicked}>
                                                    <FormattedMessage id={lesson.is_visible ? 'products.lessonButton' : 'products.lessonButton.unavailable'} />
                                                </Button>
                                            )
                                    }
                                </div>
                                {
                                    windowWidth < SMARTPHONE_WIDTH
                                        ? (
                                            <Button disabled={!lesson.is_visible} containerClasses="__products-card-button-container" buttonClasses="__products-card-button" onClick={this.handleAvailabilityClicked}>
                                                <FormattedMessage id={lesson.is_visible ? 'products.lessonButton' : 'products.lessonButton.unavailable'} />
                                            </Button>
                                        )
                                        : null
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    selectedProductsDate: store.products.selectedProductsDate,
    windowWidth: store.windowSize.width,
    language: store.translation.language,
});
const connector = connect(mapStateToProps);

export default connector(Card);
