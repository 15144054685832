/* eslint-disable no-nested-ternary */
import _, { toInteger } from 'lodash';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { LESSON_FILTERS } from '../../resources/constants';
import '../../resources/css/booking.css';
import { domaineBaseHasSubdomainAlready } from '../../resources/utils';
import axiosapi from '../../store/actions/axiosapi';
import {
    fetchFreeInstructors,
    fetchInputParticipant,
    fetchLesson, fetchLessonDetails, fetchOptions, fetchQuestions,
    fetchSchoolInstructorsConfiguration,
    getSchoolsList,
    initBooking,
    setLesson,
    setParticipantsInfos, setRemarks,
    setSchoolInstructorsConfiguration,
    setSelectedLessonDetail,
    setSelectedOptions
} from '../../store/actions/booking';
import { getBCClient } from '../../store/actions/settings';
import { openSignUpForm } from '../../store/actions/user';
import { ApplicationState, StoreDispatch } from '../../store/types';
import {
    BodyFreeInstructors,
    BodyLessonDetails,
    BodyOptions,
    BodyParticipant,
    BodyQuestions, Lesson, LessonCart, LessonDetail, Level
} from '../../types/booking';
import { RouterProps } from '../../types/generalTypes';
import Authenticator from '../authenticator';
import Button from '../common/button';
import Container from '../common/container';
import Expandable from '../common/expandable';
import LoadingCircle from '../common/loadingCircle';
import Snackbar from '../common/snackbar';
import Title from '../common/title';
import OrderApi from '../order/api/order_api';
import AlertModal from './alertModal';
import BookingHeader from './bookingHeader';
import DetailsBar from './detailsbar';
import FreeInstructors from './freeInstructors';
import GroupLessonDetails from './groupLessonDetails';
import Options from './options';
import Participants from './participants';
import PrivateLessonDetails from './privateLessonDetails';
import Questions from './questions';
import Remarks from './remarks';
import Selection from './selection';
import SelectionDrawer from './selectionDrawer';
/**
 * Component for the Booking page
 */

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & WrappedComponentProps & ReduxProps & RouterProps;

interface State {
    currentLesson?: Lesson;
    fromDate?: string | Moment;
    lessonsDetails: LessonDetail[];
    minParticipants: number;
    maxParticipants: number;
    nbParticipants: number;
    levelId: any;
    offset: number;
    detailsBarOpen: boolean;
    participantsOpen: boolean;
    optionsOpen: boolean;
    freeInstructorsOpen: boolean;
    questionsOpen: boolean;
    remarksOpen: boolean;
    participantsVisible: boolean;
    optionsVisible: boolean;
    freeInstructorsVisible: boolean;
    questionsVisible: boolean;
    remarksVisible: boolean;
    currentLevel?: Level;
    hasChoosed: boolean;
    openAlert: boolean;
    alertSchool?: any;
    isLessonLoading: boolean;
    isCheckAvailabilityLoading: boolean;
    openSnackbar: boolean;
    minHoursBeforeBooking?: number;
    // openSnackbarMessage: string;
    reload: boolean;
    limit?: number;
    toDate?: string;
}

class Booking extends React.Component<Props, State> {
    static cartToBooking = (gotCart: LessonCart[]) => {
        const cart = gotCart || [];
        return cart.filter((l) => l.instructor !== undefined && l.instructor !== null).map((lesson) => {
            const fromTime = moment(lesson.time.from_time);
            const toTime = moment(lesson.time.to_time.time);

            return {
                ProductId: lesson.external_id,
                ActivityId: lesson.activity_id,
                CategoryId: lesson.level?.external_id,
                From: moment(lesson.date.from_date).hours(fromTime.hours()).minutes(fromTime.minutes()).seconds(fromTime.seconds())
                    .format('YYYY-MM-DDTHH:mm:ss'),
                To: moment(lesson.date.to_date).hours(toTime.hours()).minutes(toTime.minutes()).seconds(toTime.seconds())
                    .format('YYYY-MM-DDTHH:mm:ss'),
                MeetingPointId: lesson.meeting_point.id,
                NbDays: lesson.days,
                NbMinutes: toTime.diff(fromTime, 'minutes'),
                NbParticipants: lesson.participants,
                InstructorId: lesson.instructor ? lesson.instructor.Id : undefined,
                InstructorPriority: lesson.instructor ? lesson.instructor.priority : undefined,
                Price: lesson.price + (typeof lesson.totalOptionsPrice === 'string' ? parseFloat(lesson.totalOptionsPrice) : lesson.totalOptionsPrice),
            };
        });
    };

    static replaceSubdomain = (urlParam: string, toSubdomain: any): any => {
        const values = domaineBaseHasSubdomainAlready();
        const replace = `://${toSubdomain}.`;
        let url = urlParam;
        // Prepend http://
        if (!/^\w*:\/\//.test(url)) {
            url = `http://${url}`;
        }

        // Check if we got a subdomain in url
        const match = url.match(/\.\w*\b/g);
        if (match && match.length > 1 && match.length > (values.max - 1)) {
            return url.replace(/(:\/\/\w+\.)/, replace);
        }

        return url.replace(/:\/\/(\w*\.)/, `${replace}$1`);
    };

    static toggleStateKey<T extends keyof State>(key: T, state: State, value?: boolean): Pick<State, T> {
        if (typeof state[key] === 'boolean') {
            if (value !== undefined) {
                return { [key]: value } as Pick<State, T>;
            }

            return { [key]: !state[key] } as Pick<State, T>;
        }
        throw new Error(`Property ${key} is not a boolean`);
    }

    constructor(props: Props) {
        super(props);

        const { runInitBooking, history } = this.props;

        runInitBooking();

        this.state = {
            currentLesson: (history.location.state && history.location.state.lesson) === undefined
                ? null : history.location.state.lesson,
            fromDate: (history.location.state
                && history.location.state.selectedLessonDate) === undefined
                ? null : history.location.state.selectedLessonDate,
            lessonsDetails: [],
            minParticipants: 1,
            maxParticipants: 10,
            nbParticipants: 1,
            levelId: -1,
            offset: 0,
            limit: 0,
            detailsBarOpen: true,
            participantsOpen: false,
            optionsOpen: false,
            freeInstructorsOpen: false,
            questionsOpen: false,
            remarksOpen: false,
            participantsVisible: false,
            optionsVisible: false,
            freeInstructorsVisible: false,
            questionsVisible: false,
            remarksVisible: false,
            currentLevel: undefined,
            hasChoosed: false,
            openAlert: false,
            alertSchool: null,
            isLessonLoading: false,
            isCheckAvailabilityLoading: false,
            openSnackbar: false,
            minHoursBeforeBooking: undefined,
            reload: false, // if there is no lessons, set date to 15 november
        };
    }

    componentDidMount() {
        const {
            history, bcClient, runGetBCClient, runFetchLesson, match, location, runSetLesson, runFetchSchoolInstructorsConfiguration, runGetSchoolsList
        } = this.props;
        const {
            currentLesson, fromDate, offset, nbParticipants,
        } = this.state;
        document.getElementById('top')?.scrollIntoView();
        if ((history.location.state && history.location.state.selectedLessonDate) !== undefined) {
            const shortFromDate = moment(history.location.state.selectedLessonDate).format('YYYYMMDD');
            this.addQueryParam('d', shortFromDate);
        }

        // load booking corner client
        if (!bcClient && Authenticator.isLoggedIn()) runGetBCClient(match.params.lang);

        // load lesson if come directly from link
        if (currentLesson === null && location.search) {
            const query:any = qs.parse(location.search, {
                ignoreQueryPrefix: true,
                comma: true
            });
            if (query.d || query.p || query.l) {
                if (query.p && Number.isNaN(query.p)) {
                    this.addQueryParam('p', 1);
                }
                if (query.d && !moment(query.d, 'YYYYMMDD').isValid()) {
                    const shortFromDate = moment().format('YYYYMMDD');
                    this.addQueryParam('d', shortFromDate);
                }

                this.setState((state) => ({
                    fromDate: query.d ? moment(query.d, 'YYYYMMDD').isValid() ? moment(query.d, 'YYYYMMDD') : moment() : fromDate,
                    nbParticipants: query.p ? isNaN(query.p) ? 1 : parseInt(query.p, 10) : nbParticipants,
                    levelId: query.l ? query.l : state.levelId
                }), () => {
                    if (query.id) {
                        const params = query.id.split('-');
                        runFetchLesson(params[0], params[1], match.params.lang);
                        this.setState({ isLessonLoading: true });
                    }
                });
            } else if (query.id) {
                const params = query.id.split('-');
                runFetchLesson(params[0], params[1], match.params.lang);
                this.setState({ isLessonLoading: true });
            }
        }

        if (currentLesson != null && fromDate != null) {
            // history.replace(`/${match.params.lang}/booking?id=${currentLesson.external_id}-${currentLesson.external_activity_id}`);
            this.addQueryParam('id', `${currentLesson.external_id}-${currentLesson.external_activity_id}`);
            this.translateLevels();
            runSetLesson(currentLesson);

            if (currentLesson.lesson_type === LESSON_FILTERS.PRIVATE_LESSON) {
                // load school instructors configuration
                runFetchSchoolInstructorsConfiguration(currentLesson.school.external_id, currentLesson.department_id);
                // load schools list
                runGetSchoolsList();
            }

            // use the length of the "days_duration" array to determine the limit in the query
            this.setState(
                (state) => ({
                    currentLevel: state.currentLesson != null ? state.currentLesson.levels[0] : undefined,
                    limit: state.currentLesson != null ? (state.currentLesson.school.external_id === '2' && state.currentLesson.lesson_type === 'P' ? 3 : state.currentLesson.days_duration.length) : undefined,
                    toDate: moment(state.fromDate).add(10, 'month').format('YYYY-MM-DDT00:00:00'),
                }),
                () => {
                    this.fetchLessonsDetails(offset);
                }
            );
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            lesson,
            runFetchSchoolInstructorsConfiguration,
            runGetSchoolsList,
            lessonsDetails,
            currentSchool,
            history,
            match,
            isLessonsDetailsLoading,
        } = this.props;
        const {
            currentLesson,
            levelId,
            offset,
            nbParticipants,
            minParticipants,
            maxParticipants,
            currentLevel,
            reload
        } = this.state;
        // if come from link, fetch lesson details
        if (!_.isEqual(prevProps.lesson, lesson) && currentLesson === null && lesson && Object.keys(lesson).length > 0) {
            const lessonTmp: Lesson | undefined = lesson ? Object.keys(lesson).length > 0 ? lesson : prevProps.lesson : undefined;
            let level: Level | undefined;
            if (lessonTmp == null) return;
            level = lessonTmp.levels.find((l) => parseInt(l.id, 10) === parseInt(levelId, 10));
            if (!level) {
                // eslint-disable-next-line prefer-destructuring
                level = lessonTmp.levels[0];
                this.handleChangeLevel(level, false);
            }

            this.setState(
                (state) => ({
                    currentLesson: lessonTmp,
                    currentLevel: level,
                    limit: lessonTmp ? (lessonTmp.school.external_id === '2' && lessonTmp.lesson_type === 'P' ? 3 : lessonTmp.days_duration.length) : 0,
                    fromDate: state.fromDate ? moment(state.fromDate).format('YYYY-MM-DDT00:00:00') : moment().format('YYYY-MM-DDT00:00:00'),
                    toDate: state.fromDate ? moment(state.fromDate).add(10, 'month').format('YYYY-MM-DDT00:00:00') : moment().add(10, 'month').format('YYYY-MM-DDT00:00:00'),
                    isLessonLoading: false,
                }),
                () => {
                    this.translateLevels();
                    this.fetchLessonsDetails(offset);
                    if (lessonTmp?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON) {
                        // load school instructors configuration
                        runFetchSchoolInstructorsConfiguration(lessonTmp.school.external_id, lessonTmp.department_id);
                        // load schools list
                        runGetSchoolsList();
                    }
                }
            );
        }

        // If new lesson details => concat to the current state with lesson details already loaded
        if (prevProps.lessonsDetails !== lessonsDetails && Object.keys(lessonsDetails).length > 0) {
            this.setState((state) => ({ lessonsDetails: state.lessonsDetails.concat(lessonsDetails) }));
        }

        // if there is no lessons, set date to 15 november
        // if (!reload && currentLesson && lessonsDetails.length === 0) {
        //     const november = moment().month(10).days(14);
        //     if (moment().diff(november, 'days') <= 3) {
        //         this.handleDateFrom(moment(november));
        //         this.setState({ reload: true });
        //     }
        // }

        // if the lesson's school is not the same as the current school go back to products page
        if (currentSchool && currentLesson && currentSchool.int_id !== currentLesson.school.external_id) {
            history.push(`/${match.params.lang}/products`);
        }

        if (currentLesson && currentLesson.school.id) {
            // const destination = destinations.find(d => d.companyId === currentLesson.school.id);

            const subdomain = window.location.host.split('.')[2] ? window.location.host.split('.')[0] : false;
            let nextSubdomain = currentLesson.school.name.toLowerCase().replace('ess ', '').replace(/ /g, '').replace(/'/g, '')
                .normalize('NFD')
                .replace(/\p{Diacritic}/gu, '');
            let url = Booking.replaceSubdomain(window.location.href, nextSubdomain);
            if (currentLesson.school.name.toLowerCase().indexOf('diablerets') > 0) {
                (url = Booking.replaceSubdomain(window.location.href, 'diablerets'));
                nextSubdomain = 'diablerets';
            }
            if (currentLesson.school.name.toLowerCase().indexOf('villars-sur-ollon') > 0) {
                (url = Booking.replaceSubdomain(window.location.href, 'villars'));
                nextSubdomain = 'villars';
            }
            if (subdomain === false || subdomain !== nextSubdomain) {
                window.location.href = url;
            }
        }

        // After lesson load, check min & max participants
        if (prevProps.isLessonsDetailsLoading && !isLessonsDetailsLoading) {
            // Check min
            // let level = lesson.levels.find(l => l.id === levelId)
            let newNbParticipants;
            if (currentLevel && nbParticipants > currentLevel.min_participant) {
                if (currentLevel.min_participant >= 1) {
                    newNbParticipants = currentLevel.min_participant;
                } else {
                    newNbParticipants = 1;
                }
            }
            // Check max
            const max = lesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON ? 6 : 10;
            newNbParticipants = undefined;
            if (max && nbParticipants > max) {
                newNbParticipants = max;
            }

            // Change nb participants if necessary
            if (newNbParticipants !== undefined) {
                this.handleParticipant(newNbParticipants);
            }
        }
        // After lesson load, set min & max
        if (prevProps.isLessonsDetailsLoading && !isLessonsDetailsLoading) {
            const minParticipantsTmp = currentLevel ? currentLevel.min_participant >= 1 ? currentLevel.min_participant : 1 : 1;
            const maxParticipantsTmp = lesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON ? 6 : 10;

            if (minParticipants && minParticipantsTmp !== minParticipants) {
                this.setState({ minParticipants: minParticipantsTmp });
            }
            if (maxParticipants && maxParticipantsTmp !== maxParticipants) {
                this.setState({ maxParticipants: maxParticipantsTmp });
            }
        }
    }

    componentWillUnmount() {
        const { runSetSelectedLessonDetail, runSetSchoolInstructorsConfiguration } = this.props;
        // reset selected lesson details
        runSetSelectedLessonDetail({});
        runSetSchoolInstructorsConfiguration(undefined);
    }

    // updateUrl = () => {
    //     let query = qs.stringify(final, { addQueryPrefix: true, delimiter: '&', encodeValuesOnly: true, indices: false, arrayFormat: 'comma', encode: false });
    //     history.replace(`/${match.params.lang}/products${query}`);

    // }

    /**
     * Update "date from" and set parameters to default
     * @param dateFrom new date to set
     */
    handleDateFrom = (dateFrom: Moment) => {
        const { hasChoosed } = this.state;
        const newOffset = 0;
        const { runSetSelectedLessonDetail } = this.props;
        const newFromDate = dateFrom.format('YYYY-MM-DDT00:00:00');
        const shortFromDate = dateFrom.format('YYYYMMDD');
        const newToDate = moment(newFromDate).add(10, 'month').format('YYYY-MM-DDT00:00:00');
        this.addQueryParam('d', shortFromDate);
        this.setState({
            fromDate: newFromDate,
            toDate: newToDate,
            offset: newOffset,
            lessonsDetails: [],

            participantsOpen: false,
            participantsVisible: false,
            optionsOpen: false,
            optionsVisible: false,
            questionsOpen: false,
            questionsVisible: false,
            remarksOpen: false,
            remarksVisible: false,
            detailsBarOpen: true,
        }, () => {
            this.fetchLessonsDetails(newOffset);
        });

        if (hasChoosed) {
            runSetSelectedLessonDetail({});
        }
    };

    /**
     * update date to and set parameters to default
     * @param dateTo
     */
    // handleDateTo = (dateTo: string) => {
    //     const { offset } = this.state;
    //     this.setState({
    //         toDate: moment(dateTo).format('YYYY-MM-DDT00:00:00'),
    //         offset: 0,
    //         lessonsDetails: [],
    //     }, () => {
    //         this.fetchLessonsDetails(offset);
    //     });
    // }

    /**
     * update participant number and set parameters to default
     * @param participant
     */
    handleParticipant = (nbParticipants: number) => {
        const { hasChoosed } = this.state;
        const newOffset = 0;
        const { runSetSelectedLessonDetail } = this.props;
        this.addQueryParam('p', nbParticipants);
        this.setState({
            nbParticipants,
            offset: newOffset,
            lessonsDetails: [],
            participantsOpen: false,
            participantsVisible: false,
            optionsOpen: false,
            optionsVisible: false,
            questionsOpen: false,
            questionsVisible: false,
            remarksOpen: false,
            remarksVisible: false,
            detailsBarOpen: true,
        }, () => {
            this.fetchLessonsDetails(newOffset);
        });

        if (hasChoosed) {
            runSetSelectedLessonDetail({});
        }
    };

    /**
     * update booking details with a different level
     * @param level
     */
    handleChangeLevel = (level: Level, loadLessonsDetails = true) => {
        const { runSetSelectedLessonDetail } = this.props;
        const { hasChoosed } = this.state;
        const newOffset = 0;
        const newLevelId: any = level.id;
        this.addQueryParam('l', newLevelId);
        this.setState({
            offset: newOffset,
            levelId: newLevelId,
            lessonsDetails: [],
            currentLevel: level,

            participantsOpen: false,
            participantsVisible: false,
            optionsOpen: false,
            optionsVisible: false,
            questionsOpen: false,
            questionsVisible: false,
            remarksOpen: false,
            remarksVisible: false,
            detailsBarOpen: true,
        }, () => {
            if (loadLessonsDetails) this.fetchLessonsDetails(newOffset);
        });

        if (hasChoosed) {
            runSetSelectedLessonDetail({});
        }
    };

    /**
     * increment offset by limit and fetch more lessons
     */
    handleClickMore = () => {
        const { offset } = this.state;
        let newOffset = offset;
        this.setState((state) => {
            newOffset = state.offset + (state.limit ?? 0);
            return { offset: (state.offset + (state.limit ?? 0)) };
        }, () => this.fetchLessonsDetails(newOffset));
    };

    /**
     * Set/change query param
     * @param key param to set/change
     * @value param's value
     */
    addQueryParam = (key: any, value: any) => {
        const { history, match } = this.props;
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        // window.history.pushState({}, '', url.toString());
        history.replace(`/${match.params.lang}/booking${url.search}`);
        // window.location.replace(url.toString());
    };

    getNoAvailabilitySnackbarMessage = () => {
        const { intl, currentSchool } = this.props;
        const { minHoursBeforeBooking, currentLesson } = this.state;
        return (
            <div>
                {

                    // currentLesson.school.id == 4 ?
                    //     <p>{intl.formatMessage({ id: 'booking.noMoreAvailabilityNendaz' }, { school: currentLesson.school.name })}</p>
                    //     :
                    minHoursBeforeBooking && minHoursBeforeBooking > 0
                        ? (
                            <>
                                <p>{intl.formatMessage({ id: 'booking.minHoursBeforeBooking' }, { minHoursBeforeBooking, school: currentLesson && currentLesson.school.name })}</p>
                                {
                                    currentSchool && currentSchool.email.length > 0
                                        ? (
                                            <p style={{ marginLeft: '10px' }}>
                                                {' '}
                                                <a title={`Mail ${currentSchool?.name}`} href={`mailto:${currentSchool?.email}`}>{currentSchool?.email}</a>
                                            </p>
                                        )
                                        : null
                                }
                            </>
                        )
                        : <p>{intl.formatMessage({ id: 'booking.noMoreAvailability' }, { school: currentLesson && currentLesson.school.name })}</p>
                }
                {/* <p>{openSnackbarMessage}</p> */}
                {/* {
                    currentLesson.school.id == 4 ?

                        <p><br />{intl.formatMessage({ id: 'booking.noMoreAvailabilityContact' })}<br /><a className="__booking-lesson-no-availability-snackbar-link" href="mailto:info@skinendaz.ch">info@skinendaz.ch</a>, <a className="__booking-lesson-no-availability-snackbar-link" href="tel:+41272882975">+41 (0)27 288 29 75</a></p>
                        : null
                } */}
            </div>
        );
    };

    checkAvailability = async (detailLesson: LessonDetail, fromTime: Moment, toTime: Moment) => {
        this.setState({ isCheckAvailabilityLoading: true });
        const { currentLesson, currentLevel } = this.state;
        if (currentLesson == null) return false;
        const days = currentLesson.lesson_type === LESSON_FILTERS.GROUP_LESSON ? detailLesson.days : detailLesson.days[detailLesson.selectedDayIndex];
        // message d'erreur pour indiquer que la période n'a pas de meeting points
        if (detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex] === undefined) return false;
        const payload = {
            bookings: [
                {
                    From: moment(detailLesson.startDate).hours(fromTime.hours()).minutes(fromTime.minutes()).seconds(fromTime.seconds())
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    To: moment(detailLesson.endDate).hours(toTime.hours()).minutes(toTime.minutes()).seconds(toTime.seconds())
                        .format('YYYY-MM-DDTHH:mm:ss'),
                    lessonId: currentLesson.id,
                    ProductId: currentLesson.external_id,
                    ActivityId: currentLesson.external_activity_id,
                    CategoryId: currentLevel?.external_id,
                    meetingPointId: detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex].id,
                    nbDays: days,
                    nbParticipants: detailLesson.participants,
                    nbMinutes: toTime.diff(fromTime, 'minutes'),
                    participants: [],
                },
            ],
        };

        const response = await OrderApi.checkAvailability(payload).catch((error) => {
            throw error;
        });

        return response;
    };

    checkFreeInstructors = async (detailLesson: LessonDetail, start: Moment, end: Moment, cart: any) => {
        const { currentLesson, currentLevel } = this.state;

        const body = {
            lessonId: currentLesson ? currentLesson.id : undefined,
            LanguageId: '',
            CheckLanguage: false,
            Bookings: cart,
            Booking: {
                ProductId: currentLesson ? currentLesson.external_id : undefined,
                ActivityId: currentLesson ? currentLesson.external_activity_id : undefined,
                CategoryId: currentLevel?.external_id,
                From: moment(detailLesson.startDate).hours(start.hours()).minutes(start.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                To: moment(detailLesson.endDate).hours(end.hours()).minutes(end.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                MeetingPointId: detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex].id,
            },
        };

        const response = await axiosapi.post('/booking-corner/check-free-instructors/', body);

        return response.data.length !== 0;
    };

    handleClickSelectDetailLesson = async (detailLessonParam: any) => {
        const {
            lesson, schoolInstructorsConfiguration, runOpenSignUpForm, language,
            runSetParticipantsInfos,
            runSetRemarks,
            runSetSelectedOptions,
            runSetSelectedLessonDetail,
            runFetchInputParticipant,
            runFetchOptions,
            runFetchQuestions,
            runFetchFreeInstructors,
        } = this.props;
        const {
            currentLesson, currentLevel, nbParticipants, hasChoosed,
        } = this.state;
        const detailLesson = detailLessonParam;
        this.setState({ openSnackbar: false, minHoursBeforeBooking: undefined });
        let stop = false;
        // let cart = Cookie.get('cart');
        const jsonCart = localStorage?.getItem('cart');

        const cart: LessonCart[] = JSON.parse(jsonCart || '[]');
        const freeInstructorCart = Booking.cartToBooking(cart);

        // check that the cart contains only lesson from same school (or is empty)
        if (currentLesson == null) {
            stop = true;
        } else if (cart && cart.length > 0) {
            const school = cart[0].school_id.id;
            if (school !== currentLesson.school.id) {
                stop = true;
                this.setState({
                    openAlert: true,
                    alertSchool: cart[0].school_id.name,
                });
            }
        }

        if (stop || currentLesson == null) return;

        // get start and end date
        const start = moment(detailLesson.times[detailLesson.selectedStartTimeIndex].startTime);
        const end = moment(detailLesson.times[detailLesson.selectedStartTimeIndex].endTimes[detailLesson.selectedEndTimeIndex].time);
        detailLesson.nbMinutes = end.diff(start, 'minutes');

        // check availability for the choosen lesson

        const school = currentLesson.school.external_id;
        let successData: any;
        let success = true;
        try {
            successData = await this.checkAvailability(detailLesson, start, end);
        } catch (error) {
            this.setState({ isCheckAvailabilityLoading: false, openSnackbar: true, minHoursBeforeBooking: undefined });
            return;
        }
        success = successData.data.unavailableLessonsId.length === 0;

        if (!success) {
            // open modal
            let minHoursBeforeBooking;
            if (successData.data.messages.length > 0 && successData.data.messages[0].includes('MinHoursBeforeBooking')) {
                let minHoursBeforeBookingMessage = successData.data.messages[0];
                minHoursBeforeBookingMessage = minHoursBeforeBookingMessage.split(' : ');
                if (minHoursBeforeBookingMessage.length === 2) {
                    minHoursBeforeBooking = toInteger(minHoursBeforeBookingMessage[1]);
                }
            }
            this.setState({ isCheckAvailabilityLoading: false, openSnackbar: true, minHoursBeforeBooking });
            return;
        }
        this.setState({ isCheckAvailabilityLoading: false });

        // Check it was free instructors before load lesson details for availability
        if (lesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON
            && (Number(school) === 2 || Number(school) === 6 || Number(school) === 8 || Number(school) === 5 || schoolInstructorsConfiguration?.mustSelectInstructor)) {
            if (success) await this.checkFreeInstructors(detailLesson, start, end, freeInstructorCart);
        }

        // Temporary moved to componentDidUpdate
        if (!Authenticator.isLoggedIn()) {
            runOpenSignUpForm();
            return;
        }

        // continue

        detailLesson.level = currentLevel;
        const bodyParticipant: BodyParticipant = {
            lessonId: currentLesson.id,
            levelId: currentLevel?.id,
            fromDate: detailLesson.startDate,
        };

        let days = 0;

        if (lesson?.lesson_type === LESSON_FILTERS.GROUP_LESSON) days = detailLesson.days;
        else days = detailLesson.days[detailLesson.selectedDayIndex];

        let startTimeWorkaround;
        const detailLessonStartTime = detailLesson.times[detailLesson.selectedStartTimeIndex].startTime[0];

        // workaround startTime
        if (typeof detailLesson.times[detailLesson.selectedStartTimeIndex].startTime === 'string'
            || detailLesson.times[detailLesson.selectedStartTimeIndex].startTime instanceof String) {
            startTimeWorkaround = detailLesson.times[detailLesson.selectedStartTimeIndex].startTime;
        } else {
            startTimeWorkaround = detailLessonStartTime;
        }

        const bodyOptions: BodyOptions = {
            lessonId: currentLesson.id,
            levelId: currentLevel?.id,
            fromDate: detailLesson.startDate,
            toDate: detailLesson.endDate,
            // "startTime": detailLesson.times[detailLesson.selectedStartTimeIndex].startTime,
            startTime: startTimeWorkaround,
            endTime: detailLesson.times[detailLesson.selectedStartTimeIndex].endTimes[detailLesson.selectedEndTimeIndex].time,
            nbDays: days,
            nbParticipants,
        };

        const bodyQuestions: BodyQuestions = {
            lessonId: currentLesson.id,
            levelId: currentLevel?.id,
        };

        const bodyFreeInstructors: BodyFreeInstructors = {
            lessonId: currentLesson.id,
            LanguageId: '',
            CheckLanguage: true,
            Bookings: freeInstructorCart,
            Booking: {
                ProductId: currentLesson.external_id,
                ActivityId: currentLesson.external_activity_id,
                CategoryId: currentLevel?.external_id,
                From: moment(detailLesson.startDate).hours(start.hours()).minutes(start.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                To: moment(detailLesson.endDate).hours(end.hours()).minutes(end.minutes()).format('YYYY-MM-DDTHH:mm:ss'),
                MeetingPointId: detailLesson.meetingPoints[detailLesson.selectedMeetingPointIndex].id,
            },
        };

        // reset if a lesson has already been choosen
        if (hasChoosed) {
            this.setState({
                participantsOpen: false,
                participantsVisible: false,
                optionsOpen: false,
                optionsVisible: false,
                questionsOpen: false,
                questionsVisible: false,
                remarksOpen: false,
                remarksVisible: false,
                freeInstructorsVisible: false,
                freeInstructorsOpen: false,
                detailsBarOpen: true,
            }, () => {
                this.toggleExpandable('detailsBarOpen', 'participantsOpen');
                this.toggleVisibility('participantsVisible');
            });
            runSetParticipantsInfos([]);
            runSetRemarks([]);
            runSetSelectedOptions([]);
        } else {
            this.toggleExpandable('detailsBarOpen', 'participantsOpen');
            this.toggleVisibility('participantsVisible');
        }

        const detailsbar = document.getElementById('detailsbar');
        if (detailsbar) detailsbar.scrollIntoView();
        runSetSelectedLessonDetail(detailLesson);
        runFetchInputParticipant(bodyParticipant, language);
        runFetchOptions(bodyOptions, language);
        runFetchQuestions(bodyQuestions, language);

        if (lesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON && schoolInstructorsConfiguration
            && schoolInstructorsConfiguration.canSelectInstructor) {
            runFetchFreeInstructors(bodyFreeInstructors, language);
        }
        this.setState({ hasChoosed: true });
    };

    translateLevels = () => {
        const { match } = this.props;
        const { currentLesson, levelId } = this.state;
        const headers = {
            headers: {
                'Accept-Language': match.params.lang,
            },
        };
        if (currentLesson == null) return;
        const currentLessonLevel = currentLesson.levels[0];

        axiosapi.post('/booking-corner/product/', { ProductId: currentLesson.external_id, ActivityId: currentLesson.external_activity_id, From: currentLesson.fromDate }, headers)
            .then((response) => {
                const categories = response.data.Categories;
                currentLesson.levels.map((levvel) => {
                    const l = levvel;
                    if (categories) {
                        for (const category of categories) {
                            if (levvel.external_id === category.Id) {
                                l.memo = category.Memo;
                                l.name = category.Description;
                                // break; TODO Verif mathias
                            }
                        }
                    }
                    return l;
                });
                let level = currentLesson.levels.find(
                    (l) => parseInt(l.id, 10) === parseInt(levelId, 10),
                );
                if (!level) level = currentLessonLevel;
                this.setState({ currentLesson, currentLevel: level });
            })
            .catch((error) => {
                throw new Error(`${error}`);
            });
    };

    fetchLessonsDetails = (offset: number) => {
        const { language, runFetchLessonDetails } = this.props;
        const {
            currentLesson, fromDate, toDate, nbParticipants, levelId, limit,
        } = this.state;
        if (currentLesson == null) return;
        const body: BodyLessonDetails = {
            lessonId: currentLesson.id,
            fromDate,
            toDate, // TODO: delete 'toDate' in API Python??
            nbParticipants,
        };

        if (currentLesson.levels[0] && levelId === -1) {
            body.levelId = currentLesson.levels[0].id;
        } else {
            body.levelId = levelId;
        }

        const params = { offset, limit };

        runFetchLessonDetails(body, params, language);
    };

    toggleExpandable = (param: string, optional?: string) => {
        this.setState((prevState) => Booking.toggleStateKey(param as keyof State, prevState));

        if (optional) {
            this.setState(
                (prevState) => Booking.toggleStateKey(
                    optional as keyof State,
                    prevState,
                    true,
                ),
            );
        }
    };

    toggleVisibility = (param: string) => {
        this.setState((prevState) => Booking.toggleStateKey(param as keyof State, prevState, true));
    };

    render() {
        const {
            windowWidth,
            lesson,
            details,
            intl,
            isLessonsDetailsLoading,
            options,
            questions,
            hasMoreLessonsDetails,
            freeInstructors,
            schoolInstructorsConfiguration,
        } = this.props;
        const {
            lessonsDetails: stateLessonsDetails,
            currentLevel,
            hasChoosed,
            isCheckAvailabilityLoading,
            openAlert,
            alertSchool,
            openSnackbar,
            participantsVisible,
            isLessonLoading,
            detailsBarOpen,
            fromDate,
            nbParticipants,
            minParticipants,
            maxParticipants,
            levelId,
            currentLesson,
            participantsOpen,
            freeInstructorsVisible,
            freeInstructorsOpen,
            optionsVisible,
            optionsOpen,
            questionsOpen,
            questionsVisible,
            remarksVisible,
            remarksOpen,
        } = this.state;
        const bigScreen = windowWidth > 1000;

        const lessonsDetails = stateLessonsDetails.map((lessonDetail, idx) => {
            const i = idx;
            if (currentLevel === undefined) return null;
            return (
                <div key={`lesson_details_${i}`}>
                    {
                        lesson?.lesson_type === LESSON_FILTERS.GROUP_LESSON
                            ? (
                                <GroupLessonDetails
                                    lessonDetail={lessonDetail}
                                    currentLevel={currentLevel}
                                    onClickSelect={this.handleClickSelectDetailLesson}
                                    bigScreen={bigScreen}
                                    hasChoosed={hasChoosed}
                                    isCheckAvailabilityLoading={isCheckAvailabilityLoading}
                                />
                            )
                            : (
                                <PrivateLessonDetails
                                    lessonDetail={lessonDetail}
                                    currentLevel={currentLevel}
                                    onClickSelect={this.handleClickSelectDetailLesson}
                                    bigScreen={bigScreen}
                                    hasChoosed={hasChoosed}
                                    isCheckAvailabilityLoading={isCheckAvailabilityLoading}
                                />
                            )
                    }
                </div>
            );
        });

        const detailsEmpty = _.isEmpty(details);

        return (
            <Container
                headerBase={lesson && Object.keys(lesson).length > 0 ? intl.formatMessage({ id: `products.${lesson.lesson_type === LESSON_FILTERS.GROUP_LESSON ? 'group' : 'private'}` }) : intl.formatMessage({ id: 'booking.booking' })}
            >
                <div className="__booking-parent">
                    <AlertModal open={openAlert} school={alertSchool} />
                    <Snackbar
                        variant="warning"
                        open={openSnackbar}
                        onClose={() => this.setState({ openSnackbar: false, minHoursBeforeBooking: undefined })}
                        message={this.getNoAvailabilitySnackbarMessage}
                        // messageAsFunction
                    />
                    <div className={(bigScreen && participantsVisible === true) ? '__booking-left-small' : '__booking-left-big'}>
                        {
                            bigScreen ? null
                                : <SelectionDrawer detailsEmpty={detailsEmpty} />
                        }
                        <BookingHeader currentLevel={currentLevel} />
                        {
                            (isLessonLoading || currentLesson == null)
                                ? <LoadingCircle />
                                : (
                                    <div className="__booking-left-content" id="detailsbar">
                                        <DetailsBar
                                            title={intl.formatMessage({ id: 'booking.title' })}
                                            open={detailsBarOpen}
                                            handleExpandable={() => this.toggleExpandable('detailsBarOpen')}
                                            fromDate={fromDate ? moment(fromDate).format('YYYY-MM-DDT00:00:00') : moment().format('YYYY-MM-DDT00:00:00')}
                                            onChangeDateFrom={this.handleDateFrom}
                                            selectedParticipant={nbParticipants}
                                            onChangeParticipant={this.handleParticipant}
                                            minParticipants={minParticipants}
                                            maxParticipants={maxParticipants}
                                            selectedLevel={parseInt(levelId, 10)}
                                            onChangeLevel={this.handleChangeLevel}
                                            lesson={currentLesson}
                                            disabled={isLessonsDetailsLoading}
                                        >
                                            {
                                                currentLesson != null
                                                    ? (
                                                        <div>
                                                            {lessonsDetails}
                                                        </div>
                                                    )
                                                    : (
                                                        <div className="__booking-left-no-lesson">
                                                            <Title>{intl.formatMessage({ id: 'booking.selectLesson' })}</Title>
                                                        </div>
                                                    )

                                            }
                                            {
                                                isLessonsDetailsLoading
                                                    ? <LoadingCircle />
                                                    : currentLesson && lessonsDetails.length > 2 && hasMoreLessonsDetails
                                                        ? (
                                                            <div className="__booking-more-lessons">
                                                                <Button buttonClasses="__booking-more-lessons-button" onClick={this.handleClickMore}>
                                                                    {intl.formatMessage({ id: 'booking.moreLessons' })}
                                                                </Button>
                                                            </div>
                                                        )
                                                        : lessonsDetails.length > 0 && hasMoreLessonsDetails
                                                            ? <div />
                                                            : lessonsDetails.length > 0 && !hasMoreLessonsDetails
                                                                ? (
                                                                    <div className="__booking-left-no-lesson">
                                                                        <p>{intl.formatMessage({ id: 'booking.noMoreLesson' })}</p>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div className="__booking-left-no-lesson">
                                                                        <Title>{intl.formatMessage({ id: 'booking.noLesson' })}</Title>
                                                                    </div>
                                                                )
                                            }
                                        </DetailsBar>
                                        {
                                            participantsVisible
                                                ? (
                                                    <Expandable title={intl.formatMessage({ id: 'booking.Participants' })} open={participantsOpen} handleExpandable={() => this.toggleExpandable('participantsOpen')}>
                                                        <Participants
                                                            onChangeIfEmpty={() => {
                                                                this.setState({
                                                                    optionsOpen: false,
                                                                    optionsVisible: false,
                                                                    questionsOpen: false,
                                                                    questionsVisible: false,
                                                                    remarksOpen: false,
                                                                    remarksVisible: false,
                                                                    freeInstructorsVisible: false,
                                                                    freeInstructorsOpen: false,
                                                                });
                                                            }}
                                                            nbParticipants={nbParticipants}
                                                            handleExpandable={() => {
                                                                if (lesson?.lesson_type === LESSON_FILTERS.PRIVATE_LESSON && (freeInstructors.length > 0 || schoolInstructorsConfiguration?.mustSelectInstructor)) {
                                                                    this.toggleVisibility('freeInstructorsVisible');
                                                                    this.toggleExpandable('participantsOpen', 'freeInstructorsOpen');
                                                                } else if (options.length > 0) {
                                                                    this.toggleVisibility('optionsVisible');
                                                                    this.toggleExpandable('participantsOpen', 'optionsOpen');
                                                                } else if (questions.length > 0) {
                                                                    this.toggleVisibility('questionsVisible');
                                                                    this.toggleExpandable('participantsOpen', 'questionsOpen');
                                                                } else {
                                                                    this.toggleVisibility('remarksVisible');
                                                                    this.toggleExpandable('participantsOpen', 'remarksOpen');
                                                                }
                                                            }}
                                                        />
                                                    </Expandable>
                                                )
                                                : null
                                        }
                                        {
                                            freeInstructorsVisible
                                                ? (
                                                    <Expandable title={intl.formatMessage({ id: 'booking.freeInstructorsTitle' })} open={freeInstructorsOpen} handleExpandable={() => this.toggleExpandable('freeInstructorsOpen')}>
                                                        <FreeInstructors
                                                            handleExpandable={() => {
                                                                if (options.length > 0) {
                                                                    this.toggleVisibility('optionsVisible');
                                                                    this.toggleExpandable('freeInstructorsOpen', 'optionsOpen');
                                                                } else if (questions.length > 0) {
                                                                    this.toggleVisibility('questionsVisible');
                                                                    this.toggleExpandable('freeInstructorsOpen', 'questionsOpen');
                                                                } else {
                                                                    this.toggleVisibility('remarksVisible');
                                                                    this.toggleExpandable('freeInstructorsOpen', 'remarksOpen');
                                                                }
                                                            }}
                                                        />
                                                    </Expandable>
                                                )
                                                : null
                                        }
                                        {
                                            optionsVisible
                                                ? (
                                                    <Expandable title={intl.formatMessage({ id: 'booking.options' })} open={optionsOpen} handleExpandable={() => this.toggleExpandable('optionsOpen')}>
                                                        <Options
                                                            handleExpandable={() => {
                                                                if (questions.length > 0) {
                                                                    this.toggleVisibility('questionsVisible');
                                                                    this.toggleExpandable('optionsOpen', 'questionsOpen');
                                                                } else {
                                                                    this.toggleVisibility('remarksVisible');
                                                                    this.toggleExpandable('optionsOpen', 'remarksOpen');
                                                                }
                                                            }}
                                                        />
                                                    </Expandable>
                                                )
                                                : null
                                        }
                                        {
                                            questionsVisible
                                                ? (
                                                    <Expandable title={intl.formatMessage({ id: 'booking.questions' })} open={questionsOpen} handleExpandable={() => this.toggleExpandable('questionsOpen')}>
                                                        <Questions
                                                            handleExpandable={() => {
                                                                this.toggleVisibility('remarksVisible');
                                                                this.toggleExpandable('questionsOpen', 'remarksOpen');
                                                            }}
                                                        />
                                                    </Expandable>
                                                )
                                                : null
                                        }
                                        {
                                            remarksVisible
                                                ? (
                                                    <Expandable title={intl.formatMessage({ id: 'booking.remarks' })} open={remarksOpen} handleExpandable={() => this.toggleExpandable('remarksOpen')}>
                                                        <Remarks />
                                                    </Expandable>
                                                )
                                                : null
                                        }
                                    </div>
                                )
                        }
                    </div>
                    {
                        bigScreen
                            ? (
                                <div className={participantsVisible ? '__booking-right-small' : '__booking-right-big'}>
                                    {detailsEmpty ? null : <Selection />}
                                </div>
                            )
                            : null
                    }
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    currentSchool: store.navigation.currentSchool,
    lesson: store.booking.currentLesson,
    lessonsDetails: store.booking.lessonsDetails,
    hasMoreLessonsDetails: store.booking.hasMoreLessonsDetails,
    isLessonsDetailsLoading: store.booking.isLessonsDetailsLoading,
    options: store.booking.options,
    questions: store.booking.questions,
    details: store.booking.selectedLessonDetail,
    windowWidth: store.windowSize.width,
    selectedProductsDate: store.products.selectedProductsDate,
    language: store.translation.language,
    bcClient: store.setting.bcClient,
    freeInstructors: store.booking.freeInstructors,
    schoolsList: store.booking.schoolsList,
    schoolInstructorsConfiguration: store.booking.schoolInstructorsConfiguration,
    destinations: store.navigation.destinations,
    isSignUpVisible: store.user.isSignUpVisible,
});
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    runFetchLessonDetails: (body: BodyLessonDetails, params: any, language: string) => dispatch(fetchLessonDetails(body, params, language)),
    runSetSchoolInstructorsConfiguration: (data?: {
        mustSelectInstructor: boolean;
        canSelectInstructor: boolean;
    } | undefined) => dispatch(setSchoolInstructorsConfiguration(data)),
    runGetSchoolsList: () => dispatch(getSchoolsList()),
    runGetBCClient: (language: string) => dispatch(getBCClient(language)),
    runInitBooking: () => dispatch(initBooking()),
    runFetchLesson: (externalId: string, activityId: string, language: string) => dispatch(fetchLesson(externalId, activityId, language)),
    runSetLesson: (currentLesson: Lesson) => dispatch(setLesson(currentLesson)),
    runFetchSchoolInstructorsConfiguration: (externalId: string, department_id: string) => dispatch(fetchSchoolInstructorsConfiguration(externalId, department_id)),
    runOpenSignUpForm: () => dispatch(openSignUpForm()),
    runSetParticipantsInfos: (participantsInfos: any) => dispatch(setParticipantsInfos(participantsInfos)),
    runSetRemarks: (remarks: string | any[]) => dispatch(setRemarks(remarks)),
    runSetSelectedOptions: (options: any) => dispatch(setSelectedOptions(options)),
    runSetSelectedLessonDetail: (electedLessonDetail: any) => dispatch(setSelectedLessonDetail(electedLessonDetail)),
    runFetchInputParticipant: (body: BodyParticipant, language: string) => dispatch(fetchInputParticipant(body, language)),
    runFetchOptions: (body: BodyOptions, language: string) => dispatch(fetchOptions(body, language)),
    runFetchQuestions: (body: BodyQuestions, language: string) => dispatch(fetchQuestions(body, language)),
    runFetchFreeInstructors: (body: BodyFreeInstructors, language: string) => dispatch(fetchFreeInstructors(body, language)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(connector(Booking));
