/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import * as Sentry from '@sentry/browser';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React, { CSSProperties } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import {
    LESSON_FILTERS,
    SMARTPHONE_WIDTH,
    URL_ACTIVITY,
    URL_AGE,
    URL_DATE,
    URL_LESSON,
    URL_RESORT,
    URL_SCHOOL
} from '../../resources/constants';
import '../../resources/css/products.css';
import {
    cransParamsToBody, getSchoolById, getSchoolsIds, getSubDomain
} from '../../resources/utils';
import {
    fetchAllProducts,
    fetchCransMontanaProducts,
    fetchMoreCransMontanaProducts,
    fetchMoreProducts,
    initProducts,
    resetHasNoMoreProducts,
    setSelectedProductsDate
} from '../../store/actions/products';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import { Param } from '../../types/products';
import Container from '../common/container';
import LoadingCircle from '../common/loadingCircle';
import Title from '../common/title';
import Card from './card';
import ProductsFilters from './productsFilters';
import ProductsFilterSmartphone from './productsFilterSmartphone';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    scrollElement: any;
    location: any[];
}

class Products extends React.Component<Props, State> {
    params: Param;

    minDate: Moment;

    loadingMore: boolean;

    constructor(props: Props) {
        super(props);

        const {
            location, dispatch, match, intl,
        } = this.props;

        this.minDate = moment();
        this.loadingMore = false;
        this.params = {};

        this.queryToParams = this.queryToParams.bind(this);
        this.updatePath = this.updatePath.bind(this);
        this.handleScroll = this.handleScroll.bind(this);

        // if there is a query, transform it into params object
        let query;
        if (location.search || getSubDomain()) {
            query = qs.parse(location.search, {
                ignoreQueryPrefix: true,
                comma: true
            });
            this.queryToParams(query);
        }

        this.state = {
            scrollElement: undefined,
            location: this.params.resort_in ? this.params.resort_in : [],
        };

        const date = this.params[URL_DATE.FILTER] ? this.params[URL_DATE.FILTER] : (moment().diff(this.minDate, 'days') >= 0 ? moment().format('YYYY-MM-DDT00:00:00') : this.minDate.format('YYYY-MM-DDT00:00:00'));
        dispatch(initProducts());
        dispatch(setSelectedProductsDate(date));
        if (Number(this.params.school_id) === 3) dispatch(fetchCransMontanaProducts(cransParamsToBody(this.params, intl.formatMessage), match.params.lang));
        else dispatch(fetchAllProducts(this.params, match.params.lang));
    }

    componentDidMount() {
        document.getElementById('top')?.scrollIntoView();
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps: Props) {
        const {
            intl, dispatch, match, currentSchool, history, isProductsLoading
        } = this.props;
        // Correction of bug crans with filter on query for other languages than fr
        if (prevProps.intl.formatMessage !== intl.formatMessage && Number(this.params.school_id) === 3) {
            dispatch(fetchCransMontanaProducts(cransParamsToBody(this.params, intl.formatMessage), match.params.lang));
        }

        // if the current school as not online purchase redirect to landing
        if (currentSchool && !currentSchool.online_purchase) {
            history.replace(`/${match.params.lang}/`);
        }

        if (this.loadingMore === true) this.loadingMore = isProductsLoading;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
* Update the current path (URL) according to the filters
*/

    handleScroll() {
        const {
            hasNoMoreProducts, dispatch, intl, products, match,
        } = this.props;
        const { scrollElement } = this.state;
        if (!scrollElement) this.setState({ scrollElement: document.getElementById('scroll_reload') });

        if (!hasNoMoreProducts && !this.loadingMore && scrollElement && scrollElement.getBoundingClientRect().bottom <= window.innerHeight) {
            this.loadingMore = true;

            if (Number(this.params.school_id) === 3) dispatch(fetchMoreCransMontanaProducts(cransParamsToBody(this.params, intl.formatMessage), products.length, match.params.lang));
            else dispatch(fetchMoreProducts(this.params, products.length, match.params.lang));
        }
    }

    handleChangeLocation = (checkbox: any) => {
        let { location } = this.state;
        if (checkbox !== undefined) {
            if (checkbox.checked) {
                if (!location.includes(checkbox.name)) {
                    location.push(checkbox.name);
                }
            } else {
                location = location.filter((l) => l !== checkbox.name);
            }
        } else {
            location = [];
        }
        this.setState({ location });
    };

    queryToParams(query: {
        school?: string;
        activity?: string | string[];
        lesson?: string | string[];
        age?: string | string[];
        resort?: string | string[];
        date?: Moment;
    }) {
        const { history, match } = this.props;

        // school id
        const subDomain = getSubDomain();
        if (subDomain || query.school) {
            const name = subDomain || query.school;
            // const school = Object.values(get_schools_ids()).find((v) => v[0] === name);
            const school = Object.values(getSchoolsIds()).find((v) => v[0] === name);
            // const { , schoolId, departmentId } = school;
            if (school) {
                this.params[URL_SCHOOL.FILTER] = school[1] as never as number;
                this.params.department_id = school[2] as never as string | undefined;
            } else {
                Sentry.captureMessage(`Erreur, should never come here ${name}`);
            }

            // const [, id, departmentId] = school;
            // this.params.school_id = id as never as number;
            // this.params.department_id = departmentId as never as string | undefined;

            // if there is a subdomain and a school in the search delete it
            if (subDomain && query.school) {
                const stringQuery = qs.stringify({ ...query, school: undefined }, {
                    addQueryPrefix: true, delimiter: '&', encodeValuesOnly: true, indices: false, arrayFormat: 'comma', encode: false, skipNulls: true
                });
                history.replace(`/${match.params.lang}/products${stringQuery}`);
            }
        }

        // activity
        if (query.activity) {
            let activity;
            this.params[URL_ACTIVITY.FILTER] = [];
            const params = Array.isArray(query.activity) ? query.activity : [query.activity];
            for (const param of params) {
                switch (param) {
                    case URL_ACTIVITY.SKI:
                        activity = LESSON_FILTERS.SKIING;
                        break;
                    case URL_ACTIVITY.SNOWBOARD:
                        activity = LESSON_FILTERS.SNOWBOARD;
                        break;
                    case URL_ACTIVITY.TELEMARK:
                        activity = LESSON_FILTERS.TELEMARK;
                        break;
                    case URL_ACTIVITY.OTHER:
                        activity = LESSON_FILTERS.OTHER;
                        break;
                    case URL_ACTIVITY.SUMMER:
                        activity = LESSON_FILTERS.SUMMER;
                        break;
                    case URL_ACTIVITY.ROCKER:
                        activity = LESSON_FILTERS.ROCKER;
                        break;
                    case URL_ACTIVITY.MOUNTAINEERING:
                        activity = LESSON_FILTERS.MOUNTAINEERING;
                        break;
                    case URL_ACTIVITY.ICE:
                        activity = LESSON_FILTERS.ICE;
                        break;
                    case URL_ACTIVITY.TRAIL:
                        activity = LESSON_FILTERS.TRAIL;
                        break;
                    case URL_ACTIVITY.FORMATION:
                        activity = LESSON_FILTERS.FORMATION;
                        break;
                }
                this.params[URL_ACTIVITY.FILTER].push(activity);
            }
        }

        // lesson type
        if (query.lesson) {
            let lesson;
            this.params[URL_LESSON.FILTER] = [];
            const params = Array.isArray(query.lesson) ? query.lesson : [query.lesson];

            for (const param of params) {
                switch (param) {
                    case URL_LESSON.GROUP:
                        lesson = LESSON_FILTERS.GROUP_LESSON;
                        break;
                    case URL_LESSON.PRIVATE:
                        lesson = LESSON_FILTERS.PRIVATE_LESSON;
                        break;

                        // For Crans Montana
                    case LESSON_FILTERS.CRANS_KIDS_CRANS:
                        lesson = LESSON_FILTERS.CRANS_KIDS_CRANS;
                        break;
                    case LESSON_FILTERS.CRANS_KIDS_GOLF:
                        lesson = LESSON_FILTERS.CRANS_KIDS_GOLF;
                        break;
                    case LESSON_FILTERS.CRANS_KIDS_MONTANA:
                        lesson = LESSON_FILTERS.CRANS_KIDS_MONTANA;
                        break;
                    case LESSON_FILTERS.CRANS_SPECIAL:
                        lesson = LESSON_FILTERS.CRANS_SPECIAL;
                        break;
                    case LESSON_FILTERS.CRANS_SNOWBOARD_G:
                        lesson = LESSON_FILTERS.CRANS_SNOWBOARD_G;
                        break;
                    case LESSON_FILTERS.CRANS_SNOWBOARD:
                        lesson = LESSON_FILTERS.CRANS_SNOWBOARD;
                        break;
                    case LESSON_FILTERS.CRANS_PRIVATE:
                        lesson = LESSON_FILTERS.CRANS_PRIVATE;
                        break;
                    case LESSON_FILTERS.CRANS_GROUP:
                        lesson = LESSON_FILTERS.CRANS_GROUP;
                        break;
                    case LESSON_FILTERS.CRANS_OTHER:
                        lesson = LESSON_FILTERS.CRANS_OTHER;
                        break;
                    case LESSON_FILTERS.CRANS_KIDS_SUMMER:
                        lesson = LESSON_FILTERS.CRANS_KIDS_SUMMER;
                        break;
                    case LESSON_FILTERS.CRANS_BIKE:
                        lesson = LESSON_FILTERS.CRANS_BIKE;
                        break;
                }
                this.params[URL_LESSON.FILTER].push(lesson);
            }
        }

        // age
        if (query.age) {
            let age;
            this.params[URL_AGE.FILTER] = [];
            const params = Array.isArray(query.age) ? query.age : [query.age];
            for (const param of params) {
                switch (param) {
                    case URL_AGE.BABY:
                        age = LESSON_FILTERS.MINI_KID;
                        break;
                    case URL_AGE.KIDS:
                        age = LESSON_FILTERS.CHILD;
                        break;
                    case URL_AGE.TEENAGER:
                        age = LESSON_FILTERS.TEENAGER;
                        break;
                    case URL_AGE.ADULT:
                        age = LESSON_FILTERS.ADULT;
                        break;
                }
                this.params[URL_AGE.FILTER].push(age);
            }
        }

        if (query.resort) {
            let age;
            this.params[URL_RESORT.FILTER] = [];
            const params = Array.isArray(query.resort) ? query.resort : [query.resort];
            for (const param of params) {
                switch (param) {
                    case URL_RESORT.GRIMENTZ:
                        age = LESSON_FILTERS.GRIMENTZ;
                        break;
                    case URL_RESORT.ZINAL:
                        age = LESSON_FILTERS.ZINAL;
                        break;
                    case URL_RESORT.CORBATIERE:
                        age = LESSON_FILTERS.CORBATIERE;
                        break;
                    case URL_RESORT.BUGNENETS:
                        age = LESSON_FILTERS.BUGNENETS;
                        break;
                    case URL_RESORT.VUE_DES_ALPES:
                        age = LESSON_FILTERS.VUE_DES_ALPES;
                        break;
                    case URL_RESORT.CRET_DU_PUY:
                        age = LESSON_FILTERS.CRET_DU_PUY;
                        break;
                    case URL_RESORT.SOMMARTEL:
                        age = LESSON_FILTERS.SOMMARTEL;
                        break;
                    case URL_RESORT.LAUCHERALP:
                        age = LESSON_FILTERS.LAUCHERALP;
                        break;
                    case URL_RESORT.BELALP:
                        age = LESSON_FILTERS.BELALP;
                        break;
                    case URL_RESORT.GRACHEN:
                        age = LESSON_FILTERS.GRACHEN;
                        break;
                    case URL_RESORT.SAAS_FEE:
                        age = LESSON_FILTERS.SAAS_FEE;
                        break;
                    case URL_RESORT.ZERMATT:
                        age = LESSON_FILTERS.ZERMATT;
                        break;
                    case URL_RESORT.GRIMENTZ_BF:
                        age = LESSON_FILTERS.GRIMENTZ_BF;
                        break;
                    case URL_RESORT.LES_CROSETS:
                        age = LESSON_FILTERS.LES_CROSETS;
                        break;
                    case URL_RESORT.NENDAZ:
                        age = LESSON_FILTERS.NENDAZ;
                        break;
                    case URL_RESORT.VEYSONNAZ:
                        age = LESSON_FILTERS.VEYSONNAZ;
                        break;
                    case URL_RESORT.VERBIER:
                        age = LESSON_FILTERS.VERBIER;
                        break;
                }
                this.params[URL_RESORT.FILTER].push(age);
            }
        }

        if (query.date) {
            const date = moment(query.date);
            this.params[URL_DATE.FILTER] = this.minDate.diff(date, 'days') >= 0 ? this.minDate.format('YYYY-MM-DDT00:00:00') : date.format('YYYY-MM-DDT00:00:00');
        }
    }

    updatePath(params: Param) {
        const { history, match, dispatch } = this.props;
        this.params = params;

        const final: {
            lesson?: (string | undefined)[];
            activity?: (string | undefined)[];
            age?: (string | undefined)[];
            resort?: (string | undefined)[];
            school?: string;
            date?: string;
        } = {};

        if (params[URL_DATE.FILTER]) {
            if (moment().diff(params[URL_DATE.FILTER], 'days') >= 0) {
                final.date = undefined;
            } else {
                const date = moment(params[URL_DATE.FILTER]).format('YYYY-MM-DDT00:00:00');
                final.date = date;
                this.params[URL_DATE.FILTER] = date;
            }
        }

        // school
        if (!getSubDomain() && params[URL_SCHOOL.FILTER]) {
            if (params[URL_SCHOOL.FILTER] === '') {
                final.school = undefined;
            } else {
                let param = params[URL_SCHOOL.FILTER];
                if (typeof param === 'string') param = parseInt(param, 10);
                final.school = getSchoolById(param);
            }
        }

        // activity
        if (params[URL_ACTIVITY.FILTER]) {
            if (params[URL_ACTIVITY.FILTER].length === 0) {
                final.activity = undefined;
            } else {
                final.activity = [];
                let activity;
                for (const param of params[URL_ACTIVITY.FILTER]) {
                    switch (param) {
                        case LESSON_FILTERS.SKIING:
                            activity = URL_ACTIVITY.SKI;
                            break;
                        case LESSON_FILTERS.SNOWBOARD:
                            activity = URL_ACTIVITY.SNOWBOARD;
                            break;
                        case LESSON_FILTERS.TELEMARK:
                            activity = URL_ACTIVITY.TELEMARK;
                            break;
                        case LESSON_FILTERS.OTHER:
                            activity = URL_ACTIVITY.OTHER;
                            break;
                        case LESSON_FILTERS.SUMMER:
                            activity = URL_ACTIVITY.SUMMER;
                            break;
                        case LESSON_FILTERS.ROCKER:
                            activity = URL_ACTIVITY.ROCKER;
                            break;
                        case LESSON_FILTERS.MOUNTAINEERING:
                            activity = URL_ACTIVITY.MOUNTAINEERING;
                            break;
                        case LESSON_FILTERS.ICE:
                            activity = URL_ACTIVITY.ICE;
                            break;
                        case LESSON_FILTERS.TRAIL:
                            activity = URL_ACTIVITY.TRAIL;
                            break;
                        case LESSON_FILTERS.FORMATION:
                            activity = URL_ACTIVITY.FORMATION;
                            break;
                        default:
                            break;
                    }
                    if (!final.activity.includes(activity)) {
                        final.activity.push(activity);
                    }
                }
            }
        }

        // lesson type
        if (params[URL_LESSON.FILTER]) {
            if (params[URL_LESSON.FILTER].length === 0) {
                final.lesson = undefined;
            } else {
                final.lesson = [];
                let lesson;
                for (const param of params[URL_LESSON.FILTER]) {
                    switch (param) {
                        case LESSON_FILTERS.GROUP_LESSON:
                            lesson = URL_LESSON.GROUP;
                            break;
                        case LESSON_FILTERS.PRIVATE_LESSON:
                            lesson = URL_LESSON.PRIVATE;
                            break;

                            // For Crans Montana
                        case LESSON_FILTERS.CRANS_KIDS_CRANS:
                            lesson = LESSON_FILTERS.CRANS_KIDS_CRANS;
                            break;
                        case LESSON_FILTERS.CRANS_KIDS_GOLF:
                            lesson = LESSON_FILTERS.CRANS_KIDS_GOLF;
                            break;
                        case LESSON_FILTERS.CRANS_KIDS_MONTANA:
                            lesson = LESSON_FILTERS.CRANS_KIDS_MONTANA;
                            break;
                        case LESSON_FILTERS.CRANS_SPECIAL:
                            lesson = LESSON_FILTERS.CRANS_SPECIAL;
                            break;
                        case LESSON_FILTERS.CRANS_SNOWBOARD:
                            lesson = LESSON_FILTERS.CRANS_SNOWBOARD;
                            break;
                        case LESSON_FILTERS.CRANS_SNOWBOARD_G:
                            lesson = LESSON_FILTERS.CRANS_SNOWBOARD_G;
                            break;
                        case LESSON_FILTERS.CRANS_PRIVATE:
                            lesson = LESSON_FILTERS.CRANS_PRIVATE;
                            break;
                        case LESSON_FILTERS.CRANS_GROUP:
                            lesson = LESSON_FILTERS.CRANS_GROUP;
                            break;
                        case LESSON_FILTERS.CRANS_OTHER:
                            lesson = LESSON_FILTERS.CRANS_OTHER;
                            break;
                        case LESSON_FILTERS.CRANS_KIDS_SUMMER:
                            lesson = LESSON_FILTERS.CRANS_KIDS_SUMMER;
                            break;
                        case LESSON_FILTERS.CRANS_BIKE:
                            lesson = LESSON_FILTERS.CRANS_BIKE;
                            break;
                    }
                    if (!final.lesson.includes(lesson)) {
                        final.lesson.push(lesson);
                    }
                }
            }
        }

        // age
        if (params[URL_AGE.FILTER]) {
            if (params[URL_AGE.FILTER].length === 0) {
                final.age = undefined;
            } else {
                final.age = [];
                let age;
                for (const param of params[URL_AGE.FILTER]) {
                    switch (param) {
                        case LESSON_FILTERS.MINI_KID:
                            age = URL_AGE.BABY;
                            break;
                        case LESSON_FILTERS.CHILD:
                            age = URL_AGE.KIDS;
                            break;
                        case LESSON_FILTERS.TEENAGER:
                            age = URL_AGE.TEENAGER;
                            break;
                        case LESSON_FILTERS.ADULT:
                            age = URL_AGE.ADULT;
                            break;
                    }
                    if (!final.age.includes(age)) {
                        final.age.push(age);
                    }
                }
            }
        }

        if (params[URL_RESORT.FILTER]) {
            if (params[URL_RESORT.FILTER].length === 0) {
                final.resort = undefined;
            } else {
                final.resort = [];
                let resort;
                for (const param of params[URL_RESORT.FILTER]) {
                    switch (param) {
                        case LESSON_FILTERS.GRIMENTZ:
                            resort = URL_RESORT.GRIMENTZ;
                            break;
                        case LESSON_FILTERS.ZINAL:
                            resort = URL_RESORT.ZINAL;
                            break;
                        case LESSON_FILTERS.CORBATIERE:
                            resort = URL_RESORT.CORBATIERE;
                            break;
                        case LESSON_FILTERS.BUGNENETS:
                            resort = URL_RESORT.BUGNENETS;
                            break;
                        case LESSON_FILTERS.VUE_DES_ALPES:
                            resort = URL_RESORT.VUE_DES_ALPES;
                            break;
                        case LESSON_FILTERS.CRET_DU_PUY:
                            resort = URL_RESORT.CRET_DU_PUY;
                            break;
                        case LESSON_FILTERS.SOMMARTEL:
                            resort = URL_RESORT.SOMMARTEL;
                            break;
                    }
                    if (final.resort.includes(resort)) {
                        final.resort.push(resort);
                    }
                }
            }
        }

        const query = qs.stringify(final, {
            addQueryPrefix: true, delimiter: '&', encodeValuesOnly: true, indices: false, arrayFormat: 'comma', encode: false
        });
        history.replace(`/${match.params.lang}/products${query}`);
        dispatch(resetHasNoMoreProducts());
    }

    render() {
        const {
            currentSchool, products, windowHeight, intl, windowWidth, isProductsLoading, match, dispatch
        } = this.props;
        const { location } = this.state;
        let cards = [];
        if (currentSchool?.int_id === '2' && location && location.length !== 0) {
            cards = products.filter((p) => location.some((t) => p.title.includes(t))).map((p, idx) => {
                const i = idx;
                return <Card key={`lesson_card_${p.id}_${i}`} lesson={p} />;
            });
        } else if (currentSchool?.int_id === '37' && location && location.length !== 0) {
            cards = products.filter((p) => location.some((t) => p.title.includes(t))).map((p, idx) => {
                const i = idx;
                return <Card key={`lesson_card_${p.id}_${i}`} lesson={p} />;
            });
        } else if (currentSchool?.int_id === '81' && location && location.length !== 0) {
            cards = products.filter((p) => location.some((t) => p.title.includes(t))).map((p, idx) => {
                const i = idx;
                return <Card key={`lesson_card_${p.id}_${i}`} lesson={p} />;
            });
            if (cards.length === 0) {
                dispatch(fetchMoreProducts(this.params, products.length, match.params.lang));
            }
            // Delete temporary duplicate (korben code) oui oui mathias c'est moche je sais
            cards = cards.filter((v, i, a) => a.findIndex((v2) => (v2.props.lesson.id === v.props.lesson.id)) === i);
        } else {
            cards = products.map((p, idx) => {
                const i = idx;
                return <Card key={`lesson_card_${p.id}_${i}`} lesson={p} />;
            });
        }
        if (cards.length > 0) cards.splice(cards.length - 2, 0, <span key="lesson_card_scroll_reload" id="scroll_reload" />);

        const style: CSSProperties | undefined = windowHeight > 680 ? { position: 'sticky', top: '95px' } : undefined;

        return (
            <Container
                headerBase={intl.formatMessage({ id: 'products.products' })}
            >
                <div className="__products-index-parent">
                    {
                        windowWidth < SMARTPHONE_WIDTH
                            ? (
                                <ProductsFilterSmartphone
                                    params={this.params}
                                    updatePath={this.updatePath}
                                    minDate={this.minDate}
                                    handleChangeLocation={this.handleChangeLocation}
                                    // handleChangeCransMontana={this.handleChangeCransMontana}
                                />
                            )
                            : (
                                <div className="__products-index-left" style={style}>
                                    <ProductsFilters
                                        params={this.params}
                                        updatePath={this.updatePath}
                                        minDate={this.minDate}
                                        handleChangeLocation={this.handleChangeLocation}
                                        // handleChangeCransMontana={this.handleChangeCransMontana}
                                    />
                                </div>
                            )
                    }
                    <div className="__products-index-right">
                        {
                            isProductsLoading
                                ? <LoadingCircle />
                                : cards.length === 0
                                    ? (
                                        <div className="__products-index-no-card-container">
                                            <Title>{intl.formatMessage({ id: 'products.noCards' })}</Title>
                                        </div>
                                    )
                                    : cards
                        }
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    products: store.products.products,
    windowWidth: store.windowSize.width,
    windowHeight: store.windowSize.height,
    schools: store.products.schools,
    isProductsLoading: store.products.isProductsLoading,
    selectedProductsDate: store.products.selectedProductsDate,
    hasNoMoreProducts: store.products.hasNoMoreProducts,
    currentSchool: store.navigation.currentSchool,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Products));
