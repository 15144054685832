import {
    FormControlLabel, MenuItem, Radio, RadioGroup
} from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { setSelectedInstructor } from '../../store/actions/booking';
import { ApplicationState, StoreDispatch } from '../../store/types';
import {
    Group,
    Instructor
} from '../../types/booking';
import Button from '../common/button';
import CheckBox from '../common/checkbox';
import SelectField from '../common/selectField';

const InstructorPriority = Object.freeze({ Absolutely: 3, Preferably: 1 });

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
    handleExpandable: () => void;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    language: string;
    instructor: string;
    error?: any;
    onlyPriority: any;
    instructorPriority: number;
}

class FreeInstructors extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { schoolsList, currentLesson } = this.props;

        this.state = {
            language: '',
            instructor: '-1',
            error: undefined,
            onlyPriority: schoolsList.filter(
                (s: any) => Number(s.Id) === Number(currentLesson?.school.external_id),
            )[0].OnlyPriorityAbsolutely,
            instructorPriority: InstructorPriority.Preferably,
        };
    }

    formatLanguages = () => {
        const { freeInstructors, intl } = this.props;
        const languages = freeInstructors.map((group) => <MenuItem key={`insctructors_languages_${group.language.Code}`} value={group.language.Id}>{group.language.Text}</MenuItem>);
        languages.unshift(<MenuItem key="insctructors_languages_disabled" value="-1" disabled>{intl.formatMessage({ id: 'language' })}</MenuItem>);
        return languages;
    };

    formatFreeInstructors = () => {
        let instructors = [];
        const { freeInstructors, intl } = this.props;
        const { language } = this.state;
        freeInstructors.forEach((group: Group) => {
            if (group.language.Id === language) {
                instructors = group.instructors.map((i: Instructor, index: number) => {
                    const idx = index;
                    return (<MenuItem key={`insctructors_instructor_${idx}`} value={i.Id}>{`${i.Name} ${i.Firstname}`}</MenuItem>);
                });
            }
        });
        instructors.unshift(<MenuItem key="insctructors_instructor_disabled" value="-1" disabled>{intl.formatMessage({ id: 'booking.freeInstructors' })}</MenuItem>);
        return instructors;
    };

    handleChangeLanguage = (event: any) => {
        this.setState({ language: event.target.value, instructor: '-1' });
    };

    handleChangeInstructor = (event: any) => {
        this.setState({ instructor: event.target.value });
    };

    handleClickNext = () => {
        const {
            freeInstructors, schoolInstructorsConfiguration, intl, dispatch, handleExpandable,
        } = this.props;
        const {
            instructor, language, onlyPriority, instructorPriority,
        } = this.state;
        let instructorTmp: Instructor = {};
        if (instructor !== '-1') {
            freeInstructors.forEach((group) => {
                if (group.language.Id === language) {
                    group.instructors.forEach((i) => {
                        if (i.Id === instructor) {
                            instructorTmp = i;
                        }
                    });
                }
            });
        }

        if (schoolInstructorsConfiguration
            && schoolInstructorsConfiguration.mustSelectInstructor
            && Object.keys(instructorTmp).length === 0) {
            this.setState({ error: intl.formatMessage({ id: 'booking.freeInstructorsMandatory' }) });
        } else {
            this.setState({ error: undefined });
            instructorTmp.priority = onlyPriority
                ? InstructorPriority.Absolutely : instructorPriority;
            dispatch(setSelectedInstructor(instructorTmp));
            handleExpandable();
        }
    };

    render() {
        const parsedFreeInstructors = this.formatFreeInstructors();
        const { freeInstructors, intl } = this.props;
        const {
            language, instructor, error, onlyPriority, instructorPriority,
        } = this.state;
        return (
            <div className="__booking-instructors-parent">
                <div style={{ width: 'calc( 100% - 140px)' }} className="__booking-instructors-left">
                    <div style={{ width: 240 }} className="__booking-instructors-selects">
                        {

                            freeInstructors.length > 0
                                ? (
                                    <>
                                        <SelectField
                                            border
                                            parentClasses="__booking-instructors-select-parent"
                                            value={language || -1}
                                            fontSize="16px"
                                            padding="0px 15px 0px 0px"
                                            // style={{ height: 42 }}
                                            height={42}
                                            onChange={this.handleChangeLanguage}
                                        >
                                            {this.formatLanguages()}
                                        </SelectField>
                                        {
                                            parsedFreeInstructors.length > 0
                                                && parsedFreeInstructors.length > 0
                                                ? (
                                                    <SelectField
                                                        border
                                                        disabled={
                                                            language === undefined
                                                            || language.length === 0
                                                        }
                                                        parentClasses="__booking-instructors-select-parent"
                                                        value={instructor || -1}
                                                        fontSize="16px"
                                                        height={42}
                                                        padding="0px 15px 0px 0px"
                                                        // style={{ height: 42 }}
                                                        onChange={this.handleChangeInstructor}
                                                    >
                                                        {parsedFreeInstructors}
                                                    </SelectField>
                                                )
                                                : <p className="__booking-instructors-no-instructor">{intl.formatMessage({ id: 'booking.freeInstructorsNone' })}</p>
                                        }
                                    </>
                                )
                                : <p className="__booking-instructors-no-instructor">{intl.formatMessage({ id: 'booking.freeInstructorsEmpty' })}</p>
                        }

                        {
                            error
                                ? <p className="__booking-instructor-error">{error}</p>
                                : null
                        }
                    </div>
                    {
                        onlyPriority
                            ? (
                                <div className="__booking-instructors-checkboxes">
                                    <CheckBox
                                        inputFirst
                                        disabled
                                        checked
                                        label={intl.formatMessage({ id: 'booking.freeInstructorsAbsolutely' })}
                                    />
                                </div>
                            )
                            : (
                                <div style={{ marginLeft: 40, paddingTop: 10 }} className="__booking-instructors-checkboxes">
                                    {/* <CheckBox
                                        inputFirst
                                        onChange={
                                            () => this.setState({ instructorPriority: InstructorPriority.Preferably })
                                        }
                                        checked={
                                            instructorPriority === InstructorPriority.Preferably
                                        }
                                        label={intl.formatMessage({ id: 'booking.freeInstructorsPreferably' })}
                                    />
                                    <CheckBox
                                        inputFirst
                                        onChange={
                                            () => this.setState({ instructorPriority: InstructorPriority.Absolutely })
                                        }
                                        checked={
                                            instructorPriority === InstructorPriority.Absolutely
                                        }
                                        label={intl.formatMessage({ id: 'booking.freeInstructorsAbsolutely' })}
                                    /> */}
                                    <RadioGroup
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '14px',
                                                fontFamily: 'Montserrat',
                                                width: '100%',
                                                color: 'var(--font-input-color)'
                                            },
                                            gap: '4px'
                                        }}
                                        // style={{
                                        //     fontSize: '14px',
                                        //     fontFamily: 'Montserrat',
                                        //     width: '100%',
                                        // }}
                                        value={instructorPriority}
                                        onChange={(event, value) => this.setState({ instructorPriority: Number(value) })}
                                    >
                                        <FormControlLabel
                                            value={String(InstructorPriority.Preferably)}
                                            control={(
                                                <Radio sx={{
                                                    color: 'var(--font-input-color)',
                                                    '&.Mui-checked': {
                                                        color: 'var(--font-input-color)',
                                                    },
                                                }}
                                                />
                                            )}
                                            label={intl.formatMessage({ id: 'booking.freeInstructorsPreferably' })}
                                        />
                                        <FormControlLabel
                                            value={String(InstructorPriority.Absolutely)}
                                            control={(
                                                <Radio sx={{
                                                    color: 'var(--font-input-color)',
                                                    '&.Mui-checked': {
                                                        color: 'var(--font-input-color)',
                                                    },
                                                }}
                                                />
                                            )}
                                            label={intl.formatMessage({ id: 'booking.freeInstructorsAbsolutely' })}
                                        />
                                    </RadioGroup>
                                </div>
                            )
                    }
                </div>
                <div className="__booking-instructors-button">
                    <Button onClick={this.handleClickNext}>
                        {intl.formatMessage({ id: 'booking.next' })}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    freeInstructors: store.booking.freeInstructors,
    currentLesson: store.booking.currentLesson,
    schoolsList: store.booking.schoolsList,
    schoolInstructorsConfiguration: store.booking.schoolInstructorsConfiguration,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(FreeInstructors));
