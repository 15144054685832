import moment from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { VERSIONS } from '../../resources/constants';
import {
    getCurrentVersion, getDomain, getSubDomain, getTranslation, getVersionName
} from '../../resources/utils';
import { Language } from '../../store/reducers/translation';
import { ApplicationState } from '../../store/types';
import { ShopContent, ShopSponsor } from '../../types/settings';
import Sitemap from './sitemap';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps { }

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    isLoggedIn?: string;
    lessonMaxLength: number;
    isNewsFlash: boolean;
    grimentzZinalModal: boolean;
    neuchatelRegionModal: boolean;
    path?: string;
}

class Footer extends React.Component<Props, State> {
    componentDidMount() {

    }

    render() {
        const {
            intl, currentSchool, schoolSiteContent, sponsors, language
        } = this.props;
        let site: string = '';

        if (currentSchool && (getCurrentVersion() === VERSIONS.BookingSport)) {
            site += `${getSubDomain()}.`;
        }
        site += getDomain();
        const schoolSiteContentSitemap:ShopContent[] = [];
        if (schoolSiteContent && schoolSiteContent.length > 0) {
            schoolSiteContent.filter((ssc) => ssc.content_type === 'sitemap').forEach((ssc) => {
                schoolSiteContentSitemap.push(language === Language.en ? ssc : getTranslation(ssc.translations, language));
            });
        }
        return (
            <div className="__footer-parent">
                {
                    schoolSiteContentSitemap && schoolSiteContentSitemap.length > 0
                    && schoolSiteContentSitemap.map((ssc: any, i: number) => {
                        const idx = i;
                        return (
                            <div
                                className="__sitemap-parent-content-sitemap-footer"
                                key={`school-site-content-sitemap-${idx}`}
                            // title={ssc.title}
                            // viewAll={false}
                            // notSmartphone && products
                            // ? products.length > this.state.lessonMaxLength : false}
                            // viewAllAction={() => this.setState({
                            // lessonMaxLength: products.length })}
                            // viewLessAction={notSmartphone && products
                            // && products.length > 4
                            // ? () => this.setState({lessonMaxLength: 4}) : null}
                            >
                                {/* eslint-disable-next-line react/no-danger */}
                                <div className="__school-site-content-sitemap-footer" dangerouslySetInnerHTML={{ __html: ssc.html }} />
                            </div>
                        );
                    })
                }
                <Sitemap />
                <div className="__footer-sponsor-parent">
                    {
                        sponsors && sponsors.map((sponsor: ShopSponsor) => (
                            <a
                                rel="noopener noreferrer"
                                className="__footer-sponsor-item"
                                target="_blank"
                                href={sponsor.link}
                                key={`footer-sponsor-${sponsor.id}`}
                            >
                                <img className="__footer-sponsor-image" src={sponsor.image} alt={`${sponsor.title} - ${intl.formatMessage({ id: 'img.alt.logo' })} ${getVersionName()}`} title={sponsor.title} />
                            </a>
                        ))
                    }
                </div>
                <div className="__footer-copyright-parent">
                    <p className="__footer-copyright-label">
                        {`${site} ${moment().format('YYYY')} © Powered by `}
                        <a rel="noopener noreferrer" className="__footer-copyright-link" href="https://www.webevolutions.ch" target="_blank">
                            WebEvolutions
                        </a>
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    sponsors: store.navigation.sponsors,
    schoolSiteContent: store.landing.schoolSiteContent,
    language: store.translation.language,
    currentSchool: store.navigation.currentSchool,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(Footer));
