/* eslint-disable no-nested-ternary */
import {
    Grid
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { SMARTPHONE_WIDTH, VERSIONS } from '../../resources/constants';
import '../../resources/css/settings.css';
import { getCurrentVersion } from '../../resources/utils';
import {
    changeClientPassword,
    editNotificationSettings,
    editProfile,
    getCountries,
    getLanguages,
    saveClientSchool
} from '../../store/actions/settings';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { SettingsItem, SettingsItemsList } from '../../types/account';
import {
    BCCountry, BCLanguage,
    School
} from '../../types/settings';
import Expandable from '../common/expandable';
import LoadingCircle from '../common/loadingCircle';
import { SettingsType } from './settingsCard';
import SettingsSection from './settingsSection';
// enumeration for the different types

/* Methods also available for SettingsModal */
interface IProps {
    userData: any;
    schools: School[];
    intl: any;
    languages: BCLanguage[];
    countries: BCCountry[];

}

export const createSettingsState = (props: IProps) => {
    const {
        userData, schools, intl, languages, countries,
    } = props;
    if (!userData || !userData.user) return undefined;
    const state: SettingsItemsList = {
        settings: {
            details: [
                {
                    title: intl.formatMessage({ id: 'settings.name' }), datas: [userData.user.first_name, userData.user.last_name], type: SettingsType.text, category: 'name', desc: ['name', 'firstname'],
                },
                {
                    title: intl.formatMessage({ id: 'email' }), datas: [userData.user.username], type: SettingsType.text, category: 'email'
                },
                {
                    title: intl.formatMessage({ id: 'phone' }), datas: [userData.phone], type: SettingsType.text, category: 'phone', desc: ['phone', 'mobile'],
                },
                {
                    title: intl.formatMessage({ id: 'birthdate' }), datas: [moment(userData.user.doj)], type: SettingsType.date, category: 'birthdate',
                },
                {
                    title: intl.formatMessage({ id: 'language' }), datas: [userData.language ? languages.filter((l) => l.Id === userData.language).map((el) => ({ name: el.Text, id: el.Id }))[0] : null], type: SettingsType.select, choices: languages.map((el) => ({ int_id: el.Id, id: el.Id, name: el.Text })), category: 'bc-l',
                },
                {
                    title: intl.formatMessage({ id: 'settings.country' }), datas: [userData.country ? countries.filter((c) => c.Id === userData.country).map((el) => ({ name: el.Text, id: el.Id }))[0] : null], type: SettingsType.select, choices: countries.map((el: BCCountry) => ({ int_id: el.Id, id: el.Id, name: el.Text })), category: 'bc-c',
                },
                {
                    title: intl.formatMessage({ id: 'address' }),
                    datas: [userData.town, userData.zip, userData.address],
                    type: SettingsType.text,
                    placeholders: [intl.formatMessage({ id: 'town' }), intl.formatMessage({ id: 'zip' }), intl.formatMessage({ id: 'address' })],
                    category: 'address',
                },
                {
                    title: intl.formatMessage({ id: 'addressLocal' }), datas: [userData.address_local], type: SettingsType.text, placeholders: [intl.formatMessage({ id: 'addressLocal' })], category: 'addressLocal', desc: ['localaddress'],
                },
            ],
            password: [
                {
                    title: intl.formatMessage({ id: 'password' }), datas: [''], type: SettingsType.password, category: 'password'
                },
            ],
            myskischool: schools && schools.length > 0 ? [
                {
                    title: intl.formatMessage(
                        {
                            id: 'settings.school',
                        },
                    ),
                    datas: [userData.company ? schools.filter(
                        (s) => s.id === userData.company.id,
                    )[0] : null],
                    type: SettingsType.select,
                    choices: schools,
                    category: 'myskischool'
                },
            ] : [],
            notifications: [{
                title: intl.formatMessage({ id: 'settings.notificationsLessons' }), datas: [userData.lesson_notif], type: SettingsType.switch, category: 'notifications'
            }],
            participants: null,
        },
        loaded: true,
    };

    return state;
};

export const updateSettingsDetails = (
    newValue: any,
    category: any,
    settings: any,
    dispatch: any,
) => {
    let value = Array.isArray(newValue) ? newValue : [newValue];

    if (value[0] === -1) return;

    if (value[0]._isAMomentObject) {
        value = [value[0].format('YYYY-MM-DD')];
    }

    const details = settings.map((el: { category: any; }) => {
        const final: { datas?: any } = {};
        if (el.category === category) {
            final.datas = value;
        } else {
            final.datas = [undefined];
        }
        return final;
    });

    dispatch(editProfile(
        details[0].datas[0], // firstName
        details[0].datas[1], // lastName
        details[2].datas[0], // phone
        details[3].datas[0], // birthdate
        details[6].datas[2], // address
        details[6].datas[0], // town
        details[6].datas[1], // zip
        details[5].datas[0], // country
        details[4].datas[0], // language
        details[7].datas[0], // addressLocal
    ));
};

type ReduxProps = ConnectedProps<typeof settingsConnector>;

interface SettingsIProps {
    dispatch: StoreDispatch
}

interface SettingsState {
    settings: {
        details: SettingsItem[] | null,
        password: SettingsItem[] | null,
        myskischool: SettingsItem[] | null,
        notifications: SettingsItem[] | null,
        participants: SettingsItem[] | null,
    },
    loaded: boolean
}
type SettingsProps = SettingsIProps & ReduxProps & WrappedComponentProps;

class Settings extends React.Component<SettingsProps, SettingsState> {
    constructor(props: SettingsProps) {
        super(props);

        this.state = {
            settings: {
                details: null,
                password: null,
                myskischool: null,
                notifications: null,
                participants: null,
            },
            loaded: false,
        };

        this.updateDetails = this.updateDetails.bind(this);
        this.updateSchool = this.updateSchool.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.updateNotifications = this.updateNotifications.bind(this);
    }

    componentDidMount() {
        const {
            languages, translationLanguage, dispatch, countries,
        } = this.props;
        let loaded = true;

        if (Object.keys(languages).length === 0) {
            dispatch(getLanguages(translationLanguage));
            loaded = false;
        }

        if (Object.keys(countries).length === 0) {
            dispatch(getCountries(translationLanguage));
            loaded = false;
        }

        // Correction to sentry (reading 'first_name')
        if (loaded) {
            const state = createSettingsState(this.props);
            if (state !== null) {
                this.setState({
                    settings: state ? state.settings : {
                        details: null, myskischool: null, password: null, notifications: null, participants: null
                    },
                    loaded: state ? state.loaded : false
                });
            }
        }
    }

    componentDidUpdate(prevProps: SettingsProps) {
        const {
            schools, userData, languages, countries,
        } = this.props;
        if (prevProps !== this.props && schools.length > 0 && userData
            && userData.user && languages.length > 0 && countries.length > 0) {
            const state = createSettingsState(this.props);
            // eslint-disable-next-line react/no-did-update-set-state
            if (state !== null) {
                this.setState({
                    settings: state ? state.settings : {
                        details: null, myskischool: null, password: null, notifications: null, participants: null
                    },
                    loaded: state ? state.loaded : false
                });
            }
        }
    }

    updateDetails(newValue: any, category: any) {
        const { dispatch } = this.props;
        const { settings } = this.state;
        updateSettingsDetails(newValue, category, settings.details, dispatch);
    }

    updateSchool(newValue: any) {
        const { dispatch } = this.props;
        dispatch(saveClientSchool(newValue));
    }

    updatePassword(values: any) {
        const { dispatch } = this.props;
        dispatch(changeClientPassword(values[0], values[1]));
    }

    updateNotifications(value: any) {
        const { dispatch } = this.props;
        dispatch(editNotificationSettings(value));
    }

    render() {
        const {
            isGetSchoolsLoading,
            isUserDataLoading,
            isCountriesLoading,
            isLanguagesLoading,
            intl,
            windowWidth,
        } = this.props;
        const { loaded, settings } = this.state;

        return (
            isGetSchoolsLoading || isUserDataLoading || isCountriesLoading || isLanguagesLoading || loaded !== true
                ? <LoadingCircle />
                : windowWidth > SMARTPHONE_WIDTH
                    ? (
                        <Grid alignContent="center" container spacing={2}>
                            <Grid item xs>
                                <Expandable title={intl.formatMessage({ id: 'settings.details' })} defaultOpened unmountOnExit>
                                    <SettingsSection items={settings.details} function={this.updateDetails} />
                                </Expandable>
                                <Expandable title={intl.formatMessage({ id: 'password' })} defaultOpened unmountOnExit>
                                    <SettingsSection items={settings.password} function={this.updatePassword} />
                                </Expandable>
                            </Grid>
                            <Grid item xs>
                                {/* Participant are not available for now !
                            <Expandable title={intl.formatMessage({id: 'settings.participants'})} defaultOpened={true} unmountOnExit>
                            {
                                settings.participants.map((participant) => { let title = participant[0].datas.reduce((a, b) => a.concat(' ', b));
                                return (
                                    <Expandable className="__settings-participants-expandable" plus title={title} key={title} defaultOpened={true} unmountOnExit>
                                        <SettingsSection items={participant} />
                                    </Expandable>
                                )})
                            }
                            </Expandable>
                        */}
                                <Expandable title={intl.formatMessage({ id: 'settings.mySwisskischool' })} defaultOpened unmountOnExit>
                                    <SettingsSection items={settings.myskischool} function={this.updateSchool} />
                                </Expandable>
                                <Expandable title={intl.formatMessage({ id: 'settings.notifications' })} defaultOpened unmountOnExit>
                                    <SettingsSection items={settings.notifications} function={this.updateNotifications} />
                                </Expandable>
                            </Grid>
                        </Grid>
                    )
                    : (
                        <Grid alignContent="center" container spacing={2}>
                            <Grid item xs>
                                <Expandable title={intl.formatMessage({ id: 'settings.details' })} defaultOpened unmountOnExit>
                                    <SettingsSection items={settings.details} function={this.updateDetails} />
                                </Expandable>
                                <Expandable title={intl.formatMessage({ id: 'password' })} defaultOpened unmountOnExit>
                                    <SettingsSection items={settings.password} function={this.updatePassword} />
                                </Expandable>
                                {/* Participant are not available for now !
                            <Expandable title={intl.formatMessage({id: 'settings.participants'})} defaultOpened={true} unmountOnExit>
                            {
                                <SettingsSection items={settings.participants} />
                            }
                            </Expandable>
                        */}
                                {
                                    getCurrentVersion() === VERSIONS.BookingFormation
                                        ? null
                                        : (
                                            <Expandable title={intl.formatMessage({ id: 'settings.mySwisskischool' })} defaultOpened unmountOnExit>
                                                <SettingsSection items={settings.myskischool} function={this.updateSchool} />
                                            </Expandable>
                                        )
                                }
                                <Expandable title={intl.formatMessage({ id: 'settings.notifications' })} defaultOpened unmountOnExit>
                                    <SettingsSection items={settings.notifications} function={this.updateNotifications} />
                                </Expandable>
                            </Grid>
                        </Grid>
                    )
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    windowWidth: store.windowSize.width,
    schools: store.setting.schools,
    userData: store.setting.userData,
    isGetSchoolsLoading: store.setting.isGetSchoolsLoading,
    isUserDataLoading: store.setting.isUserDataLoading,
    countries: store.setting.countries,
    languages: store.setting.languages,
    translationLanguage: store.translation.language,
    isCountriesLoading: store.setting.isCountriesLoading,
    isLanguagesLoading: store.setting.isLanguagesLoading,
});
const settingsConnector = connect(mapStateToProps);
export default settingsConnector((injectIntl(Settings)));
