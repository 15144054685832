import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Collapse } from '@mui/material';
import React from 'react';
import '../../resources/css/expandable.css';

/**
 * Component that represent an expandable menu
 */
interface IProps {
    defaultOpened?: boolean;
    handleExpandable?: () => void;
    containerClasses?: string;
    elements?: any;
    headerClasses?: string;
    collapseClasses?: string;
    title: string;
    open?: boolean;
    children?: any;
    plus?: any;
    unmountOnExit?: boolean;
}

type Props = IProps;

interface State {
    collapseIn: boolean;
    handler(): void;
}

export default class Expandable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // bind methods
        this.handleExpandable = this.handleExpandable.bind(this);

        const { defaultOpened, handleExpandable } = this.props;

        this.state = {
            collapseIn: defaultOpened ?? false,
            handler: handleExpandable || this.handleExpandable,
        };
    }

    handleExpandable() {
        this.setState((state) => ({ collapseIn: !state.collapseIn }));
    }

    render() {
        const {
            containerClasses,
            elements,
            headerClasses,
            collapseClasses,
            title,
            handleExpandable,
            open,
            plus,
            children,
            defaultOpened,
            ...props
        } = this.props;
        const { handler, collapseIn } = this.state;

        const iconClassName = `__expandable-icon ${elements ? '__expandable-icon-with-elements' : ''}`;

        let returnElement;

        if ((handleExpandable ? open : collapseIn)) {
            if (plus) {
                returnElement = (
                    <RemoveIcon fontSize="small" className={iconClassName} />
                );
            } else {
                returnElement = (
                    <ExpandLess className={iconClassName} />
                );
            }
        } else if (plus) {
            returnElement = (
                <AddIcon fontSize="small" className={iconClassName} />
            );
        } else {
            returnElement = (
                <ExpandMore className={iconClassName} />
            );
        }

        return (
            <div className={`__expandable-parent ${containerClasses || ''}`}>
                {/* eslint-disable-next-line
                jsx-a11y/no-static-element-interactions,
                jsx-a11y/click-events-have-key-events */}
                <div className={`__expandable-header ${elements ? '__expandable-header-with-elements' : ''} ${headerClasses || ''}`} onClick={handler}>
                    <p className={`__expandable-title ${elements ? '__expandable-title-with-elements' : ''}`}>{title}</p>
                    {
                        elements
                            ? (
                                <div className="__expandable-elements-container">
                                    {
                                        elements.map((el: any, i: number) => {
                                            const idx = i;
                                            return (
                                                /* eslint-disable-next-line
                                                    jsx-a11y/no-static-element-interactions,
                                                    jsx-a11y/click-events-have-key-events */
                                                <span key={`${el[1]}_${idx}`} className="__expandable-element" onClick={(e) => e.stopPropagation()}>
                                                    {el[0]}
                                                </span>
                                            );
                                        })
                                    }
                                </div>
                            )
                            : null
                    }
                    {
                        returnElement
                    }
                </div>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Collapse in={handleExpandable ? open : collapseIn} {...props}>
                    <div className={collapseClasses}>
                        {children}
                    </div>
                </Collapse>
            </div>
        );
    }
}
