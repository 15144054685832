import { MenuItem, Switch } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../store/types';
import { SettingsItem } from '../../types/account';
import Button from '../common/button';
import DatePickerField from '../common/datePickerField';
import SelectField from '../common/selectField';
import TextField from '../common/textfield';

export const SettingsType = Object.freeze({
    text: 0, password: 1, select: 2, date: 3, switch: 4,
});

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    item: SettingsItem;
    awesomeFunction(
        value: string[] | string | Moment | Moment[] | undefined,
        category: string
    ): void;
}
interface State {
    editing: boolean;
    value?: string[] | string | Moment | Moment[] ;
    error?: string;
    initial?: any
}
type Props = IProps & ReduxProps & WrappedComponentProps;

class SettingsCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editing: false,
            value: undefined,
            error: undefined,
        };

        this.toggleEditing = this.toggleEditing.bind(this);
        this.renderSettings = this.renderSettings.bind(this);
        this.renderNormal = this.renderNormal.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDatePickerError = this.handleDatePickerError.bind(this);
        this.handleSetEmptyError = this.handleSetEmptyError.bind(this);
        this.handleUnsetError = this.handleUnsetError.bind(this);
        this.handlePhoneError = this.handlePhoneError.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const { isSavingSetting, error } = this.props;
        // if update failed, do not stop editing
        if (prevProps.isSavingSetting === true && isSavingSetting === false && error === false) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState((state) => ({ editing: false, value: state.initial }));
        }
    }

    handleSave() {
        let { value } = this.state;
        const { item, awesomeFunction } = this.props;

        if (typeof value === 'string') value = String(value).trim();
        else if (Array.isArray(value)) value = value.map((v) => (typeof v === 'string' ? v.trim() : String(v)));

        if (item.category === 'birthdate' && this.handleDatePickerError()) {
            return;
        } if (item.category === 'phone' && this.handlePhoneError()) {
            return;
        } if ((typeof value === 'string' && (value === undefined || value === '')) || (Array.isArray(value) && value.length <= 0)) {
            this.handleSetEmptyError();
            return;
        }

        awesomeFunction(value, item.category);
    }

    handleChangeText(event: any, index: number = 0) {
        const { value } = this.state;
        if (value === undefined || !Array.isArray(value) || index >= value.length) {
            return;
        }

        const { item } = this.props;

        const newValue = cloneDeep(value);
        if (item.category === 'phone') {
            if (event.target.value.charAt(event.target.value.length - 1) !== ' ') newValue[index] = event.target.value;
        } else {
            newValue[index] = event.target.value;
        }
        this.setState({ value: newValue });
    }

    handleChangeDate(value: Moment[] | Moment) {
        this.setState({ value });
    }

    handleChangeSelect(event: any) {
        this.setState({ value: event.target.value });
        return true;
    }

    handleChangeSwitch(event: any) {
        this.setState({ value: event.target.checked });
    }

    handleDatePickerError() {
        const { intl } = this.props;
        const { value } = this.state;
        if (value && !Array.isArray(value) && value instanceof moment && typeof value !== 'string' && !value.isValid()) {
            this.setState({ error: intl.formatMessage({ id: 'settings.dateNotValid' }) });
            return true;
        }

        return false;
    }

    handlePhoneError() {
        const { value } = this.state;
        const { intl } = this.props;
        if (typeof value === 'string'
            && !/^[+]?[0-9]+$/.test(value)) {
            this.setState({ error: intl.formatMessage({ id: 'settings.phoneNotValid' }) });
            return true;
        } if (value !== undefined && Array.isArray(value) && typeof value[0] === 'string') {
            value[0] = value[0].split(/[^+0123456789]/).join('');
        }
        this.setState({ value });
        return false;
    }

    handleSetEmptyError() {
        const { intl } = this.props;
        this.setState({ error: intl.formatMessage({ id: 'booking.error' }) });
    }

    handleUnsetError() {
        this.setState({ error: undefined });
    }

    toggleEditing() {
        this.setState((state) => ({ editing: !state.editing }));
    }

    renderSettings(data: any, index: number) {
        const { item, intl } = this.props;
        const { value: valueState, error } = this.state;
        if (valueState === undefined) {
            // update the state
            let value;
            switch (item.type) {
                case SettingsType.date:
                    value = moment(data);
                    break;
                case SettingsType.select:
                    value = data ? data.id : -1;
                    break;
                case SettingsType.switch:
                    value = data || false;
                    break;
                default:
                    value = item.datas.slice(0);
                    value = value.map((v: string) => (!v ? '' : v));
                    break;
            }
            this.setState({ value });
        }

        const key = `settings_${item.title}-${index}`;
        let choices = null;
        switch (item.type) {
            case SettingsType.password:
                return (
                    <div key={key}>
                        <div className="__settings-card-textfield">
                            <TextField
                                onChange={this.handleChangeText}
                                type="password"
                                placeholder={intl.formatMessage({ id: 'settings.oldPassword' })}
                                error={error}
                                onClick={this.handleUnsetError}
                            />
                        </div>
                        <div className="__settings-card-textfield">
                            <TextField
                                onChange={(e: any) => this.handleChangeText(e, 1)}
                                type="password"
                                placeholder={intl.formatMessage({ id: 'settings.newPassword' })}
                                error={error}
                                onClick={this.handleUnsetError}
                            />
                        </div>
                    </div>
                );
            case SettingsType.select:
                if (item.choices !== undefined) {
                    choices = item.choices.map(
                        (choice) => (
                            <MenuItem
                                key={choice.int_id}
                                value={choice.id}
                            >
                                {choice.name}
                            </MenuItem>
                        ),
                    );
                    choices.unshift(<MenuItem key={`settings_select${item.title}`} value={-1} disabled>{item.title}</MenuItem>);
                }
                return (
                    <div style={{ height: 44, justifyContent: 'center' }} className="__settings-card-selectfield-container __textfield-content" key={key}>
                        <SelectField
                            value={valueState}
                            onChange={this.handleChangeSelect}
                        >
                            {choices}
                        </SelectField>
                    </div>
                );
            case SettingsType.date:
                return (
                    <div className="__settings-card-datepicker-container" key={key}>
                        <DatePickerField
                            // eslint-disable-next-line no-nested-ternary
                            value={typeof valueState === 'string' ? moment(valueState) : moment.isMoment(valueState) ? valueState : undefined}
                            onChange={(e: Moment | null) => {
                                if (e) {
                                    this.handleChangeDate(e);
                                }
                            }}
                            disableFuture
                            onFocus={this.handleUnsetError}
                            error={error}
                        />
                    </div>
                );
            case SettingsType.switch:
                return (
                    <div className="__settings-card-switch-container" key={key}>
                        <p className="__settings-card-switch-text">{intl.formatMessage({ id: valueState ? 'enabled' : 'disabled' })}</p>
                        <Switch
                            checked={Boolean(valueState)}
                            onChange={this.handleChangeSwitch}
                            color="primary"
                        />
                    </div>
                );
            default:
                return (
                    <div className="__settings-card-textfield" key={key}>
                        <TextField
                            title={item.placeholders ? item.placeholders[index] : item.title}
                            value={valueState && Array.isArray(valueState)
                                && valueState.length > index ? String(valueState[index]) : undefined}
                            onChange={(e) => this.handleChangeText(e, index)}
                            type="text"
                            placeholder={item.placeholders ? item.placeholders[index] : item.title}
                            error={error}
                            onClick={this.handleUnsetError}
                        />
                    </div>
                );
        }
    }

    renderEditing() {
        const { isSavingSetting, item } = this.props;
        return (
            <div>
                <p className="__settings-card-title">{item.title}</p>
                {
                    item.datas.map((data:any, index:number) => this.renderSettings(data, index))
                }
                <div>
                    <Button buttonClasses="__settings-card-button-input" onClick={this.handleSave} loading={isSavingSetting}>
                        <FormattedMessage id="save" />
                    </Button>
                </div>
            </div>
        );
    }

    renderNormal() {
        const { intl, item } = this.props;
        let showError = false;
        let displayText = '';

        switch (item.type) {
            case SettingsType.switch:
                displayText = intl.formatMessage({ id: item.datas[0] === true ? 'enabled' : 'disabled' });
                break;
            case SettingsType.date:
                if (moment(item.datas[0]).isValid()) {
                    displayText = intl.formatDate(item.datas[0], { year: 'numeric', month: 'long', day: '2-digit' });
                } else {
                    showError = true;
                    displayText = intl.formatMessage({ id: 'settings.noValue' });
                }
                break;
            case SettingsType.password:
                displayText = '******';
                break;
            case SettingsType.select:
                if (item.datas[0]) {
                    displayText = item.datas[0].name;
                } else {
                    showError = true;
                    displayText = intl.formatMessage({ id: 'settings.noValue' });
                }
                break;
            default:
                if (item.datas.filter((str: string) => str && str.length > 0).length > 0) {
                    displayText = item.datas.reduce((a: any, b:any) => {
                        let first = a;
                        let second = b;
                        if (!a) first = '';
                        if (!b) second = '';
                        return first.concat(', ', second);
                    });
                } else {
                    showError = true;
                    displayText = item.datas[0] ? item.datas[0].name : intl.formatMessage({ id: 'settings.noValue' });
                }
                break;
        }

        if (!['name', 'phone', 'birthdate', 'bc-l', 'bc-c', 'address'].includes(item.category)) {
            showError = false;
        }

        return (
            <div>
                <p className="__settings-card-title" style={showError ? { fontWeight: 'bold' } : {}}>{item.title}</p>
                <p className="__settings-card-text" style={showError ? { color: 'red', fontWeight: 'bold' } : {}}>{displayText}</p>
            </div>
        );
    }

    render() {
        const { item } = this.props;
        const { editing } = this.state;
        return (
            <div className="__settings-card-parent">
                <div className="__settings-card-content">
                    {
                        editing
                            ? this.renderEditing()
                            : this.renderNormal()
                    }
                </div>
                {
                    item.title.includes('mail') ? null
                        /* eslint-disable-next-line jsx-a11y/label-has-associated-control,
                        jsx-a11y/click-events-have-key-events,
                        jsx-a11y/no-noninteractive-element-interactions,
                        jsx-a11y/label-has-for */
                        : <label className="__settings-card-edit" onClick={this.toggleEditing}><FormattedMessage id={editing ? 'cancel' : 'edit'} /></label>
                }
            </div>
        );
    }
}

const settingsCardMapStateToProps = (store: ApplicationState) => ({
    isSavingSetting: store.setting.isSavingSetting,
    error: store.setting.error,
});
const connector = connect(settingsCardMapStateToProps);

export default injectIntl(connector(SettingsCard));
