import { FormControl, MenuItem } from '@mui/material';
// import { MobileDatePicker } from '@mui/x-date-pickers';
import { range } from 'lodash';
import moment, { Moment } from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Lesson, Level } from '../../types/booking';
import DatePickerField from '../common/datePickerField';
import Expandable from '../common/expandable';
import SelectField from '../common/selectField';

interface IProps {
    lesson: Lesson | null;
    minParticipants: number;
    maxParticipants: number;
    title: string;
    open: boolean;
    fromDate: string;
    disabled: boolean;
    selectedParticipant: any;
    selectedLevel: number;
    children: any;
    onChangeDateFrom(event: any): void;
    onChangeParticipant(participant: number): void;
    onChangeLevel(level: Level): void;
    handleExpandable(): void;
}

type Props = IProps & WrappedComponentProps;

interface State { }

function shouldDisableDate(day: Moment): boolean {
    return moment().startOf('day').diff(day, 'day') >= 1;
}

class DetailsBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.handleDateFrom = this.handleDateFrom.bind(this);
        this.handleParticipant = this.handleParticipant.bind(this);
        this.handleLevel = this.handleLevel.bind(this);
        this.formatLevelsOptions = this.formatLevelsOptions.bind(this);
    }

    handleDateFrom(event: any) {
        const { onChangeDateFrom } = this.props;
        onChangeDateFrom(event);
    }

    handleParticipant(value: any) {
        const { onChangeParticipant } = this.props;
        onChangeParticipant(value);
    }

    handleLevel(value: string) {
        const { lesson, onChangeLevel } = this.props;

        if (lesson) {
            const index = parseInt(value, 10);
            const level = lesson.levels.find((l) => l.id === index);
            if (level) {
                onChangeLevel(level);
            }
        }
    }

    formatLevelsOptions() {
        const { lesson } = this.props;
        if (!lesson) return [];
        if (lesson.levels[0].order_display !== null) {
            return lesson.levels.sort((a, b) => {
                const aOrderDisplay = a.order_display || 0;
                const bOrderDisplay = b.order_display || 0;
                return (aOrderDisplay < bOrderDisplay ? -1 : 1);
            }).map((level) => (<MenuItem key={`level_${level.id}`} value={level.id}>{level.name}</MenuItem>));
        }
        return lesson.levels.sort((a, b) => (a.id < b.id ? -1 : 1)).map((level) => (<MenuItem key={`level_${level.id}`} value={level.id}>{level.name}</MenuItem>));
    }

    render() {
        const {
            minParticipants,
            maxParticipants,
            title,
            open,
            handleExpandable,
            fromDate,
            disabled,
            intl,
            selectedParticipant,
            selectedLevel,
            lesson,
            children,
        } = this.props;
        const participantItems = range(minParticipants, maxParticipants + 1).map((nb) => <MenuItem key={`participant_${nb}`} value={nb}>{nb}</MenuItem>);
        const levelItems = this.formatLevelsOptions();

        let returnElement;
        if (lesson && lesson.levels.length > 1) {
            returnElement = (
                <FormControl style={{ height: '44px', width: '100%' }}>
                    <div className="__booking-detailsbar-select-parent">
                        {
                            lesson.id === 1567 || lesson.id === 1568
                                ? <p className="__booking-detailsbar-select-text">{`${intl.formatMessage({ id: 'booking.activity' })}:`}</p>
                                : <p className="__booking-detailsbar-select-text">{`${intl.formatMessage({ id: 'booking.level' })}:`}</p>
                        }
                        <SelectField
                            containerClasses="__booking-detailsbar-select-level"
                            selectClasses="__booking-detailsbar-select-input"
                            padding="0px 15px 0px 0px"
                            height={42}
                            value={selectedLevel === -1 && levelItems.length > 0 ? levelItems[0].props.value : selectedLevel}
                            onChange={(e: any) => this.handleLevel(e.target.value)}
                            disabled={disabled}
                        >
                            {levelItems}
                        </SelectField>
                    </div>
                </FormControl>
            );
        } else if (lesson) {
            returnElement = (
                <p className="__booking-detailsbar-level-text">{`${intl.formatMessage({ id: 'booking.level' })}: ${lesson.levels[0] && lesson.levels[0].name.length > 0 ? lesson.levels[0].name : intl.formatMessage({ id: 'booking.noLevel' })}`}</p>
            );
        } else {
            returnElement = null;
        }

        return (
            <Expandable
                title={title}
                open={open}
                handleExpandable={handleExpandable}
                containerClasses="__booking-detailsbar-expandable-container"
                elements={[
                    [<DatePickerField
                        key={`details-date-picker-${title}`}
                        fontSize="16px"
                        containerClasses="__booking-detailsbar-datepicker"
                        textAlign="center"
                        format="DD MMMM YYYY"
                        value={moment(fromDate)}
                        // noKeyboard ? ...{onAccept={this.handleDateFrom}} :...{onChange={this.handleDateFrom}}
                        animateYearScrolling
                        disablePast
                        noKeyboard
                        disabled={disabled}
                        onChange={this.handleDateFrom}
                        // ={disabled}
                        shouldDisableDate={shouldDisableDate}
                        readOnly={disabled}
                    />, 'detailsbar-datepicker1'],

                    [
                        // eslint-disable-next-line react/jsx-key
                        <FormControl style={{ height: '44px', width: '100%' }}>
                            <div className="__booking-detailsbar-select-parent">
                                <p className="__booking-detailsbar-select-text">{`${intl.formatMessage({ id: 'booking.Participants' })}:`}</p>
                                <SelectField
                                    containerClasses="__booking-detailsbar-select-participants"
                                    value={selectedParticipant}
                                    height={42}
                                    onChange={(e) => this.handleParticipant(e.target.value)}
                                    name="select_participant"
                                    selectClasses="__booking-detailsbar-select-input"
                                    padding="0px 15px 0px 0px"
                                    disabled={disabled}
                                >
                                    {participantItems}
                                </SelectField>
                            </div>
                        </FormControl>, 'detailsbar-select',
                    ],

                    [returnElement, 'detailsbar-select-level'],
                ]}
            >
                {children}
            </Expandable>
        );
    }
}
const connector = connect();

export default injectIntl(connector(DetailsBar));
