import { MessageState, StoreAction } from '../types';

export const MESSAGE_INIT_STATE: MessageState = {
    messages: [],
    page: 0,
    pageEnd: false,
    loading: false,
    msgLoadErr: '',
    message: {},
    msgSendErr: '',
    onEndReached: null,
    isNewsfeedLoading: false,
};

export const TYPE_MESSAGES_LIST_INIT = Symbol('msg_init');
export const TYPE_MESSAGES_LIST_SUCCESS = Symbol('msg_success');
export const TYPE_MESSAGES_LIST_FAILURE = Symbol('msg_fail');
export const TYPE_MESSAGE_SEND_INIT = Symbol('msg_sn_init');
export const TYPE_MESSAGE_SEND_SUCCESS = Symbol('msg_sn_success');
export const TYPE_MESSAGE_SEND_FAILURE = Symbol('msg_sn_fail');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const messages = (state: MessageState = MESSAGE_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    let oldMessages = null;
    let pageEnd = null;
    switch (action.type) {
        case TYPE_MESSAGES_LIST_INIT:
            return {
                ...state,
                msgLoadErr: '',
                messages: [],
                loading: true,
                pageEnd: false,
            };
        case TYPE_MESSAGES_LIST_SUCCESS:
            oldMessages = state.messages.slice();
            oldMessages = oldMessages.concat(action.data);
            pageEnd = false;
            if (action.data.length < 30) {
                pageEnd = true;
            }
            return {
                ...state,
                msgLoadErr: '',
                page: action.page ? action.page + 1 : 1,
                messages: oldMessages,
                loading: false,
                pageEnd,
            };
        case TYPE_MESSAGES_LIST_FAILURE:
            return {
                ...state,
                msgLoadErr: 'Unable to load the messages',
                loading: false,
                pageEnd: true,
            };
        case TYPE_MESSAGE_SEND_INIT:
            return {
                ...state,
                msgSendErr: '',
                message: {},
            };
        case TYPE_MESSAGE_SEND_SUCCESS:
            return {
                ...state,
                msgSendErr: '',
                message: {},
            };
        case TYPE_MESSAGE_SEND_FAILURE:
            return {
                ...state,
                msgSendErr: '',
                message: {},
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
