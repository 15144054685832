import { StyledEngineProvider } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {
    deDE, enUS, frFR, itIT, MobileDatePicker, MobileDateTimePicker, MobileTimePicker
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    pickersLayoutClasses
} from '@mui/x-date-pickers/PickersLayout';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment, { Moment } from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import '../../resources/css/fields.css';
import { getTheme } from '../../resources/utils';
import { ApplicationState } from '../../store/types';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

/**
 * Component that represent a date picker field
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    value?: Moment | null;
    pickerType?: any;
    disabled?: boolean;
    containerClasses?: string;
    error?: any;
    format?: string | null;
    onChange?: (e: Moment | null) => void;
    placeholder?: string;
    fontSize?: string;
    padding?: string;
    noKeyboard?: boolean;
    textAlign?: any;
    inputRef?: any;
    forceValue?: boolean;
    disableFuture?: boolean;
    onFocus?: any;
    animateYearScrolling?: boolean;
    disablePast?: boolean;
    readOnly?: boolean;
    minDate?: Moment;
    shouldDisableDate?: (day: Moment) => boolean;
}

type Props = IProps & ReduxProps;

interface State {
    value?: Moment | null;
    theme: Theme;
}

class DatePickerField extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { value, forceValue } = this.props;

        const cssVar = (name: keyof ReturnType<typeof getTheme>) => {
            const theme = getTheme();
            if (name in theme) {
                return theme[name] ?? '#111111';
            }
            return '#111111';
        };

        this.state = {
            value: value || (forceValue === false ? undefined : value),
            theme: createTheme({
                palette: {
                    primary: { main: cssVar('--font-input-color') },
                },

            }),
        };
    }

    componentDidMount() {
    }

    onChange = (value: Moment | null) => {
        const { onChange } = this.props;
        this.setState({ value });
        if (onChange) onChange(value);
    };

    render() {
        const {
            pickerType,
            disabled,
            containerClasses,
            error,
            format,
            value: valueProps,
            onChange,
            placeholder,
            fontSize,
            padding,
            noKeyboard,
            textAlign,
            language,
            dispatch,
            inputRef,
            forceValue,
            ...props
        } = this.props;
        const { theme, value: valueState } = this.state;

        let DatePickerComponent;
        if (noKeyboard) {
            if (pickerType === 'time') {
                DatePickerComponent = MobileTimePicker;
            } else if (pickerType === 'datetime') {
                DatePickerComponent = MobileDateTimePicker;
            } else {
                DatePickerComponent = MobileDatePicker;
            }
        } else if (pickerType === 'time') {
            DatePickerComponent = TimePicker;
        } else if (pickerType === 'datetime') {
            DatePickerComponent = DateTimePicker;
        } else {
            DatePickerComponent = DatePicker;
        }

        moment.locale(language);

        let returnCursorStyle;

        if (disabled) {
            returnCursorStyle = 'not-allowed';
        } else if (noKeyboard) {
            returnCursorStyle = 'pointer';
        } else {
            returnCursorStyle = 'auto';
        }

        let localText = frFR;
        switch (language) {
            case 'de':
            case 'de-CH':
            case 'de-DE':
            case 'de-AU':
                localText = deDE;
                break;
            case 'fr':
            case 'fr-CH':
            case 'fr-FR':
            case 'fr-BE':
                localText = frFR;
                break;
            case 'en':
            case 'en-GB':
            case 'en-US':
                localText = enUS;
                break;
            case 'it':
            case 'it-IT':
            case 'it-CH':
                localText = itIT;
                break;
            default:
                localText = frFR;
        }
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment} localeText={localText.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <div className="__textfield-parent">
                            <div className={`__datepicker-container ${containerClasses || ''} ${error ? '__textfield-content-error' : ''}`}>
                                <DatePickerComponent
                                    inputRef={inputRef}
                                    className={`__datepicker-input ${pickersLayoutClasses.root}`}
                                    // ampm={false}
                                    disabled={disabled}
                                    // variant="dialog"
                                    // inputVariant="standard"
                                    format={format || 'DD/MM/YYYY'}
                                    // margin="none"
                                    value={
                                        valueProps
                                        || (forceValue === false
                                            ? valueState : valueProps)
                                    }
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...(noKeyboard ? {
                                        onAccept: this.onChange,
                                    } : {
                                        onChange: this.onChange
                                    }
                                    )}
                                    slotProps={{
                                        layout: {
                                            sx: {
                                                '.MuiPickersCalendarHeader-root': {
                                                    color: 'var(--font-input-color)'
                                                },
                                                '.MuiSvgIcon-root': {
                                                    color: 'var(--font-input-color)'
                                                },
                                                '.MuiPickersDay-root': {
                                                    color: 'var(--font-input-color)'
                                                },
                                                '.MuiPickersDay-today': {
                                                    borderColor: 'var(--font-input-color)'
                                                }
                                            }
                                        }
                                    }}
                                    sx={{
                                        fontSize,
                                        '& .MuiOutlinedInput-input': {
                                            fontSize,
                                            WebkitTextFillColor: 'var(--font-input-color)'
                                        },
                                        height: 44,
                                        justifyContent: 'center',
                                        cursor: returnCursorStyle,
                                        '.MuiInputBase-root:hover fieldset': {
                                            // borderColor: '#c78034',
                                            border: 'none'
                                        },
                                        '.Mui-focused fieldset': {
                                            // borderColor: '#c78034!important',
                                            // borderWidth: '0px!important'
                                            border: 'none'
                                        },
                                        '& .MuiDayCalendar-weekNumber': {
                                            color: 'var(--font-input-color)',
                                        },
                                        '.MuiPickersDay-root': {
                                            color: 'var(--font-input-color)',
                                            backgroundColor: 'red',

                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: 'var(--font-input-color)'
                                        },
                                    }}
                                    // invalidDateMessage=""
                                    // maxDateMessage=""
                                    // minDateMessage=""
                                    // placeholder={placeholder}
                                    // inputProps={{
                                    //     disableunderline: true,
                                    //     'aria-label': 'date',
                                    //     style: {
                                    //         fontFamily: 'Montserrat',
                                    //         fontSize: fontSize || '16px',
                                    //         padding: padding || '10px 15px',
                                    //         width: '100 %',
                                    //         cursor: returnCursorStyle,
                                    //         textAlign: textAlign || 'inherit',
                                    //         color: disabled ? '#9C9C9D' : 'black',
                                    //     },
                                    // }}
                                    // keyboardbuttonprops={{ KROK Wrong property name
                                    // KeyboardButtonProps={{
                                    //     'aria-label': 'date-picker',
                                    //     style: {
                                    //         backgroundColor: 'transparent',
                                    //     },
                                    // }}
                                    defaultValue={moment()}
                                    // initialFocusedDate={moment()._d} KROK Changed ._d to .toDate()
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...props}
                                />
                            </div>
                            {
                                error
                                    ? <p className="__textfield-error">{error}</p>
                                    : null
                            }
                        </div>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    language: store.translation.language,
});
const connector = connect(mapStateToProps);

export default connector(DatePickerField);
