import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Snackbar as MaterialSnackbar, SnackbarContent } from '@mui/material';

const variantIcon: any = {
    warning: WarningIcon,
    error: ErrorIcon,
    success: CheckCircleIcon,
};

const classes: any = {
    error: {
        backgroundColor: '#c40928',
    },
    warning: {
        backgroundColor: '#e09100',
    },
    success: {
        backgroundColor: 'green',
    },
};

interface IProps {
    variant: string;
    open?: boolean;
    timer?: number;
    message: string | JSX.Element | (() => JSX.Element);
    onClose?: () => void;
}

type Props = IProps;

function Snackbar(props: Props) {
    const {
        variant, open, timer, onClose, message,
    } = props;
    const Icon = variantIcon[variant];

    let returnElement;

    if (typeof message === 'function') {
        if (open) {
            returnElement = message();
        } else {
            returnElement = null;
        }
    } else {
        returnElement = message;
    }

    return (
        <MaterialSnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={timer}
            onClose={onClose}
            ClickAwayListenerProps={{
                mouseEvent: false,
            }}
        >
            <SnackbarContent
                className="__snackbar-content"
                style={classes[variant]}
                message={(
                    <span className="__snackbar-message">
                        <Icon className="__snackbar-icon" />
                        {
                            returnElement
                        }
                    </span>
                )}
                action={
                    onClose
                        ? [
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={onClose}
                                size="large"
                            >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ] : null
                }
            />
        </MaterialSnackbar>
    );
}

export default Snackbar;
