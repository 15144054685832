import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { getActivityNameFromType, getLessonNameFromType } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import { Level } from '../../types/booking';
import Title from '../common/title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    currentLevel?: Level;
}

type NewType = ReduxProps;

type Props = IProps & WrappedComponentProps & NewType;

function BookingHeader(props: Props) {
    const { currentLevel, lesson, intl } = props;

    return (
        <div className="__booking-header">
            <Title className="__booking-header-title">
                {lesson?.title}
            </Title>
            <div className="__booking-header-content">
                <div className="__booking-header-content-text">
                    <span className="__booking-header-content-info">
                        <p className="__booking-header-category">
                            {intl.formatMessage({ id: 'products.type' })}
                            :
                        </p>
                        <p className="__booking-header-text">{lesson && getLessonNameFromType(lesson.lesson_type, intl.formatMessage)}</p>
                    </span>
                    <span className="__booking-header-content-info">
                        <p className="__booking-header-category">
                            {intl.formatMessage({ id: 'products.location' })}
                            :
                        </p>
                        <p className="__booking-header-text">{lesson?.school ? lesson.school.name : null}</p>
                    </span>
                    <span className="__booking-header-content-info">
                        <p className="__booking-header-category">
                            {intl.formatMessage({ id: 'products.activity' })}
                            :
                        </p>
                        <p className="__booking-header-text">{lesson && lesson.activity_type?.split(';').map((t) => getActivityNameFromType(t, intl.formatMessage)).join(', ')}</p>
                    </span>
                </div>
                <div className="__booking-header-content-description">
                    <span className="__booking-header-content-info">
                        <span className="__booking-header-text-description">
                            {/* eslint-disable-next-line react/no-danger */}
                            <span className="__booking-header-text-description" dangerouslySetInnerHTML={{ __html: lesson == null || lesson.description === 'nan' ? '' : lesson.description }} />
                        </span>
                    </span>
                    {
                        currentLevel && currentLevel.name && currentLevel.name.length > 0
                        && currentLevel.memo && currentLevel.memo.length > 0
                        && (
                            <span className="__booking-header-content-info">
                                <span className="__booking-header-text-description" style={{ marginTop: '10px' }}>
                                    <h2>{currentLevel.name}</h2>
                                    <span className="__booking-header-text-description">
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <span className="__booking-header-text-description" dangerouslySetInnerHTML={{ __html: currentLevel?.memo ? currentLevel.memo : '' }} />
                                    </span>
                                </span>
                            </span>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (store: ApplicationState) => ({
    lesson: store.booking.currentLesson,
    booking: store.booking,
});

const connector = connect(mapStateToProps);

export default injectIntl(connector(BookingHeader));
