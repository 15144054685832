/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Theme } from '@mui/material/styles';
import moment from 'moment';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { connect, Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import AddLanguage from './components/specialRoutes/addLanguage';
import RouteSelector from './components/specialRoutes/routeSelector';
import { VERSIONS } from './resources/constants';
import './resources/css/account.css';
import './resources/css/accountLessons.css';
import './resources/css/cards.css';
import './resources/css/footer.css';
import './resources/css/global.css';
import './resources/css/header.css';
import './resources/css/landing.css';
import './resources/css/login.css';
import './resources/css/menubar.css';
import './resources/css/modals.css';
import './resources/css/topbar.css';
import './resources/css/util.scss';
import {
    applyTheme, getAllVersionUrls, getCurrentVersion
} from './resources/utils';
import changeHelpContentHeight from './store/actions/help';
import { changeNavigationContentHeight } from './store/actions/navigation';
import { toggleOpenPWAModal } from './store/actions/user';
import { changeHeight, changeWidth } from './store/actions/windowSize';
import { Store } from './store/types';
import german from './translations/de.json';
import english from './translations/en.json';
import french from './translations/fr.json';
import italian from './translations/it.json';
import './translations/moment';

const messages = {
    en: english,
    fr: french,
    de: german,
    it: italian,
};
export type ObjectKey = keyof typeof messages;

// type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    store: Store;
    language: string;
}
type Props = IProps;

interface State {
    theme: Theme;
}

// const CHROME_VERSION: number = 80;
// const FIREFOX_VERSION: number = 35;

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // const cssVar = (name: keyof ReturnType<typeof getTheme>) => {
        //     const theme = getTheme();
        //     if (name in theme) {
        //         return theme[name] ?? '#111111';
        //     }
        //     return '#111111';
        // };
        // this.state = {
        //     theme: createTheme({
        //         palette: {
        //             primary: { main: cssVar('--font-input-color') },
        //         },
        //     }),
        // };
    }

    componentDidMount() {
        const { store } = this.props;
        // apply correct theme
        applyTheme();

        // handle window resizing
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('message', this.handleMessage);

        // handle PWA (not working, not finished)
        // let deferredPrompt;
        window.addEventListener('beforeinstallprompt', () => {
            // Stash the event so it can be triggered later.
            // deferredPrompt = e;
            store.dispatch(toggleOpenPWAModal());
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('message', this.handleMessage);
    }

    handleResize = () => {
        const { store } = this.props;
        const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
        const windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0;
        store.dispatch(changeHeight(windowHeight));
        store.dispatch(changeWidth(windowWidth));
    };

    handleMessage = (event: any) => {
        const { store } = this.props;
        let isAnAvailableUrl = false;
        const versionUrls = getAllVersionUrls();
        for (const url of versionUrls) {
            if (event.origin.includes(`content.${url}`)) {
                isAnAvailableUrl = true;
                break;
            }
        }
        if (isAnAvailableUrl) {
            if (event.data.type === 'help') store.dispatch(changeHelpContentHeight(event.data.height));
            else if (event.data.type === 'navigation') store.dispatch(changeNavigationContentHeight(event.data.height));
        }
    };

    render() {
        const { store, language: languageProps } = this.props;
        // const { theme } = this.state;
        const language:string = languageProps ?? 'fr';
        // const language = 'fr';
        moment.locale(language);
        const currentVersion = getCurrentVersion();

        const lang: ObjectKey = language as ObjectKey;

        return (
            <Provider store={store}>
                <IntlProvider locale={language} messages={messages[lang]}>
                    {/* <StyledEngineProvider injectFirst>

                        <ThemeProvider theme={theme}> */}
                    <HelmetProvider>
                        {
                            /* currentVersion === VERSIONS.BookingSport ?
                        <Helmet
                            key={
                                `helmet-version-${currentVersion}-lang-${language}`
                            }
                            prioritizeSeoTags={true}>
                            <title>Guides des Alpes Vaudoises</title>
                            <meta name="title" content="Guides des Alpes Vaudoises" />
                            <meta name="keywords" content="Guides des Alpes Vaudoises" />
                            <meta name="description" content="Guides des Alpes Vaudoises" />
                            <meta
                                name="description"
                                content="Réservez vos cours de ski, snowboard et
                                autres activités en Valais, Vaud sur la plateforme
                                officielle des écoles de ski Suisse avec
                                Swiss Ski School." />
                            <link
                                id="favicon-apple"
                                rel="apple-touch-icon"
                                sizes="180x180"
                                href={faviconApple} />
                            <link
                                id="favicon-32"
                                rel="icon"
                                type="image/png"
                                sizes="32x32" href={favicon32} />
                            <link
                                id="favicon-16"
                                rel="icon"
                                type="image/png"
                                sizes="16x16"
                                href={favicon16} />
                            <link
                                id="favicon"
                                rel="icon"
                                type="image/x-icon"
                                href={favicon} />
                            <meta property="og:image" content="https://storage.googleapis.com/snowpros/images/header_ski_lessons_valais/nendaz-lessons-les-ski-siviez-cours-group-groep-collectif-prive-privat-ski-school-ecole-anzere-thyon-veysonnaz-la-tzoumaz-verbier-crans-montana-veysonnaz-thyon-st-luc-grimentz-zinal-unterricht-ecole-valais-wallis-suisse-schweiz-switzerland.jpg" />
                            <meta property="og:site_name" content="Guides des Alpes Vaudoises" />
                            <meta property="og:title" content="Guides des Alpes Vaudoises" />
                            <meta property="og:description" content="Guides des Alpes Vaudoises" />
                            <meta name="twitter:title" content="Guides des Alpes Vaudoises" />
                            <meta name="twitter:description" content="Guides des Alpes Vaudoises" />
                            <meta name="twitter:image" content="https://storage.googleapis.com/snowpros/images/header_ski_lessons_valais/nendaz-lessons-les-ski-siviez-cours-group-groep-collectif-prive-privat-ski-school-ecole-anzere-thyon-veysonnaz-la-tzoumaz-verbier-crans-montana-veysonnaz-thyon-st-luc-grimentz-zinal-unterricht-ecole-valais-wallis-suisse-schweiz-switzerland.jpg" />
                            <meta name="twitter:card" content="summary_large_image" />
                            <link rel="manifest" href={manifest} />
                            <link rel="mask-icon" href={safari} color="#5bbad5" />
                            <meta name="msapplication-TileColor" content="#ffffff" />
                            <meta name="theme-color" content="#ffffff" />
                        </Helmet>
                                : */
                            currentVersion === VERSIONS.Swisskischool ? (
                                <Helmet key={`helmet-version-${currentVersion}-lang-${language}`}>
                                    <script>
                                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','GTM-MMCS9NV');`}
                                    </script>
                                </Helmet>
                            ) : null
                        }
                        <Router>
                            <Route path="/:lang(en|fr|de|it)" component={RouteSelector} />
                            <Route path="/:lang(en|fr|de|it)?" component={AddLanguage} />
                        </Router>
                    </HelmetProvider>
                    {/* </ThemeProvider>
                    </StyledEngineProvider> */}
                </IntlProvider>
            </Provider>
        );
    }
}

const mapStateToProps = (store:any) => ({
    language: store.translation.language
});

export default connect(mapStateToProps)(App);
