/* eslint-disable jsx-a11y/no-static-element-interactions,
jsx-a11y/click-events-have-key-events */
import SearchIcon from '@mui/icons-material/Search';
import { Drawer, MenuItem } from '@mui/material';
import moment, { Moment } from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import {
    LESSON_FILTERS,
    SMARTPHONE_WIDTH,
    URL_DATE,
    URL_LESSON, URL_SCHOOL, VERSIONS
} from '../../resources/constants';
import {
    domaineBaseHasSubdomainAlready, getCurrentVersion, getSchoolById, getSubDomain
} from '../../resources/utils';
import { changeSearchLessonType, changeSearchSelectedSchool, changeSearchStartDate } from '../../store/actions/landing';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import DatePickerField from '../common/datePickerField';
import SelectField from '../common/selectField';
import Title from '../common/title';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    dispatch: StoreDispatch;
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    toLesson: boolean;
    open: boolean;
    schoolError: boolean;
}

function replaceSubdomain(urlParam: string, toSubdomain: string) {
    const values = domaineBaseHasSubdomainAlready();

    const replace = `://${toSubdomain}.`;
    let url = urlParam;
    // Prepend http://
    if (!/^\w*:\/\//.test(url)) {
        url = `http://${url}`;
    }

    // Check if we got a subdomain in url
    const match = url.match(/\.\w*\b/g);
    if (match && match.length > 1 && match.length > (values.max - 1)) {
        const redirectUrl = url.replace(/(:\/\/\w+\.)/, replace);
        return redirectUrl;
    }

    const redirectUrl = url.replace(/:\/\//, `${replace}`);
    // const redirectUrl = url.replace(/:\/\/(\w*\.)/, `${replace}`);
    return `${redirectUrl}`;
}

class SearchBar extends React.Component<Props, State> {
    dateRef: any;

    constructor(props: Props) {
        super(props);

        const { startDate, dispatch } = this.props;

        this.state = {
            toLesson: false,
            open: false,
            schoolError: false,
        };

        this.handleChangeSchool = this.handleChangeSchool.bind(this);
        this.handleChangeLessonType = this.handleChangeLessonType.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.createSearch = this.createSearch.bind(this);

        if (startDate === null) {
            const today = moment();
            dispatch(changeSearchStartDate(today));
        }
    }

    handleChangeSchool(event: any) {
        const { dispatch, schools } = this.props;
        const selctedSchool = schools.find((s: any) => s.id === event.target.value);

        dispatch(changeSearchSelectedSchool(selctedSchool));
        this.setState({ schoolError: false });
    }

    handleChangeDate(date: Moment | null) {
        const { dispatch } = this.props;
        // const momentObject = moment(date);
        if (date) {
            dispatch(
                changeSearchStartDate(
                    date,
                ),
            );
        }
    }

    handleChangeLessonType(event: any) {
        const { dispatch } = this.props;
        dispatch(changeSearchLessonType(event.target.value));
    }

    handleClickSearch() {
        const { selectedSchool } = this.props;
        if (selectedSchool) {
            this.setState({ toLesson: true });
        } else {
            this.setState({ schoolError: true });
        }
    }

    toggleDrawer = () => this.setState((state) => ({ open: !state.open }));

    createSearch() {
        const { selectedSchool, lessonType, startDate } = this.props;
        let search = '';
        const schoolName = (selectedSchool && getSchoolById(selectedSchool.id)) ?? undefined;
        if (schoolName && schoolName === 'crans-montana') {
            search += `?${URL_SCHOOL.NAME}=${schoolName}`;
            if (lessonType !== '') {
                let type = '';

                if (lessonType === URL_LESSON.PRIVATE) {
                    type = LESSON_FILTERS.CRANS_PRIVATE;
                } else {
                    type = `${LESSON_FILTERS.CRANS_KIDS_CRANS},${LESSON_FILTERS.CRANS_KIDS_GOLF},${LESSON_FILTERS.CRANS_KIDS_MONTANA},${LESSON_FILTERS.CRANS_SPECIAL},${LESSON_FILTERS.CRANS_SNOWBOARD},${LESSON_FILTERS.CRANS_GROUP}`;
                }

                search += `&${URL_LESSON.NAME}=${type}`;
            }
        } else {
            if (schoolName) {
                search += `?${URL_SCHOOL.NAME}=${schoolName}`;
            }

            if (lessonType !== '') {
                if (schoolName) search += '&';
                else search += '?';
                search += `${URL_LESSON.NAME}=${lessonType}`;
            }
        }

        if (moment().startOf('days').diff(startDate, 'days') < 0) {
            if (schoolName || lessonType !== '') search += '&';
            else search += '?';
            search += `${URL_DATE.NAME}=${startDate?.format('YYYY-MM-DD')}`;
        }

        return search;
    }

    render() {
        const {
            selectedSchool, intl, windowWidth, schools, lessonType, startDate,
        } = this.props;
        const { toLesson, schoolError, open } = this.state;
        // redirect to lesson page
        if (toLesson && selectedSchool) {
            let url = replaceSubdomain(window.location.href, selectedSchool.name.toLowerCase().replace('ess ', '').replace(/ /g, '').replace(/'/g, '')
                .normalize('NFD')
                .replace(/\p{Diacritic}/gu, ''));

            if (selectedSchool.name.toLowerCase().indexOf('diablerets') > 0) url = replaceSubdomain(window.location.href, 'diablerets');
            if (selectedSchool.name.toLowerCase().indexOf('villars-sur-ollon') > 0) url = replaceSubdomain(window.location.href, 'villars');
            if (url.charAt(url.length - 1) !== '/') url += '/';
            window.location.href = `${url}products${this.createSearch()}`;
            // return <Redirect push to={{
            //     pathname: `/${language}/products`,
            //     search: this.createSearch(),
            // }} />;
        }

        const hasSubDomain = Boolean(getSubDomain());

        // Search icon
        const searchIcon = (
            <div className="__landing-search-bar-button" onClick={this.handleClickSearch}>
                <SearchIcon className="__landing-search-bar-button-icon" />
                <p className="__landing-search-bar-button-text">{intl.formatMessage({ id: 'landing.search' })}</p>
            </div>
        );

        // Schools selection
        const schoolsSelect = (
            <div className={`__landing-search-bar-schools ${schoolError && '__landing-search-bar-schools-error'}`}>
                {
                    hasSubDomain && selectedSchool
                        ? (
                            <p className="__landing-search-bar-selected-school">
                                {
                                    getCurrentVersion() === VERSIONS.BookingFormation
                                        ? intl.formatMessage({ id: 'landing.bookingformation.search' })
                                        : selectedSchool.name
                                }
                            </p>
                        )
                        : (
                            <SelectField
                                // containerClasses="__landing-search-bar-field __landing-search-bar-select"
                                className="__landing-search-bar-field __landing-search-bar-select"
                                height="52px"
                                error={schoolError}
                                fontSize={windowWidth > SMARTPHONE_WIDTH ? '18px' : '16px'}
                                onChange={this.handleChangeSchool}
                                value={selectedSchool ? selectedSchool.id : 0}
                            >
                                {!hasSubDomain && <MenuItem key="school_default" value={0} disabled>{intl.formatMessage({ id: 'products.schoolTitle' })}</MenuItem>}
                                {schools.filter((s) => s.online_purchase).sort((a, b) => (a.name > b.name ? 1 : -1)).map((school) => <MenuItem key={`school_${school.id}`} value={school.id}>{school.name}</MenuItem>)}
                            </SelectField>
                        )
                }
            </div>
        );
        // Type selection
        const typeSelect = (
            <div className="__landing-search-bar-type">
                <SelectField
                    className="__landing-search-bar-field"
                    height={windowWidth > SMARTPHONE_WIDTH ? 52 : 38}
                    fontSize={windowWidth > SMARTPHONE_WIDTH ? '18px' : '16px'}
                    onChange={this.handleChangeLessonType}
                    value={lessonType}
                    padding="0px 0px 3px 0px"
                    forceValue
                >
                    <MenuItem value="" disabled>{intl.formatMessage({ id: 'products.typeTitle' })}</MenuItem>
                    <MenuItem value={URL_LESSON.GROUP}>{intl.formatMessage({ id: 'products.group' })}</MenuItem>
                    <MenuItem value={URL_LESSON.PRIVATE}>{intl.formatMessage({ id: 'products.private' })}</MenuItem>
                </SelectField>
            </div>
        );

        // Date picker
        const datePicker = (
            <div className="__landing-search-bar-date">
                <DatePickerField
                    ref={this.dateRef}
                    containerClasses="__landing-search-bar-field"
                    fontSize={windowWidth > SMARTPHONE_WIDTH ? '18px' : '16px'}
                    padding={windowWidth > SMARTPHONE_WIDTH ? '6px 0px 6px 15px' : '8px 0px 6px 15px'}
                    onChange={this.handleChangeDate}
                    value={startDate ?? null}
                    noKeyboard={windowWidth <= SMARTPHONE_WIDTH}
                    disablePast
                    shouldDisableDate={(day) => moment().startOf('day').diff(moment(day), 'day') >= 1}
                    format={windowWidth > SMARTPHONE_WIDTH ? null : 'DD MMMM YYYY'}
                />
            </div>
        );

        return (
            <div className="__landing-search-bar-parent">
                {
                    windowWidth > SMARTPHONE_WIDTH
                        ? (
                            <div className="__landing-search-bar">
                                {schoolsSelect}
                                <div className="__landing-search-bar-divider" />
                                {datePicker}
                                {
                                    getCurrentVersion() !== VERSIONS.BookingFormation
                                        ? (
                                            <>
                                                <div className="__landing-search-bar-divider" />
                                                {typeSelect}
                                            </>
                                        )
                                        : null
                                }
                                <div className="__landing-search-bar-divider" />
                                {searchIcon}
                            </div>
                        )
                        : (
                            <div className="__landing-search-bar-parent-sp">
                                <div className="__landing-search-bar-sp-content">
                                    <div className="__landing-search-bar-sp-field-container" onClick={this.toggleDrawer}>
                                        <div className="__landing-search-bar-sp-field">
                                            {intl.formatMessage({ id: 'landing.searchbar.title' })}
                                        </div>
                                        <div className="__landing-search-bar-divider" />
                                        <div className="__landing-search-bar-button">
                                            <SearchIcon className="__landing-search-bar-button-icon" />
                                        </div>
                                    </div>
                                </div>
                                <Drawer
                                    anchor="top"
                                    open={open}
                                    onClose={this.toggleDrawer}
                                    PaperProps={{
                                        style: {
                                            maxWidth: '100%', height: 'fit-content', maxHeight: '100%', borderRadius: '0px 0px 20px 20px'
                                        }
                                    }}
                                >
                                    <div className="__landing-search-bar-drawer">
                                        <Title className="__landing-search-bar-drawer-title">{intl.formatMessage({ id: 'landing.searchbar.title' })}</Title>
                                        <div className="__landing-search-bar-drawer-section">
                                            <p className="__landing-search-bar-drawer-section-title">{intl.formatMessage({ id: 'products.location' })}</p>
                                            <div className="__landing-search-bar-panel">
                                                {schoolsSelect}
                                            </div>
                                        </div>
                                        <div className="__landing-search-bar-drawer-section">
                                            <p className="__landing-search-bar-drawer-section-title">{intl.formatMessage({ id: 'products.date' })}</p>
                                            <div className="__landing-search-bar-panel">
                                                {datePicker}
                                            </div>
                                        </div>

                                        <div className="__landing-search-bar-drawer-section">
                                            <p className="__landing-search-bar-drawer-section-title">{intl.formatMessage({ id: 'products.type' })}</p>
                                            <div className="__landing-search-bar-panel">
                                                {typeSelect}
                                            </div>
                                        </div>

                                        <div className="__landing-search-bar-sp-button" onClick={this.handleClickSearch}>
                                            <p className="__landing-search-bar-button-text">{intl.formatMessage({ id: 'landing.search' })}</p>
                                        </div>
                                    </div>
                                </Drawer>
                            </div>
                        )
                }
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    schools: store.products.schools,
    windowWidth: store.windowSize.width,
    language: store.translation.language,
    selectedSchool: store.landing.searchSelectedSchool,
    lessonType: store.landing.searchLessonType,
    startDate: store.landing.searchStartDate,
    userData: store.setting.userData,
    currentSchool: store.navigation.currentSchool,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(withRouter(SearchBar)));
