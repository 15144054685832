/* eslint-disable no-nested-ternary */
import CloseIcon from '@mui/icons-material/Close';
import {
    List,
    ListItem,
    ListItemText,
    Skeleton
} from '@mui/material';
import 'animate.css/animate.min.css';
import Cookie from 'js-cookie';
import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SMARTPHONE_WIDTH, VERSIONS } from '../../resources/constants';
import {
    getCurrentVersion,
    getDomain, getSubDomain, getTranslation, getVersionName, ImgWithFallback
} from '../../resources/utils';
import {
    changeSearchSelectedSchool,
    fetchLandingLesson,
    fetchNewsfeed,
    fetchNewsflash,
    fetchSchoolSiteContent
} from '../../store/actions/landing';
import { Language } from '../../store/reducers/translation';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import { LessonCardData } from '../../types/landing';
import { ShopContent } from '../../types/settings';
import authenticator from '../authenticator';
import Container from '../common/container';
import Modal from '../common/modal';
import Title from '../common/title';
import LandingChangePassword from './landingChangePassword';
import LessonCard from './lessonCard';
import NewsfeedCard from './newsfeedCard';
import SearchBar from './searchBar';
import Section from './section';

type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
}

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    isLoggedIn?: string;
    lessonMaxLength: number;
    isNewsFlash: boolean;
    grimentzZinalModal: boolean;
    neuchatelRegionModal: boolean;
    path?: string;
}

class Landing extends React.Component<Props, State> {
    static getLessonSkeletons() {
        return (
            <div className="__landing-section-content">
                {
                    [...Array(3).keys()].map((index) => (
                        <div className="__landing-lesson-card-skeleton" key={index}>
                            <Skeleton variant="rectangular" style={{ width: '100%)' }} className="__landing-lesson-card-skeleton-rect" />
                            <Skeleton variant="text" style={{ width: '60%' }} height={32} />
                            <Skeleton variant="text" style={{ width: '40%' }} height={32} />
                        </div>
                    ))
                }
            </div>
        );
    }

    static getNewsfeedSkeletons() {
        return (
            <div className="__landing-section-content">
                {
                    [...Array(3).keys()].map((item) => (
                        <div key={`skeleton-map-item-${item}`} className="__landing-newsfeed-card-skeleton">
                            <Skeleton variant="rectangular" width={360} height={145} />
                        </div>
                    ))
                }
            </div>
        );
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            isLoggedIn: authenticator.isLoggedIn(),
            lessonMaxLength: 100,
            isNewsFlash: true,
            grimentzZinalModal: false,
            neuchatelRegionModal: false,
            path: undefined,
        };

        // bind methods
        this.viewAllNewsfeed = this.viewAllNewsfeed.bind(this);
        this.newsfeedItemClick = this.newsfeedItemClick.bind(this);
        this.viewAllProducts = this.viewAllProducts.bind(this);
    }

    componentDidMount() {
        const {
            productSchools, currentSchool, runFetchNewsfeed,
            runFetchNewsflash,
            runFetchLandingLesson,
            runChangeSearchSelectedSchool,
            runFetchSchoolSiteContent
        } = this.props;
        if (document.getElementById('top')) document.getElementById('top')?.scrollIntoView();

        // if it is the default domain, fetch base landing lessons and newsfeed
        if (getSubDomain() === undefined) {
            runFetchNewsfeed(0);
            runFetchNewsflash(0);
            runFetchLandingLesson(0);
        } else if (currentSchool && productSchools.length > 0) {
            runChangeSearchSelectedSchool(
                productSchools.find(
                    (s: any) => s.external_id === currentSchool.int_id
                        && s.department_id === currentSchool.department_id,
                ),
            );
            runFetchNewsfeed(currentSchool.id);
            runFetchNewsflash(currentSchool.id);
            runFetchLandingLesson(currentSchool.id);
            runFetchSchoolSiteContent(currentSchool.id);
        }
    }

    componentDidUpdate(): void {
        const {
            newsflash,
            language,
            currentSchool,
            productSchools,
            searchSelectedSchool,
            userData,
            products,
            runChangeSearchSelectedSchool,
            runFetchNewsfeed,
            runFetchNewsflash,
            runFetchLandingLesson,
            runFetchSchoolSiteContent,
        } = this.props;
        const { isNewsFlash, isLoggedIn } = this.state;
        const tmp = newsflash.length > 0 ? Cookie.get(`newsflash${newsflash[0].id}-${language}`) : undefined;
        if (tmp && tmp === 'false' && isNewsFlash !== false) {
            this.setState({ isNewsFlash: false });
        }
        if (!tmp && newsflash.length > 0) {
            Cookie.set(`newsflash${newsflash[0].id}-${language}`, 'true', { expires: 1, domain: getDomain() });
        }
        // Set the selected search school and fetch newsfeed + landing lessons
        if (currentSchool && productSchools.length > 0 && !searchSelectedSchool) {
            runChangeSearchSelectedSchool(
                productSchools.find(
                    (s: any) => s.external_id === currentSchool.int_id
                        && s.department_id === currentSchool.department_id,
                ),
            );
            runFetchNewsfeed(currentSchool.id);
            runFetchNewsflash(currentSchool.id);
            runFetchLandingLesson(currentSchool.id);
            runFetchSchoolSiteContent(currentSchool.id);
        } else if (isLoggedIn && userData.company
            && userData.company.version !== getCurrentVersion()
            && !getSubDomain() && !products) {
            runFetchNewsfeed(0);
            runFetchNewsflash(0);
            runFetchLandingLesson(0);
        }
    }

    lessonItemClickPickerResort(resort?: string) {
        const { path } = this.state;
        const { history, language } = this.props;
        if (path && resort) {
            if (path.includes('http')) window.location.href = `${path}/${language}`;
            else if (path.includes('?')) history.push(`/${language}/${path}&resort=${resort}`);
            else history.push(`/${language}/${path}?resort=${resort}`);
        }
    }

    lessonItemClick(path: string) {
        const { currentSchool, history, language } = this.props;
        if (currentSchool?.int_id === '2') {
            this.setState({ grimentzZinalModal: true, path });
        } else if (currentSchool?.int_id === '37') {
            this.setState({ neuchatelRegionModal: true, path });
        } else
            if (path) {
            // Maybe break (navigationPage l11)
            // window.scrollTo(0, 0);
                if (path.includes('http')) window.location.href = path;
                else history.push(`/${language}/${path}`);
            }
    }

    newsfeedItemClick(id: any) {
        const { history, match } = this.props;
        history.push(`/${match.params.lang}/newsfeed/${id}`);
    }

    viewAllProducts() {
        const { history, language } = this.props;
        history.push(`/${language}/products`);
    }

    viewAllNewsfeed() {
        const { history, language } = this.props;
        history.push(`/${language}/newsfeed`);
    }

    disableNewsflash() {
        const { newsflash, language } = this.props;
        if (newsflash.length > 0) Cookie.set(`newsflash${newsflash[0].id}-${language}`, 'false', { expires: 1, domain: getDomain() });
        this.setState({ isNewsFlash: false });
    }

    render() {
        const {
            generalMedia,
            language,
            productSchools,
            schoolSiteContent: schoolSiteContentProps,
            currentSchool,
            isLessonLoading,
            match,
            intl,
            newsfeed,
            isNewsfeedLoading,
            newsflash,
            products,
            windowWidth,
        } = this.props;
        const {
            lessonMaxLength, isNewsFlash, grimentzZinalModal, neuchatelRegionModal,
        } = this.state;
        const subDomain = getSubDomain();
        const texts = generalMedia ? [
            generalMedia[`top_header_banner_${language}`],
            generalMedia[`bottom_header_banner_${language}`]
        ] : [undefined, undefined];

        const notSmartphone = windowWidth > SMARTPHONE_WIDTH;

        const onlinePurchase = productSchools.length > 0 && ((!subDomain && getCurrentVersion() !== VERSIONS.BookingSport) || (currentSchool && currentSchool.online_purchase));

        const schoolSiteContent: ShopContent[] = [];
        if (schoolSiteContentProps && schoolSiteContentProps.length > 0) {
            schoolSiteContentProps.filter((ssc) => ssc.content_type === 's_desc').forEach((ssc) => {
                schoolSiteContent.push(language === Language.en ? ssc : getTranslation(ssc.translations, language));
            });
        }

        const schoolSiteContentSitemap: ShopContent[] = [];
        if (schoolSiteContentProps && schoolSiteContentProps.length > 0) {
            schoolSiteContentProps.filter((ssc) => ssc.content_type === 'sitemap').forEach((ssc) => {
                schoolSiteContentSitemap.push(language === Language.en ? ssc : getTranslation(ssc.translations, language));
            });
        }

        let lessonsToDisplay: LessonCardData[] = [];
        let nbLessonsToDisplay = 0;
        if (!(!products || isLessonLoading)) {
            lessonsToDisplay = [...products];
            if (currentSchool) {
                lessonsToDisplay = lessonsToDisplay.filter((p) => getTranslation(p.translations, language)?.display);
            }
            lessonsToDisplay = lessonsToDisplay.slice(0, notSmartphone ? lessonMaxLength : products.length);
            nbLessonsToDisplay = lessonsToDisplay.length;
        }

        // Prepare image to <ImgWithFallback />
        let imageSrc = '';
        let imageFallback = '';
        let footerImage = '';
        let footerImageFallback = '';
        if (generalMedia) {
            const imageSource = generalMedia.logo;
            const re = /(?:\.([^.]+))?$/;
            const imageSourceExploded = re.exec(imageSource);
            const ext = imageSourceExploded ? imageSourceExploded[1] : '';
            imageSrc = imageSource;
            imageFallback = ext === 'webp' ? `${imageSource.slice(0, -4)}png` : imageSource;

            const footerImageSource = generalMedia.footer_image;
            const footerImageSourceExploded = re.exec(footerImageSource);
            const footerExt = footerImageSourceExploded ? footerImageSourceExploded[1] : '';

            footerImage = footerImageSource;
            footerImageFallback = footerExt === 'webp' ? `${footerImageSource.slice(0, -4)}jpg` : footerImageSource;
        }

        return (
            (match.params.lang !== 'fr' && subDomain !== undefined && subDomain === 'sion')
                ? <Redirect to="/fr/" />
                : (match.params.lang === 'it' && subDomain !== undefined && subDomain !== 'crans-montana')
                    ? <Redirect to="/en/" />
                    : (
                        <Container
                            mainHeader
                            headerBase={onlinePurchase ? <SearchBar /> : ''}
                            schoolName={currentSchool ? currentSchool.name : undefined}
                            texts={texts}
                            image={currentSchool && currentSchool.header_url ? currentSchool.header_url : (generalMedia ? generalMedia.header_image : undefined)}
                            withPlaceholder
                        >
                            <LandingChangePassword />
                            <div id="top" className="__landing-container">
                                {
                                    schoolSiteContent && schoolSiteContent.length > 0 && schoolSiteContent.map((ssc, index) => {
                                        const idx = index;
                                        return (
                                            <Section
                                                title={<AnimationOnScroll animateIn="animate__fadeIn" duration={1} animateOnce>{ssc.title}</AnimationOnScroll>}
                                                key={`school-site-content-description-${idx}`}
                                            // viewAll={false}//notSmartphone && products ? products.length > lessonMaxLength : false}
                                            // viewAllAction={() => this.setState({ lessonMaxLength: products.length })}
                                            // viewLessAction={notSmartphone && products && products.length > 4 ? () => this.setState({lessonMaxLength: 4}) : null}
                                            >

                                                <AnimationOnScroll animateIn="animate__fadeIn" duration={1} animateOnce>
                                                    {/* eslint-disable-next-line react/no-danger */}
                                                    <div className="__school-site-content" dangerouslySetInnerHTML={{ __html: ssc.html }} />
                                                </AnimationOnScroll>
                                            </Section>
                                        );
                                    })
                                }
                                <Section
                                    title={(
                                        <AnimationOnScroll animateIn="animate__fadeIn" duration={1} animateOnce>
                                            {
                                                currentSchool
                                                    ? getCurrentVersion() === VERSIONS.BookingSport
                                                        ? intl.formatMessage({ id: 'landing.connectedTitleBS' }, { school_name: currentSchool ? currentSchool.name : '' })
                                                        : getCurrentVersion() === VERSIONS.BookingFormation
                                                            ? intl.formatMessage({ id: 'landing.connectedTitleBF' })
                                                            : intl.formatMessage({ id: 'landing.connectedTitle' }, { school_name: currentSchool ? currentSchool.name : '' })

                                                    : getCurrentVersion() === VERSIONS.BookingSport
                                                        ? intl.formatMessage({ id: 'landing.notConnectedTitleBS' })
                                                        : getCurrentVersion() === VERSIONS.BookingSport
                                                            ? intl.formatMessage({ id: 'landing.notConnectedTitleBF' })
                                                            : intl.formatMessage({ id: 'landing.notConnectedTitle' })
                                            }
                                        </AnimationOnScroll>
                                    )}
                                    viewAll={false}// notSmartphone && products ? products.length > lessonMaxLength : false}
                                    viewAllAction={() => this.setState({ lessonMaxLength: products.length })}
                                // viewLessAction={notSmartphone && products && products.length > 4 ? () => this.setState({lessonMaxLength: 4}) : null}
                                >
                                    {
                                        !products || isLessonLoading
                                            ? Landing.getLessonSkeletons()
                                            : lessonsToDisplay.map((item, index) => {
                                                const idx = index;
                                                if (nbLessonsToDisplay === 4) {
                                                    return (
                                                        <AnimationOnScroll key={`landing-lesson-car-parent-${idx}`} className="__landing-lesson-card-parent-4" delay={idx * 50} offset={50} animateIn="animate__fadeIn" duration={2} animateOnce>
                                                            <LessonCard data={item} schoolName={currentSchool ? currentSchool.name : undefined} language={language} key={item.id.toString()} onCardClick={() => this.lessonItemClick(item.path)} />
                                                        </AnimationOnScroll>
                                                    );
                                                }
                                                return (
                                                    <AnimationOnScroll key={`landing-lesson-car-parent2-${idx}`} className="__landing-lesson-card-parent" delay={idx * 50} offset={50} animateIn="animate__fadeIn" duration={2} animateOnce>
                                                        <LessonCard data={item} schoolName={currentSchool ? currentSchool.name : undefined} language={language} key={item.id.toString()} onCardClick={() => this.lessonItemClick(item.path)} />
                                                    </AnimationOnScroll>
                                                );
                                            })
                                    }
                                </Section>
                                {
                                    newsfeed && newsfeed.length > 0
                      && (
                          <Section
                              title={intl.formatMessage({ id: 'landing.localNewsfeed' })}
                              viewAll={newsfeed ? newsfeed.length > 3 : false}
                              viewAllAction={this.viewAllNewsfeed}
                          >
                              {
                                  !newsfeed || isNewsfeedLoading
                                      ? Landing.getNewsfeedSkeletons()
                                      : newsfeed.slice(0, 3).map((item) => (
                                          <NewsfeedCard currentLang={language} data={item} key={item.id.toString()} readMoreAction={() => this.newsfeedItemClick(item.id)} />
                                      ))
                              }
                          </Section>
                      )
                                }
                                {
                                    !currentSchool && generalMedia && generalMedia.footer_image
                      && (
                          <ImgWithFallback
                              src={footerImage}
                              fallback={footerImageFallback}
                              className="__footer-image"
                              alt="landing image"
                          />
                      )
                                }
                                {
                                    getCurrentVersion() !== VERSIONS.BookingSport
                      && (
                          <div className="__landing-about">
                              <Title>
                                  {generalMedia && generalMedia[`explanation_title_${language}`]}
                              </Title>
                              <div className="__landing-about-text-container">
                                  {
                                      generalMedia
                            && (
                                <ImgWithFallback
                                    src={imageSrc}
                                    fallback={imageFallback}
                                    className="__landing-about-logo"
                                    containerClasses="__landing-about-logo-container"
                                    alt={`${intl.formatMessage({ id: 'img.alt.logo' })} ${getVersionName()}`}
                                />
                            )
                                  }
                                  <div>
                                      <p className="__landing-about-sub-desc">
                                          {generalMedia && generalMedia[`explanation_subtitle_${language}`]}
                                      </p>
                                      <p className="__landing-about-desc">
                                          {generalMedia && generalMedia[`explanation_text_${language}`]}
                                      </p>
                                  </div>
                              </div>
                          </div>
                      )
                                }
                                {/* Ne plus déplacer, gronder kristof */}
                                {/* {
                      schoolSiteContentSitemap && schoolSiteContentSitemap.length > 0 && schoolSiteContentSitemap.map((ssc, idx) => (
                        < Section
                          key={`school-site-content-sitemap-${idx}`}
                        // title={ssc.title}
                        // viewAll={false}//notSmartphone && products ? products.length > lessonMaxLength : false}
                        // viewAllAction={() => this.setState({ lessonMaxLength: products.length })}
                        // viewLessAction={notSmartphone && products && products.length > 4 ? () => this.setState({lessonMaxLength: 4}) : null}
                        >
                          <div className="__school-site-content-sitemap" dangerouslySetInnerHTML={{ __html: ssc.html }}></div>
                        </Section>
                      ))
                    } */}
                            </div>
                            {
                                isNewsFlash && newsflash && newsflash.length > 0
                    && (
                        <Modal
                            containerClasses="__newsflash-modal"
                            open={newsflash.length > 0}
                            onClose={() => this.disableNewsflash()}
                        >
                            <div className="__newsflash-header">
                                <Title notH>{language === 'en' ? newsflash[0].title : (newsflash[0].translations.find((e) => e.lang_key === language) ? newsflash[0].translations.find((e) => e.lang_key === language)?.title : '')}</Title>
                                <CloseIcon className="__newsflash-header-close" onClick={() => this.disableNewsflash()} />
                                <div className="divider __newsflash-divider" />
                                <div className="__newsflash-content">
                                    {
                                        newsflash[0].res_url
                            && (
                                <img
                                    className="__newsflash-image"
                                    src={newsflash[0].res_url}
                                    alt="newsflash"
                                />
                            )
                                    }
                                    <p className="__newsflash-text">
                                        {language === 'en' ? newsflash[0].text : (newsflash[0].translations.find((e) => e.lang_key === language) ? newsflash[0].translations.find((e) => e.lang_key === language)?.desc : '')}
                                    </p>
                                </div>
                            </div>
                        </Modal>
                    )
                            }
                            {
                                grimentzZinalModal
                    && (
                        <Modal
                            containerClasses="__pick-resort"
                            open={grimentzZinalModal}
                            onClose={() => this.setState({ grimentzZinalModal: false })}
                        >
                            <div className="__pick-resort-header">
                                <Title notH>{intl.formatMessage({ id: 'landing.resort' })}</Title>
                                <List sx={{ pt: 0 }}>
                                    <ListItem button onClick={() => this.lessonItemClickPickerResort('Grimentz')} key="grimentz">
                                        <ListItemText primary="Grimentz" />
                                    </ListItem>
                                    <ListItem button onClick={() => this.lessonItemClickPickerResort('Zinal')} key="zinal">
                                        <ListItemText primary="Zinal" />
                                    </ListItem>
                                </List>
                            </div>
                        </Modal>
                    )
                            }
                            {
                                neuchatelRegionModal
                    && (
                        <Modal
                            containerClasses="__pick-resort"
                            open={neuchatelRegionModal}
                            onClose={() => this.setState({ neuchatelRegionModal: false })}
                        >
                            <div className="__pick-resort-header">
                                <Title notH>{intl.formatMessage({ id: 'landing.resort' })}</Title>
                                <List sx={{ pt: 0 }}>
                                    <ListItem button onClick={() => this.lessonItemClickPickerResort('Bugnenets')} key="zinal">
                                        <ListItemText primary="Bugnenets" />
                                    </ListItem>
                                    <ListItem button onClick={() => this.lessonItemClickPickerResort('Corbatière')} key="grimentz">
                                        <ListItemText primary="Corbatière" />
                                    </ListItem>
                                    <ListItem button onClick={() => this.lessonItemClickPickerResort('Vue-des-Alpes')} key="zinal">
                                        <ListItemText primary="Vue-des-Alpes" />
                                    </ListItem>
                                    <ListItem button onClick={() => this.lessonItemClickPickerResort('Crêt-du-Puy')} key="zinal">
                                        <ListItemText primary="Crêt-du-Puy" />
                                    </ListItem>
                                    <ListItem button onClick={() => this.lessonItemClickPickerResort('Sommartel')} key="zinal">
                                        <ListItemText primary="Sommartel" />
                                    </ListItem>
                                </List>
                            </div>
                        </Modal>
                    )
                            }
                        </Container>
                    )
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    newsfeed: store.landing.newsfeed,
    newsflash: store.landing.newsflash,
    isNewsfeedLoading: store.landing.isNewsfeedLoading,
    products: store.landing.lesson,
    schoolSiteContent: store.landing.schoolSiteContent,
    isLessonLoading: store.landing.isLessonLoading,
    isSchoolLoading: store.landing.isLessonLoading,
    language: store.translation.language,
    windowWidth: store.windowSize.width,
    schools: store.landing.lesson,
    productSchools: store.products.schools,
    userData: store.setting.userData,
    currentSchool: store.navigation.currentSchool,
    searchSelectedSchool: store.landing.searchSelectedSchool,
    generalMedia: store.navigation.generalMedia,
});

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    // runSetImagesForLessons: (image: string[]) => runsetImagesForLessons(image)),
    // runChangeMyAccountCurrentTab: (currentTab: string) => runchangeMyAccountCurrentTab(currentTab)),
    runFetchNewsfeed: (companyId: number) => dispatch(fetchNewsfeed(companyId)),
    runFetchNewsflash: (companyId: number) => dispatch(fetchNewsflash(companyId)),
    runFetchLandingLesson: (companyId: number) => dispatch(fetchLandingLesson(companyId)),
    runFetchSchoolSiteContent: (companyId: number) => dispatch(fetchSchoolSiteContent(companyId)),
    runChangeSearchSelectedSchool: (value: any) => dispatch(changeSearchSelectedSchool(value)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(connector(Landing));
