import * as Sentry from '@sentry/browser';
import Cookie from 'js-cookie';

let BASE_URL = process.env.REACT_APP_BACKENDS_SUNKHRONOS_URL;
if (!BASE_URL) {
    BASE_URL = 'https://api.swisskischool.ch/api/';
}

const requestError = (message: any) => (dispatch: any) => {
    dispatch({
        type: 'REQUEST_ERROR_MESSAGE',
        errorMessage: message,
    });
    dispatch({
        type: 'ERROR',
        data: {
            errorType: 'API Error',
            errorMessage: message,
            props: {},
        },
    });
};

const apiError = (responseJson: any) => (dispatch: any) => {
    if (responseJson.detail) {
        dispatch(requestError(responseJson.detail));
    } else if (responseJson.error_code) {
        if ((responseJson.error_code >= 100 && responseJson.error_code < 200)
            || responseJson.error_code === 99) {
            // dispatch(logout()).then(() => dispatch(requestError(responseJson.errorMessage)));
        } else {
            dispatch(requestError(responseJson.errorMessage));
        }
    }
};
const requestCompleted = () => ({
    type: 'REQUEST_COMPLETED',
});

const httpError = () => (dispatch: any) => {
    dispatch(requestError('Network error, please check your internet connection'));
    dispatch({
        type: 'ERROR',
        data: {
            errorType: 'Network Error',
            errorMessage: 'Network error, please check your internet connection',
            props: {},
        },
    });
};

const apiFetch = (
    url: string,
    method: string,
    data: any,
    successCallBack: any,
    errorCallBack: any,
    firstTry: boolean = true,
) => (dispatch: any) => {
    if (url === null) {
        return;
    }
    let body: any = {};
    if (method === 'POST' && data) {
        body = JSON.stringify(data);
    }

    if (method === 'PUT' && data) {
        body = data;
    }

    const finalUrl = new URL(`${BASE_URL}${url}`).href;

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const responseJson = xhr.response;

                if (responseJson.detail) {
                    dispatch(apiError(responseJson));
                    if (errorCallBack) {
                        errorCallBack(responseJson);
                    }
                } else if (responseJson.error_code) {
                    dispatch(apiError(responseJson));
                    if (errorCallBack) {
                        errorCallBack(responseJson);
                    }
                } else if (successCallBack) {
                    let r;
                    try {
                        r = JSON.parse(responseJson);
                        successCallBack(r);
                    } catch (e) {
                        Sentry.captureException(e); // error in the above string (in this case, yes)!
                        dispatch(apiError(responseJson));
                        if (errorCallBack) {
                            errorCallBack(responseJson);
                        }
                    }
                }
                dispatch(requestCompleted());
            } else if (xhr.status === 0 && xhr.timeout === 0 && xhr.responseText === '' && xhr.responseType === '' && firstTry) { // bug with browser back button
                // try again
                dispatch(apiFetch(url, method, data, successCallBack, errorCallBack, false));
            } else {
                dispatch(httpError());
                dispatch(requestCompleted());
                if (errorCallBack) {
                    errorCallBack(xhr.responseText);
                }
            }
        }
    };
    xhr.open(method, finalUrl, true);
    // xhr.withCredentials = true;
    if (method) {
        const token = Cookie.get('token');
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
        if (token) xhr.setRequestHeader('Authorization', token || '');
    }
    xhr.send(body);
};

export const get = (url: string, successCallBack: any, errorCallBack: any) => (dispatch: any) => dispatch(apiFetch(url, 'GET', null, successCallBack, errorCallBack));

export const post = (url: string, params: any, successCallBack: any, errorCallBack: any) => (dispatch: any) => dispatch(apiFetch(url, 'POST', params, successCallBack, errorCallBack));

export const put = (url: string, params: any, successCallBack: any, errorCallBack: any) => (dispatch: any) => dispatch(apiFetch(url, 'PUT', params, successCallBack, errorCallBack));

// Reset it after sometime to avoid race condition
// in showing error to the user
export const resetRequestError = () => (dispatch: any) => setTimeout(() => dispatch({ type: 'ERROR_MESSAGE_RESET' }), 500);

export const API_ROUTES = {
    login: 'client/login',
    register: 'client/register',
    getNewsfeed: 'client/newsfeed',
    getLandingLesson: 'client/landing-lessons',
    getSchoolSiteContent: 'client/school-site-content',
    likePost: 'client/newsfeed/like',
    newsfeedComments: 'news/post/comments/',
    newsfeedLike: 'news/post/like/',
    sendComment: 'news/post/comment/',
    getSchools: 'client/company', // FOR post company_id
    changePassword: 'client/change-password/',
    editUserProfile: 'client/edit-profile/',
    editNotification: 'client/set-notification/',
    getUserData: 'client/user',
    forgotPassword: 'client/forgot-password/',
    passwordReset: 'client/password-reset/',
    resetPasswordToken: 'client/reset-password-token/',
    mylessons: 'client/my-lessons/',
    medals: 'client/client-medals/',
    bcClient: 'client/booking-corner-client/',
    inputClient: 'client/inputclient',
    lessonImageAll: 'client/lesson/image/all/',
    changeSchoolWithExternalId: 'client/lesson/change-company/',
    companyInstructorsConfiguration: 'client/lesson/company-instructors-configuration/',
    navigationMenus: 'client/navigation-menus/',
    sponsors: 'client/sponsors/',
    destinations: 'client/destinations',
    generalMedia: 'client/general-media/',
    customerLoyalty: 'client/customer-loyalty/',
};
