import { NavigationState, StoreAction } from '../types';

export const NAVIGATION_INIT_STATE: NavigationState = {
    contentHeight: undefined,
    currentPage: undefined,
    menus: undefined,
    currentSchool: undefined,
    sponsors: undefined,
    destinations: undefined,
    generalMedia: undefined,
};

export const TYPE_NAVIGATION_CHANGE_MENUS = Symbol('TYPE_NAVIGATION_CHANGE_MENUS');
export const TYPE_NAVIGATION_CHANGE_CURRENT_SCHOOL = Symbol('TYPE_NAVIGATION_CHANGE_CURRENT_SCHOOL');
export const TYPE_NAVIGATION_CHANGE_CURRENT_PAGE = Symbol('TYPE_NAVIGATION_CHANGE_CURRENT_PAGE');
export const TYPE_NAVIGATION_CHANGE_CONTENT_HEIGHT = Symbol('TYPE_NAVIGATION_CHANGE_CONTENT_HEIGHT');
export const TYPE_NAVIGATION_CHANGE_SPONSORS = Symbol('TYPE_NAVIGATION_CHANGE_SPONSORS');
export const TYPE_NAVIGATION_CHANGE_DESTINATIONS = Symbol('TYPE_NAVIGATION_CHANGE_DESTINATIONS');
export const TYPE_NAVIGATION_CHANGE_GENERAL_MEDIA = Symbol('TYPE_NAVIGATION_CHANGE_GENERAL_MEDIA');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const navigation = (state: NavigationState = NAVIGATION_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_NAVIGATION_CHANGE_CURRENT_SCHOOL:
            return {
                ...state,
                currentSchool: action.data,
            };
        case TYPE_NAVIGATION_CHANGE_MENUS:
            return {
                ...state,
                menus: action.data,
            };
        case TYPE_NAVIGATION_CHANGE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.data,
            };
        case TYPE_NAVIGATION_CHANGE_CONTENT_HEIGHT:
            return {
                ...state,
                contentHeight: action.data,
            };
        case TYPE_NAVIGATION_CHANGE_SPONSORS:
            return {
                ...state,
                sponsors: action.data,
            };
        case TYPE_NAVIGATION_CHANGE_DESTINATIONS:
            return {
                ...state,
                destinations: action.data,
            };
        case TYPE_NAVIGATION_CHANGE_GENERAL_MEDIA:
            return {
                ...state,
                generalMedia: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
