import { StoreAction, TranslationState } from '../types';

// enumeration for the different tabs
export const Language = Object.freeze({
    en: 'en',
    fr: 'fr',
    de: 'de',
    it: 'it',
});
export const BCLanguage = Object.freeze({
    fr: 'M',
    de: 'X',
    it: '81',
});

export const TRANSLATION_INIT_STATE: TranslationState = {
    language: Language.fr,
};

export const TYPE_CHANGE_LANGUAGE = Symbol('CHANGE_LANGUAGE');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const translation = (state: TranslationState = TRANSLATION_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
