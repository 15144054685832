import { LandingState, StoreAction } from '../types';

export const LANDING_INIT_STATE: LandingState = {
    newsfeed: [],
    newsflash: [],
    isNewsfeedLoading: false,
    isNewsFlash: false,
    isLessonLoading: false,
    isSchoolSiteContentLoading: false,
    lesson: [],
    schools: [],
    schoolSiteContent: [],
    isSchoolsLoading: false,
    searchSelectedSchool: undefined,
    searchLessonType: '',
    searchStartDate: null,
};

export const TYPE_INIT_LANDING = Symbol('TYPE_INIT_LANDING');

export const TYPE_GET_LANDING_NEWSFEED = Symbol('TYPE_GET_LANDING_NEWSFEED');
export const TYPE_GET_LANDING_NEWSFEED_SUCCESS = Symbol('TYPE_GET_LANDING_NEWSFEED_SUCCESS');
export const TYPE_GET_LANDING_NEWSFEED_FAILURE = Symbol('TYPE_GET_LANDING_NEWSFEED_FAILURE');

export const TYPE_GET_LANDING_NEWSFLASH = Symbol('TYPE_GET_LANDING_NEWSFLASH');
export const TYPE_GET_LANDING_NEWSFLASH_SUCCESS = Symbol('TYPE_GET_LANDING_NEWSFLASH_SUCCESS');
export const TYPE_GET_LANDING_NEWSFLASH_FAILURE = Symbol('TYPE_GET_LANDING_NEWSFLASH_FAILURE');

export const TYPE_GET_LANDING_LESSON = Symbol('TYPE_GET_LANDING_LESSON');
export const TYPE_GET_LANDING_LESSON_SUCCESS = Symbol('TYPE_GET_LANDING_LESSON_SUCCESS');
export const TYPE_GET_LANDING_LESSON_FAILURE = Symbol('TYPE_GET_LANDING_LESSON_FAILURE');

export const TYPE_GET_SCHOOL_SITE_CONTENT = Symbol('TYPE_GET_SCHOOL_SITE_CONTENT');
export const TYPE_GET_SCHOOL_SITE_CONTENT_SUCCESS = Symbol('TYPE_GET_SCHOOL_SITE_CONTENT_SUCCESS');
export const TYPE_GET_SCHOOL_SITE_CONTENT_FAILURE = Symbol('TYPE_GET_SCHOOL_SITE_CONTENT_FAILURE');

export const TYPE_SEARCH_CHANGE_SELECTED_SCHOOL = Symbol('TYPE_SEARCH_CHANGE_SELECTED_SCHOOL');
export const TYPE_SEARCH_CHANGE_LESSON_TYPE = Symbol('TYPE_SEARCH_CHANGE_LESSON_TYPE');
export const TYPE_SEARCH_CHANGE_START_DATE = Symbol('TYPE_SEARCH_CHANGE_START_DATE');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const landing = (state: LandingState = LANDING_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_INIT_LANDING:
            return LANDING_INIT_STATE;
        case TYPE_GET_LANDING_NEWSFEED:
            return {
                ...state,
                isNewsfeedLoading: true,
            };
        case TYPE_GET_LANDING_NEWSFEED_SUCCESS:
            return {
                ...state,
                isNewsfeedLoading: false,
                newsfeed: action.data,
            };
        case TYPE_GET_LANDING_NEWSFEED_FAILURE:
            return {
                ...state,
                isNewsfeedLoading: false,
            };
        case TYPE_GET_LANDING_NEWSFLASH:
            return {
                ...state,
                isNewsFlash: true,
            };
        case TYPE_GET_LANDING_NEWSFLASH_SUCCESS:
            return {
                ...state,
                isNewsFlash: false,
                newsflash: action.data,
            };
        case TYPE_GET_LANDING_NEWSFLASH_FAILURE:
            return {
                ...state,
                isNewsFlash: false,
            };
        case TYPE_GET_LANDING_LESSON:
            return {
                ...state,
                isLessonLoading: true,
            };
        case TYPE_GET_LANDING_LESSON_SUCCESS:
            return {
                ...state,
                isLessonLoading: false,
                lesson: action.data,
            };
        case TYPE_GET_LANDING_LESSON_FAILURE:
            return {
                ...state,
                isLessonLoading: false,
            };
        case TYPE_GET_SCHOOL_SITE_CONTENT:
            return {
                ...state,
                isSchoolSiteContentLoading: true,
            };
        case TYPE_GET_SCHOOL_SITE_CONTENT_SUCCESS:
            return {
                ...state,
                isSchoolSiteContentLoading: false,
                schoolSiteContent: action.data,
            };
        case TYPE_GET_SCHOOL_SITE_CONTENT_FAILURE:
            return {
                ...state,
                isSchoolSiteContentLoading: false,
            };
        case TYPE_SEARCH_CHANGE_SELECTED_SCHOOL:
            return {
                ...state,
                searchSelectedSchool: action.data,
            };
        case TYPE_SEARCH_CHANGE_LESSON_TYPE:
            return {
                ...state,
                searchLessonType: action.data,
            };
        case TYPE_SEARCH_CHANGE_START_DATE:
            return {
                ...state,
                searchStartDate: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
