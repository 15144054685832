import { BookingState, StoreBookingAction } from '../types';

export const BOOKING_INIT_STATE: BookingState = {
    isBookingLoading: false,
    isLessonsDetailsLoading: false,
    isNewPriceLoading: false,
    isInputParticipantLoading: false,
    isOptionsLoading: false,
    isQuestionsLoading: false,
    isFetchLessonLoading: false,
    isFetchFreeInstructorsLoading: false,
    currentLesson: undefined,
    lessonsDetails: [],
    hasMoreLessonsDetails: true,
    selectedLessonDetail: undefined,
    inputParticipant: [],
    participantsInfos: undefined,
    options: [],
    questions: [],
    selectedOptions: [],
    remarks: '',
    freeInstructors: [],
    selectedInstructor: {},
    schoolsList: [],
    schoolInstructorsConfiguration: undefined,
    selectedQuestions: [],
};

export const TYPE_INIT_BOOKING = Symbol('TYPE_INIT_BOOKING');

export const TYPE_SET_LESSON = Symbol('TYPE_SET_LESSON');

export const TYPE_SET_SELECTED_OPTIONS = Symbol('TYPE_SET_SELECTED_OPTIONS');
export const TYPE_SET_SELECTED_QUESTIONS = Symbol('TYPE_SET_SELECTED_QUESTIONS');

export const TYPE_SET_SELECTED_LESSON_DETAIL = Symbol('TYPE_SET_SELECTED_LESSON_DETAIL');

export const TYPE_SET_PARTICIPANTS_INFOS = Symbol('TYPE_SET_PARTICIPANTS_INFOS');

export const TYPE_SET_REMARKS = Symbol('TYPE_SET_REMARKS');

export const TYPE_GET_LESSONS_DETAILS = Symbol('TYPE_GET_LESSONS_DETAILS');
export const TYPE_GET_LESSONS_DETAILS_SUCCESS = Symbol('TYPE_GET_LESSONS_DETAILS_SUCCESS');
export const TYPE_GET_LESSONS_DETAILS_FAILURE = Symbol('TYPE_GET_LESSONS_DETAILS_FAILURE');

export const TYPE_GET_INPUT_PARTICIPANT = Symbol('TYPE_GET_INPUT_PARTICIPANT');
export const TYPE_GET_INPUT_PARTICIPANT_SUCCESS = Symbol('TYPE_GET_INPUT_PARTICIPANT_SUCCESS');
export const TYPE_GET_INPUT_PARTICIPANT_FAILURE = Symbol('TYPE_GET_INPUT_PARTICIPANT_FAILURE');

export const TYPE_SET_CUSTOMER_LOYALTY = Symbol('TYPE_SET_CUSTOMER_LOYALTY');
export const TYPE_SET_CUSTOMER_LOYALTY_SUCCESS = Symbol('TYPE_SET_CUSTOMER_LOYALTY_SUCCESS');
export const TYPE_SET_CUSTOMER_LOYALTY_FAILURE = Symbol('TYPE_SET_CUSTOMER_LOYALTY_FAILURE');

export const TYPE_GET_OPTIONS = Symbol('TYPE_GET_OPTIONS');
export const TYPE_GET_OPTIONS_SUCCESS = Symbol('TYPE_GET_OPTIONS_SUCCESS');
export const TYPE_GET_OPTIONS_FAILURE = Symbol('TYPE_GET_OPTIONS_FAILURE');

export const TYPE_GET_QUESTIONS = Symbol('TYPE_GET_QUESTIONS');
export const TYPE_GET_QUESTIONS_SUCCESS = Symbol('TYPE_GET_QUESTIONS_SUCCESS');
export const TYPE_GET_QUESTIONS_FAILURE = Symbol('TYPE_GET_QUESTIONS_FAILURE');

export const TYPE_FETCH_LESSON = Symbol('FETCH_LESSON');
export const TYPE_FETCH_LESSON_SUCCESS = Symbol('FETCH_LESSON_SUCCESS');
export const TYPE_FETCH_LESSON_FAILURE = Symbol('FETCH_LESSON_FAILURE');

export const TYPE_FETCH_FREE_INSTRUCTORS = Symbol('FETCH_FREE_INSTRUCTORS');
export const TYPE_FETCH_FREE_INSTRUCTORS_SUCCESS = Symbol('FETCH_FREE_INSTRUCTORS_SUCCESS');
export const TYPE_FETCH_FREE_INSTRUCTORS_FAILED = Symbol('FETCH_FREE_INSTRUCTORS_FAILED');
export const TYPE_SET_SELECTED_INSTRUCTOR = Symbol('FETCH_SET_SELECTED_INSTRUCTORS');

export const TYPE_SET_SCHOOLS_LIST = Symbol('SET_SCHOOLS_LIST');
export const TYPE_SET_SCHOOL_INSTRUCTORS_CONFIGURATION = Symbol('SET_SCHOOL_INSTRUCTORS_CONFIGURATION');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const booking = (state: BookingState = BOOKING_INIT_STATE, action: StoreBookingAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_INIT_BOOKING:
            return BOOKING_INIT_STATE;
        case TYPE_SET_LESSON:
            return {
                ...state,
                currentLesson: action.currentLesson,
            };
        case TYPE_SET_SELECTED_LESSON_DETAIL:
            return {
                ...state,
                selectedLessonDetail: action.selectedLessonDetail,
            };
        case TYPE_SET_SELECTED_OPTIONS:
            return {
                ...state,
                selectedOptions: action.selectedOptions,
            };
        case TYPE_SET_SELECTED_QUESTIONS:
            return {
                ...state,
                selectedQuestions: action.selectedQuestions,
            };
        case TYPE_SET_PARTICIPANTS_INFOS:
            return {
                ...state,
                participantsInfos: action.participantsInfos,
            };
        case TYPE_SET_REMARKS:
            return {
                ...state,
                remarks: action.remarks,
            };
        case TYPE_GET_LESSONS_DETAILS:
            return {
                ...state,
                isLessonsDetailsLoading: true,
            };
        case TYPE_GET_LESSONS_DETAILS_SUCCESS:
            return {
                ...state,
                lessonsDetails: action.lessonsDetails,
                isLessonsDetailsLoading: false,
                hasMoreLessonsDetails: action.lessonsDetails.length > 0,
            };
        case TYPE_GET_LESSONS_DETAILS_FAILURE:
            return {
                ...state,
                lessonsDetails: [],
                isLessonsDetailsLoading: false,
            };
        case TYPE_GET_INPUT_PARTICIPANT:
            return {
                ...state,
                isInputParticipantLoading: true,
            };
        case TYPE_GET_INPUT_PARTICIPANT_SUCCESS:
            return {
                ...state,
                inputParticipant: action.inputParticipant,
                isInputParticipantLoading: false,
            };
        case TYPE_GET_INPUT_PARTICIPANT_FAILURE:
            return {
                ...state,
                inputParticipant: [],
                isInputParticipantLoading: false,
            };
        case TYPE_SET_CUSTOMER_LOYALTY:
            return {
                ...state,
            };
        case TYPE_SET_CUSTOMER_LOYALTY_SUCCESS:
            return {
                ...state,
            };
        case TYPE_SET_CUSTOMER_LOYALTY_FAILURE:
            return {
                ...state,
            };
        case TYPE_GET_OPTIONS:
            return {
                ...state,
                isOptionsLoading: true,
            };
        case TYPE_GET_OPTIONS_SUCCESS:
            return {
                ...state,
                options: action.options,
                isOptionsLoading: false,
            };
        case TYPE_GET_OPTIONS_FAILURE:
            return {
                ...state,
                options: [],
                isOptionsLoading: false,
            };
        case TYPE_GET_QUESTIONS:
            return {
                ...state,
                isQuestionsLoading: true,
            };
        case TYPE_GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                questions: action.questions,
                isQuestionsLoading: false,
            };
        case TYPE_GET_QUESTIONS_FAILURE:
            return {
                ...state,
                questions: [],
                isQuestionsLoading: false,
            };
        case TYPE_FETCH_LESSON:
            return {
                ...state,
                currentLesson: {},
                isFetchLessonLoading: true,
            };
        case TYPE_FETCH_LESSON_SUCCESS:
            return {
                ...state,
                currentLesson: action.lesson,
                isFetchLessonLoading: false,
            };
        case TYPE_FETCH_LESSON_FAILURE:
            return {
                ...state,
                currentLesson: {},
                isFetchLessonLoading: false,
            };
        case TYPE_FETCH_FREE_INSTRUCTORS:
            return {
                ...state,
                isFetchFreeInstructorsLoading: true,
            };
        case TYPE_FETCH_FREE_INSTRUCTORS_SUCCESS:
            return {
                ...state,
                freeInstructors: action.data,
                isFetchFreeInstructorsLoading: false,
            };
        case TYPE_FETCH_FREE_INSTRUCTORS_FAILED:
            return {
                ...state,
                freeInstructors: [],
                isFetchFreeInstructorsLoading: false,
            };
        case TYPE_SET_SELECTED_INSTRUCTOR:
            return {
                ...state,
                selectedInstructor: action.data,
            };
        case TYPE_SET_SCHOOLS_LIST:
            return {
                ...state,
                schoolsList: action.data,
            };
        case TYPE_SET_SCHOOL_INSTRUCTORS_CONFIGURATION:
            return {
                ...state,
                schoolInstructorsConfiguration: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
