import { Paper } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { LESSON_FILTERS, OPTIONS_DEPENDENCES } from '../../resources/constants';
import visibilityImage from '../../resources/img/global/visibility.svg';
import visibilityOffImage from '../../resources/img/global/visibility_off.svg';
import { decimalToTime } from '../../resources/utils';
import { ApplicationState } from '../../store/types';
import { LessonCart } from '../../types/booking';
import Button from '../common/button';
import Title from '../common/title';

/**
 * Component that represent a lesson item of the cart page
 */
type ReduxProps = ConnectedProps<typeof connector>;

interface IProps {
    lesson: LessonCart;
    disabled: boolean;
    onDelete(event: any, lesson: LessonCart): void;
    // eslint-disable-next-line react/no-unused-prop-types
    type: string;
}

type Props = IProps & WrappedComponentProps & ReduxProps;

interface State {
    totalPrice: number;
    participantsOpen: boolean;
    optionsOpen: boolean;
    remarksOpen: boolean;
}

function toggleStateKey<T extends keyof State>(key: T, state: State, value?: boolean): Pick<State, T> {
    if (typeof state[key] === 'boolean') {
        if (value !== undefined) {
            return { [key]: value } as Pick<State, T>;
        }

        return { [key]: !state[key] } as Pick<State, T>;
    }
    throw new Error(`Property ${key} is not a boolean`);
}

class CartLesson extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            totalPrice: -1,
            participantsOpen: false,
            optionsOpen: false,
            remarksOpen: false,
        };

        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
    }

    componentDidMount() {
        const { lesson } = this.props;
        const totalPrice = (typeof lesson.totalOptionsPrice === 'string' ? parseFloat(lesson.totalOptionsPrice) : lesson.totalOptionsPrice) + lesson.price;
        this.setState({ totalPrice });
    }

    handleClickDelete(event: any) {
        const { onDelete, lesson } = this.props;
        onDelete(event, lesson);
    }

    toggleOpen(param: string) {
        this.setState((state) => toggleStateKey(param as keyof State, state));
    }

    render() {
        const {
            windowWidth, lesson, intl, disabled,
        } = this.props;
        const {
            participantsOpen, optionsOpen, remarksOpen, totalPrice,
        } = this.state;
        const bigScreen = windowWidth > 1000;

        let durationSplit = null;
        if (lesson.time.to_time.durationSplit) {
            durationSplit = lesson.time.to_time.split;
            if (durationSplit) durationSplit = durationSplit.substring(6, 19).replace('+', ' - ');
        }

        let hours = '';
        let totalHours = '';

        if (lesson.lesson_type === LESSON_FILTERS.GROUP_LESSON && typeof lesson.hours !== 'string') {
            hours = decimalToTime(lesson.hours.hoursLesson, intl.formatMessage) + intl.formatMessage({ id: 'booking.hoursDay' });
            totalHours = decimalToTime(lesson.hours.totalHoursLesson, intl.formatMessage, false) + intl.formatMessage({ id: 'booking.hoursTotal' });
        } else if (typeof lesson.hours === 'string') {
            const time = moment.duration(lesson.hours.replace('h', ':')).asHours() * lesson.days;
            hours = lesson.hours + intl.formatMessage({ id: 'booking.hoursDay' });
            totalHours = `${decimalToTime(time, intl.formatMessage, false)} ${intl.formatMessage({ id: 'booking.hoursTotal' })}`;
        }
        const checkParticipant = (
            lesson.participants_infos.length !== parseInt(String(lesson.participants), 10)
        );

        return (
            <Paper className="__booking-lesson-paper __cart-lesson-paper" style={disabled ? { boxShadow: 'none' } : undefined}>
                <div className="__booking-lesson-parent">
                    <div className="__booking-lesson-header">
                        <Title className={`__booking-lesson-title ${disabled ? '__cart-lesson-disabled-element' : ''}`}>
                            {lesson.title}
                            :
                            {' '}
                            {lesson.days + intl.formatMessage({ id: `booking.day${lesson.days > 1 ? 's' : ''}` })}
                        </Title>
                    </div>
                    <div className="__booking-lesson-body">
                        <div className={`__booking-lesson-1st ${disabled ? '__cart-lesson-disabled-element' : ''}`}>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'products.location' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value __cart-lesson-location">{lesson.school_id.name}</p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.startDate' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">
                                    (
                                    {moment(lesson.date.from_date).format('dd')}
                                    )
                                    {moment(lesson.date.from_date).format('DD.MM.YYYY')}
                                </p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.endDate' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">
                                    (
                                    {moment(lesson.date.to_date).format('dd')}
                                    )
                                    {moment(lesson.date.to_date).format('DD.MM.YYYY')}
                                </p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.hours' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">{hours}</p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className="__booking-lesson-1st-text">
                                    {intl.formatMessage({ id: 'booking.totalHours' })}
                                    :
                                </p>
                                <p className="__booking-lesson-1st-value">{totalHours}</p>
                            </span>
                            <span className="__booking-lesson-1st-info">
                                <p className={`__booking-lesson-1st-text ${checkParticipant && '__booking-lesson-1st-text-error'}`}>
                                    {intl.formatMessage({ id: `booking.Participant${(lesson.participants || 0) > 1 ? 's' : ''}` })}
                                    :
                                </p>
                                <span className="__booking-lesson-1st-value">
                                    {lesson.participants}
                                    <ReactSVG className="__cart-lesson-participants-visibility-grey" src={participantsOpen ? visibilityOffImage : visibilityImage} onClick={() => this.toggleOpen('participantsOpen')} />
                                </span>
                            </span>

                            {
                                participantsOpen
                                    ? (
                                        <div className="__cart-lesson-expand">
                                            {lesson.participants_infos.map(
                                                (participant, i) => {
                                                    const idx = i;
                                                    return (
                                                        <div className="__cart-lesson-expand-line" key={`participants_${idx}`}>
                                                            {'- '}
                                                            {Object.keys(participant).map(

                                                                (key, j) => {
                                                                    const jdx = j;
                                                                    let value = participant[key];
                                                                    if (key === 'birthdate') {
                                                                        value = intl.formatDate(moment(value).toDate(), { year: 'numeric', month: 'short', day: '2-digit' });
                                                                    } else if (value.value) {
                                                                        value = value.name;
                                                                    }
                                                                    if (key === 'p_id' || key === 'Id') return null;
                                                                    return (
                                                                        <p
                                                                            style={{ marginRight: '0px' }}
                                                                            className="__cart-lesson-expand-value"
                                                                            key={`info_${idx}_${jdx}`}
                                                                        >
                                                                            {jdx !== 0 ? ', ' : ''}
                                                                            {value}
                                                                        </p>
                                                                    );
                                                                },
                                                            )}
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </div>
                                    )
                                    : null
                            }
                            {
                                durationSplit
                                    ? (
                                        <span className="__booking-lesson-1st-info">
                                            <p className="__booking-lesson-1st-text">
                                                {intl.formatMessage({ id: 'booking.durationSplit' })}
                                                :
                                            </p>
                                            <p className="__booking-lesson-1st-value">{durationSplit}</p>
                                        </span>
                                    )
                                    : null
                            }
                        </div>
                        <div className="__booking-lesson-divider" style={{ height: bigScreen ? 'auto' : '1px' }} />
                        <div className={`__booking-lesson-2nd ${disabled ? '__cart-lesson-disabled-element' : ''}`}>
                            <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                    {intl.formatMessage({ id: 'booking.level' })}
                                    :
                                </p>
                                <p className="__booking-lesson-2nd-fixed-value">{(lesson.level?.name.length || 0) > 0 ? lesson.level?.name : intl.formatMessage({ id: 'booking.noLevel' })}</p>
                            </div>
                            <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                    {intl.formatMessage({ id: lesson.lesson_type === LESSON_FILTERS.GROUP_LESSON ? 'booking.startTime' : 'booking.daysLength' })}
                                    :
                                </p>
                                <p className="__booking-lesson-2nd-fixed-value">
                                    {
                                        lesson.lesson_type === LESSON_FILTERS.GROUP_LESSON
                                            ? moment(lesson.time.from_time).format('HH[:]mm')
                                            : `${lesson.days} ${intl.formatMessage({ id: `booking.day${lesson.days > 1 ? 's' : ''}` })}`
                                    }
                                </p>
                            </div>
                            <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                    {intl.formatMessage({ id: lesson.lesson_type === LESSON_FILTERS.GROUP_LESSON ? 'booking.endTime' : 'booking.startTime' })}
                                    :
                                </p>
                                <p className="__booking-lesson-2nd-fixed-value">
                                    {
                                        lesson.lesson_type === LESSON_FILTERS.GROUP_LESSON
                                            ? moment(lesson.time.to_time.time).format('HH[:]mm')
                                            : moment(lesson.time.from_time).format('HH[:]mm')
                                    }
                                </p>
                            </div>
                            <div className="__booking-lesson-2nd-content">
                                <p className="__booking-lesson-2nd-text">
                                    {intl.formatMessage({ id: 'booking.meeting' })}
                                    :
                                </p>
                                <p className="__booking-lesson-2nd-fixed-value">{lesson.meeting_point.name}</p>
                            </div>
                            {
                                lesson.options.length > 0
                                    ? (
                                        <div className="__booking-lesson-2nd-content">
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.options' })}
                                                :
                                            </p>
                                            <span className="__booking-lesson-2nd-fixed-value">
                                                {lesson.options.length}
                                                <ReactSVG className="__cart-lesson-participants-visibility" src={optionsOpen ? visibilityOffImage : visibilityImage} onClick={() => this.toggleOpen('optionsOpen')} />
                                            </span>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                optionsOpen
                                    ? (
                                        <div className="__cart-lesson-expand">
                                            {
                                                lesson.options.map((o, i) => {
                                                    const idx = i;
                                                    return (
                                                        <div className="__cart-lesson-expand-line" key={`options_${idx}`}>
                                                            <p className="__cart-lesson-expand-key">
                                                                -
                                                                {o.text}
                                                                :
                                                                {' '}
                                                            </p>
                                                            <p className="__cart-lesson-expand-value">{`${o.type === OPTIONS_DEPENDENCES.PERCENT ? `${o.price}%` : (typeof o.price === 'string' ? parseFloat(o.price) : o.price).toFixed(2)} (total: ${(typeof o.total === 'string' ? parseFloat(o.total) : o.total).toFixed(2)})`}</p>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    )
                                    : null
                            }
                            {
                                lesson.instructor
                                    ? (
                                        <div className="__booking-lesson-2nd-content">
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.freeInstructors' })}
                                                :
                                            </p>
                                            <p className="__booking-lesson-2nd-fixed-value">{`${lesson.instructor.Firstname} ${lesson.instructor.Name}`}</p>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                lesson.remarks
                                    ? (
                                        <div className="__booking-lesson-2nd-content">
                                            <p className="__booking-lesson-2nd-text">
                                                {intl.formatMessage({ id: 'booking.remarks' })}
                                                :
                                            </p>
                                            <span className="__booking-lesson-2nd-fixed-value">
                                                <ReactSVG className="__cart-lesson-participants-visibility" src={remarksOpen ? visibilityOffImage : visibilityImage} onClick={() => this.toggleOpen('remarksOpen')} />
                                            </span>
                                        </div>
                                    )
                                    : null
                            }
                            {
                                remarksOpen
                                    ? (
                                        <div className="__cart-lesson-expand">
                                            <p className="__cart-lesson-expand-line">{lesson.remarks}</p>
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <div className="__booking-lesson-3rd">
                            <div>
                                <p className={`__booking-lesson-3rd-price ${disabled ? '__cart-lesson-disabled-element' : ''}`}>
                                    CHF
                                    {' '}
                                    {totalPrice.toFixed(2)}
                                </p>
                            </div>
                            <div className="__booking-lesson-3rd-button">
                                <Button buttonClasses="__cart-lesson-button" onClick={this.handleClickDelete}>
                                    {intl.formatMessage({ id: 'order.delete' })}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    windowWidth: store.windowSize.width,
});
const connector = connect(mapStateToProps);

export default injectIntl(connector(CartLesson));
