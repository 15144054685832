import { useEffect, useState } from 'react';

function withIsMobile<T>(Component: React.ComponentType<T>) {
    function Wrapper(props: T) {
        const [width, setWidth] = useState(document.body.clientWidth);

        useEffect(() => {
            const element = document.body;
            const observer = new ResizeObserver((mutationRecords) => {
                if (mutationRecords.length > 0) {
                    setWidth(mutationRecords[0].contentRect.width ?? 1920);
                }
            });

            observer.observe(element);

            return () => {
                observer.disconnect();
            };
        }, []);

        return (
            <Component
                isMobile={width < 767}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        );
    }

    return Wrapper;
}

export default withIsMobile;
