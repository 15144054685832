import { StoreAction, SettingState } from '../types';

export const SETTINGS_INIT_STATE: SettingState = {
    schools: [],
    isGetSchoolsLoading: false,
    isSavingSetting: false,
    userData: {},
    isUserDataLoading: false,
    countries: [],
    languages: [],
    isCountriesLoading: false,
    isLanguagesLoading: false,
    bcClient: undefined,
    inputClient: undefined,
    error: false,
};

export const TYPE_SETTING_INIT = Symbol('TYPE_SETTING_INIT');
export const TYPE_GET_SETTING_SKI_SCHOOLS = Symbol('TYPE_GET_SETTING_SKI_SCHOOLS');
export const TYPE_GET_SETTING_SKI_SCHOOLS_SUCCESS = Symbol('TYPE_GET_SETTING_SKI_SCHOOLS_SUCCESS');
export const TYPE_GET_SETTING_SKI_SCHOOLS_FAILURE = Symbol('TYPE_GET_SETTING_SKI_SCHOOLS_FAILURE');
export const TYPE_SAVE_SETTING_SKI_SCHOOL = Symbol('TYPE_SAVE_SETTING_SKI_SCHOOL');
export const TYPE_SAVE_SETTING_SKI_SCHOOL_SUCCESS = Symbol('TYPE_SAVE_SETTING_SKI_SCHOOL_SUCCESS');
export const TYPE_SAVE_SETTING_SKI_SCHOOL_FAILURE = Symbol('TYPE_SAVE_SETTING_SKI_SCHOOL_FAILURE');
export const TYPE_SETTING_CHANGE_PASSWORD = Symbol('TYPE_SETTING_CHANGE_PASSWORD');
export const TYPE_SETTING_CHANGE_PASSWORD_SUCCESS = Symbol('TYPE_SETTING_CHANGE_PASSWORD_SUCCESS');
export const TYPE_SETTING_CHANGE_PASSWORD_FAILURE = Symbol('TYPE_SETTING_CHANGE_PASSWORD_FAILURE');
export const TYPE_SETTING_EDIT_PROFILE = Symbol('TYPE_SETTING_EDIT_PROFILE');
export const TYPE_SETTING_EDIT_PROFILE_SUCCESS = Symbol('TYPE_SETTING_EDIT_PROFILE_SUCCESS');
export const TYPE_SETTING_EDIT_PROFILE_FAILURE = Symbol('TYPE_SETTING_EDIT_PROFILE_FAILURE');
export const TYPE_SETTING_EDIT_PARTICIPANT_DETAIL = Symbol('TYPE_SETTING_EDIT_PARTICIPANT_DETAIL');
export const TYPE_SETTING_EDIT_PARTICIPANT_DETAIL_SUCCESS = Symbol('TYPE_SETTING_EDIT_PARTICIPANT_DETAIL_SUCCESS');
export const TYPE_SETTING_EDIT_PARTICIPANT_DETAIL_FAILURE = Symbol('TYPE_SETTING_EDIT_PARTICIPANT_DETAIL_FAILURE');
export const TYPE_SETTING_EDIT_NOTIFICATION = Symbol('TYPE_SETTING_EDIT_NOTIFICATION');
export const TYPE_SETTING_EDIT_NOTIFICATION_SUCCESS = Symbol('TYPE_SETTING_EDIT_NOTIFICATION_SUCCESS');
export const TYPE_SETTING_EDIT_NOTIFICATION_FAILURE = Symbol('TYPE_SETTING_EDIT_NOTIFICATION_FAILURE');
export const TYPE_SETTING_GET_USER_DATA = Symbol('TYPE_SETTING_GET_USER_DATA');
export const TYPE_SETTING_GET_USER_DATA_SUCCESS = Symbol('TYPE_SETTING_GET_USER_DATA_SUCCESS');
export const TYPE_SETTING_GET_USER_DATA_FAILURE = Symbol('TYPE_SETTING_GET_USER_DATA_FAILURE');
export const TYPE_SETTING_GET_LANGUAGES = Symbol('TYPE_SETTING_GET_LANGUAGES');
export const TYPE_SETTING_GET_LANGUAGES_SUCCESS = Symbol('TYPE_SETTING_GET_LANGUAGES_SUCCESS');
export const TYPE_SETTING_GET_LANGUAGES_FAILURE = Symbol('TYPE_SETTING_GET_LANGUAGES_FAILURE');
export const TYPE_SETTING_GET_COUNTRIES = Symbol('TYPE_SETTING_GET_COUNTRIES');
export const TYPE_SETTING_GET_COUNTRIES_SUCCESS = Symbol('TYPE_SETTING_GET_COUNTRIES_SUCCESS');
export const TYPE_SETTING_GET_COUNTRIES_FAILURE = Symbol('TYPE_SETTING_GET_COUNTRIES_FAILURE');
export const TYPE_SETTING_GET_BC_CLIENT = Symbol('TYPE_SETTING_GET_BC_CLIENT');
export const TYPE_SETTING_GET_INPUT_CLIENT = Symbol('TYPE_SETTING_GET_INPUT_CLIENT');
export const TYPE_SETTING_SET_INPUT_CLIENT = Symbol('TYPE_SETTING_SET_INPUT_CLIENT');
export const ERROR_UNDEFINED = Symbol('ERROR_UNDEFINED');

export const setting = (state: SettingState = SETTINGS_INIT_STATE, action: StoreAction = { type: ERROR_UNDEFINED, error: 'notFoundAction' }) => {
    switch (action.type) {
        case TYPE_SETTING_INIT:
            return SETTINGS_INIT_STATE;
        case TYPE_GET_SETTING_SKI_SCHOOLS:
            return {
                ...state,
                isGetSchoolsLoading: true,
            };
        case TYPE_GET_SETTING_SKI_SCHOOLS_SUCCESS:
            return {
                ...state,
                schools: action.data,
                isGetSchoolsLoading: false,
            };
        case TYPE_GET_SETTING_SKI_SCHOOLS_FAILURE:
            return {
                ...state,
                isGetSchoolsLoading: false,
            };
        case TYPE_SAVE_SETTING_SKI_SCHOOL:
            return {
                ...state,
                isSavingSetting: true,
            };
        case TYPE_SAVE_SETTING_SKI_SCHOOL_SUCCESS:
            return {
                ...state,
                isSavingSetting: false,
                error: false,
            };
        case TYPE_SAVE_SETTING_SKI_SCHOOL_FAILURE:
            return {
                ...state,
                isSavingSetting: false,
                error: true,
            };
        case TYPE_SETTING_CHANGE_PASSWORD:
            return {
                ...state,
                isSavingSetting: true,
            };
        case TYPE_SETTING_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isSavingSetting: false,
                error: false,
            };
        case TYPE_SETTING_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isSavingSetting: false,
                error: true,
            };
        case TYPE_SETTING_EDIT_PROFILE:
            return {
                ...state,
                isSavingSetting: true,

            };
        case TYPE_SETTING_EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                isSavingSetting: false,
                error: false,
            };
        case TYPE_SETTING_EDIT_PROFILE_FAILURE:
            return {
                ...state,
                isSavingSetting: false,
                error: true,
            };
        case TYPE_SETTING_EDIT_PARTICIPANT_DETAIL:
            return {
                ...state,
                isSavingSetting: true,

            };
        case TYPE_SETTING_EDIT_PARTICIPANT_DETAIL_SUCCESS:
            return {
                ...state,
                isSavingSetting: false,
                error: false,
            };
        case TYPE_SETTING_EDIT_PARTICIPANT_DETAIL_FAILURE:
            return {
                ...state,
                isSavingSetting: false,
                error: true,
            };

        case TYPE_SETTING_EDIT_NOTIFICATION:
            return {
                ...state,
                isSavingSetting: true,

            };
        case TYPE_SETTING_EDIT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isSavingSetting: false,
                error: false,
            };
        case TYPE_SETTING_EDIT_NOTIFICATION_FAILURE:
            return {
                ...state,
                isSavingSetting: false,
                error: true,
            };
        case TYPE_SETTING_GET_USER_DATA:
            return {
                ...state,
                isUserDataLoading: true,
            };
        case TYPE_SETTING_GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                isUserDataLoading: false,
            };
        case TYPE_SETTING_GET_USER_DATA_FAILURE:
            return {
                ...state,
                isUserDataLoading: false,
            };
        case TYPE_SETTING_GET_LANGUAGES:
            return {
                ...state,
                isLanguageLoading: true,
            };
        case TYPE_SETTING_GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: action.data,
                isLanguageLoading: false,
            };
        case TYPE_SETTING_GET_LANGUAGES_FAILURE:
            return {
                ...state,
                isLanguageLoading: false,
            };
        case TYPE_SETTING_GET_COUNTRIES:
            return {
                ...state,
                isCountriesLoading: true,
            };
        case TYPE_SETTING_GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.data,
                isCountriesLoading: false,
            };
        case TYPE_SETTING_GET_COUNTRIES_FAILURE:
            return {
                ...state,
                isCountriesLoading: false,
            };
        case TYPE_SETTING_GET_BC_CLIENT:
            return {
                ...state,
                bcClient: action.data ? action.data : undefined,
            };
        case TYPE_SETTING_GET_INPUT_CLIENT:
            return {
                ...state,
                inputClient: action.data,
            };
        case TYPE_SETTING_SET_INPUT_CLIENT:
            return {
                ...state,
                inputClient: action.data,
            };
        case ERROR_UNDEFINED:
            return state;
        default:
            return state;
    }
};
