import CloseIcon from '@mui/icons-material/Close';
import Cookie from 'js-cookie';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import Booking from '../booking/index';
import Cart from '../cart/index';
import Claim from '../claim/claim';
import Landing from '../landing';
import MessageList from '../message';
import NewsFeedDetail from '../newsfeed/detail';
import NewsFeed from '../newsfeed/index';
import ClaimInit from '../order/claimInit';
import OrderInit from '../order/orderInit';
import PaymentAbort from '../order/paymentAborted';
import PaymentFailed from '../order/paymentFailed';
import Products from '../products/index';
// import PaymentSuccess from '../order/paymentSuccess';
import PaymentSuccess from '../order/paymentSuccessv2';
// import OrderSuccess from '../order/orderSuccess';
import {
    changeSubDomain,
    getCurrentVersion,
    getDomain,
    getSubDomain
} from '../../resources/utils';
import {
    changeNavigationCurrentSchool,
    getDestinations, getGeneralMedia,
    getNavigationMenus, getSponsors
} from '../../store/actions/navigation';
import { fetchSchools } from '../../store/actions/products';
import { getSkiSchools, getUserDetails } from '../../store/actions/settings';
import changeLanguage from '../../store/actions/translation';
import { ApplicationState, StoreDispatch } from '../../store/types';
import { RouterProps } from '../../types/generalTypes';
import MyAccount from '../account/index';
import Authenticator from '../authenticator';
import Help from '../help/index';
import NavigationPage from '../navigationPage';
import OrderSuccess from '../order/orderSuccessv2';
import ResetPassword from '../resetPassword/index';
import SnowliAI from '../snowli/SnowliAI';
import NoMatch from './noMatch';

/**
 * Component that indicate wich address correspond to wich component
 */
interface IProps {
    dispatch: StoreDispatch;
}

type ReduxProps = ConnectedProps<typeof connector>;

type Props = IProps & RouterProps & WrappedComponentProps & ReduxProps;

interface State {
    hideCookieBanner: any;
}

class RouteSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hideCookieBanner: Cookie.get('hideCookieBanner'),
        };
    }

    componentDidMount() {
        const {
            dispatch,
            match,
            generalMedia,
            destinations,
            userData,
            schools,
            productSchools,
            sponsors,
        } = this.props;
        // set language
        dispatch(changeLanguage(match.params.lang));

        // this.setDocumentTitle();

        // fetch general version values
        if (!generalMedia) {
            dispatch(getGeneralMedia());
        }

        // fetch destinations, if no sponsors are stored
        if (destinations === undefined) {
            dispatch(getDestinations());
        }

        // fetch user data if user is connected
        if (Authenticator.isLoggedIn() && (!userData || Object.keys(userData).length === 0)) {
            dispatch(getUserDetails());
        }

        // fetch ski school (companies), if no school is stored
        if (schools.length === 0) {
            dispatch(getSkiSchools());
        }

        // fetch product school
        if (productSchools.length === 0) {
            dispatch(fetchSchools());
        }

        // fetch product school
        // if (productSchools.length === 0) {
        //     dispatch(testCodeDiscount({
        //          "code": "test",
        //          "level_id": 1514,
        //          "lesson_id": 1,
        //          "lesson_from" : "2020-11-21T11:07:59",
        //          "lesson_to": "2020-11-22T11:08:00",
        //      }));
        // }

        // fetch sponsors, if no sponsors are stored
        if (sponsors === undefined && !getSubDomain()) {
            dispatch(getSponsors());
        }
    }

    componentDidUpdate() {
        const {
            currentSchool, schools, destinations, userData, history,
        } = this.props;

        // if the current school is already set, return
        if (currentSchool) return;
        const isLogged = Authenticator.isLoggedIn();
        // if userData or schools or destinations are not fetched yet, return
        if ((isLogged && Object.keys(userData).length === 0)
            || schools.length === 0 || !destinations) {
            return;
        }

        const subDomain = getSubDomain();
        if (subDomain) this.schoolFromQuery(subDomain);
        else if (isLogged && !history.location.pathname.includes('order/success')) this.schoolFromUser();
    }

    schoolFromQuery = (schoolHost: string) => {
        const { destinations, schools, dispatch } = this.props;
        // get destination according to subdomain
        const destination = destinations?.find((d) => d.subdomain === schoolHost);
        // get school according to destination
        if (!destination) return;
        const school = schools.find((s) => s.id === destination.companyId);
        if (school) {
            dispatch(changeNavigationCurrentSchool(school));
            dispatch(getNavigationMenus(school.id));
            dispatch(getSponsors(school.id));
        }
    };

    schoolFromUser = () => {
        const { userData, destinations } = this.props;
        const { school } = userData;
        // if the user has a company of the same version as the site current one,
        //  redirect to this company subdomain
        if (school && school.version === getCurrentVersion()) {
            // change location
            const dest = destinations?.find((d) => d.companyId === school.id);
            if (dest) {
                changeSubDomain(dest.subdomain);
            }
        }
    };

    // setDocumentTitle = () => {
    // switch (getCurrentVersion()) {
    //     case VERSIONS.SkiSchoolAlliance:
    //         document.title = "SkiSchoolAlliance - Cours de ski, Ski lessons – Suisse";
    //          [document.getElementById("favicon-apple"),
    //          document.getElementById("favicon-16"),
    //          document.getElementById("favicon-32")].forEach(f => f.href = f.href.replace(
    //              "swisskischool",
    //              "skischoolalliance"));
    //         break;
    //     default:
    //         document.title = "Swisskischool - Cours de ski, Ski lessons – Valais, Suisse";
    //         break;
    // }
    // };

    acceptCookieBanner = () => {
        Cookie.set('hideCookieBanner', 'true', { domain: getDomain() });
        this.setState({ hideCookieBanner: true });
    };

    render() {
        const { intl, history, match } = this.props;
        const { hideCookieBanner } = this.state;
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <Switch>
                    <Route exact path="/:lang(en|fr|de|it)/:login(login)?" component={Landing} />
                    <Route exact path="/:lang(en|fr|de|it)/newsfeed" component={NewsFeed} />
                    <Route exact path="/:lang(en|fr|de|it)/newsfeed/:id" component={NewsFeedDetail} />
                    <Route exact path="/:lang(en|fr|de|it)/messages" component={MessageList} />
                    <Route exact path="/:lang(en|fr|de|it)/booking" component={Booking} />
                    <Route exact path="/:lang(en|fr|de|it)/products" component={Products} />
                    <Route exact path="/:lang(en|fr|de|it)/account/:tab?" component={MyAccount} />
                    <Route exact path="/:lang(en|fr|de|it)/cart" component={Cart} />
                    <Route exact path="/:lang(en|fr|de|it)/claim" component={Claim} />
                    <Route exact path="/:lang(en|fr|de|it)/order/claim" component={ClaimInit} />
                    <Route exact path="/:lang(en|fr|de|it)/order/init" component={OrderInit} />
                    <Route exact path="/:lang(en|fr|de|it)/payment/fail" component={PaymentFailed} />
                    <Route exact path="/:lang(en|fr|de|it)/payment/abort" component={PaymentAbort} />
                    <Route exact path="/:lang(en|fr|de|it)/payment/success/:sessionId" component={PaymentSuccess} />
                    <Route exact path="/:lang(en|fr|de|it)/order/success" component={OrderSuccess} />
                    <Route exact path="/:lang(en|fr|de|it)/reset-password" component={ResetPassword} />
                    <Route path="/:lang(en|fr|de|it)/help" component={Help} />
                    <Route exact path="/:lang(en|fr|de|it)/pages/:menu?/:submenu?" component={NavigationPage} />
                    <Route exact path="/:lang(en|fr|de|it)/*" component={NoMatch} />
                </Switch>
                {
                    hideCookieBanner === undefined && (
                        <div className="__cookie-banner-parent">
                            <span>
                                {intl.formatMessage({ id: 'cookie.banner' })}
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
                                jsx-a11y/no-noninteractive-element-interactions */}
                                <p onClick={() => history.push(`/${match.params.lang}/help/cga`)} className="__cookie-banner-cga">
                                    {intl.formatMessage({ id: 'help.cga' })}
                                </p>
                            </span>
                            <CloseIcon className="__cookie-banner-cancel" onClick={this.acceptCookieBanner} />
                        </div>
                    )
                }
                <SnowliAI />
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState) => ({
    userData: store.setting.userData,
    schools: store.setting.schools,
    productSchools: store.products.schools,
    currentSchool: store.navigation.currentSchool,
    menus: store.navigation.menus,
    sponsors: store.navigation.sponsors,
    destinations: store.navigation.destinations,
    generalMedia: store.navigation.generalMedia,
});
const connector = connect(mapStateToProps);
export default injectIntl(withRouter(connector(RouteSelector)));
